import { FC } from "react";
import { Notifications } from "../../components";

const NotificationsPage: FC<{}> = () => {
  return (
      <div className="page_content_wrapper custom_scrollbar custom_made_scrollbar"> 
        <Notifications />
      </div>
  );
};

export default NotificationsPage;
