import { useState, FC, useEffect } from "react";
import arrowLeft from "../../../assets/icons/arrowLeft.svg";
import arrowRight from "../../../assets/icons/arrowRight.svg";

interface IPagination {
    numberOfPages: number
    currentPage: number, 
    setCurrentPage(currentPage: number): void
}

const Pagination: FC<IPagination> = ({numberOfPages, currentPage, setCurrentPage}) => {
    const [testArray, setTestArray] = useState(Array.from(Array(numberOfPages).keys()));
    const testArrayLength = testArray.length;
    const widthCss = (testArrayLength*25)+"px";

    useEffect(() => {
        setTestArray(Array.from(Array(numberOfPages).keys()));
    }, [numberOfPages])
  
    if (!numberOfPages) 
        return <div></div>

    const previousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage-1)
    }
    
    const nextPage = () => {
        if (currentPage < testArrayLength) setCurrentPage(currentPage+1)
    }
    
    const renderPaginationNumbers = () => {
        return (
            <div className="pagination_numbers_wrapper" style={{ width: widthCss }}>
                    {testArray.map( (item: number, index: number) => {

                        if (index === 0)
                            return (
                                <div onClick={() => setCurrentPage(item+1)} key={index} className={`pagination_hover_effect pagination_visble_pages full_center ${currentPage === index+1 && "pagination_visible_pages_background"} `} > 
                                    {item+1}
                                </div>
                            )

                        if (index === testArrayLength-1)
                            return (
                                <div onClick={() => setCurrentPage(item+1)} key={index} className={`pagination_hover_effect pagination_visble_pages full_center ${currentPage === index+1 && "pagination_visible_pages_background"} `} > 
                                    {item+1}
                                </div>
                            )

                        if ((currentPage === 1 || currentPage === 2 || currentPage === 3 || currentPage === 4) && (index === 1 || index === 2 || index === 3 || index === 4) ) 
                            return (
                                <div onClick={() => setCurrentPage(item+1)} key={index} className={`pagination_hover_effect pagination_visble_pages full_center ${currentPage === index+1 && "pagination_visible_pages_background"} `} > 
                                    {item+1} 
                                </div>
                            )

                        if ((currentPage === 1 || currentPage === 2 || currentPage === 3 || currentPage === 4) && (index === 5) )
                            return (
                                <div key={index} className={`pagination_visble_pages full_center ${currentPage === index+1 && "pagination_visible_pages_background"} `} > 
                                    ...
                                </div>
                            )

                        if (currentPage > 4 && index === 1)
                            return (
                                <div key={index} className={`pagination_visble_pages full_center ${currentPage === index+1 && "pagination_visible_pages_background"} `} > 
                                    ...
                                </div>
                            )

                        if ((currentPage > 4) && (index === currentPage-2 || index === currentPage-1 || index === currentPage ) )
                            return (
                                <div onClick={() => setCurrentPage(item+1)} key={index} className={`pagination_hover_effect pagination_visble_pages full_center ${currentPage === index+1 && "pagination_visible_pages_background"} `} > 
                                    {item+1}
                                </div>
                            )
                    
                        if (currentPage > 4 && index === currentPage + 2 && currentPage < testArrayLength - 2)
                            return (
                                <div key={index} className={`pagination_visble_pages full_center ${currentPage === index+1 && "pagination_visible_pages_background"} `} > 
                                    ...
                                </div>
                            )

                        if (currentPage === testArrayLength - 3 && index === testArrayLength-2)
                            return (
                                <div onClick={() => setCurrentPage(item+1)} key={index} className={`pagination_hover_effect pagination_visble_pages full_center ${currentPage === index+1 && "pagination_visible_pages_background"} `} > 
                                    {item+1}
                                </div>
                            )

                        if (currentPage === testArrayLength - 2 && index === testArrayLength-5)
                            return (
                                <div onClick={() => setCurrentPage(item+1)} key={index} className={`pagination_hover_effect pagination_visble_pages full_center ${currentPage === index+1 && "pagination_visible_pages_background"} `} > 
                                    {item+1}
                                </div>
                            )

                        if (currentPage === (testArrayLength - 1) && (index === (testArrayLength-3) || index === (testArrayLength-4) || index === (testArrayLength-5)))
                            return (
                                <div onClick={() => setCurrentPage(item+1)} key={index} className={`pagination_hover_effect pagination_visble_pages full_center ${currentPage === index+1 && "pagination_visible_pages_background"} `} > 
                                    {item+1}
                                </div>
                            )

                        if (currentPage === (testArrayLength) && (index === (testArrayLength-3) || index === (testArrayLength-4) || index === (testArrayLength-5) || index === (testArrayLength-6)))
                            return (
                                <div onClick={() => setCurrentPage(item+1)} key={index} className={`pagination_hover_effect pagination_visble_pages full_center ${currentPage === index+1 && "pagination_visible_pages_background"} `} > 
                                    {item+1}
                                </div>
                            )

                        return <></>
                    })}
               </div>
        )
    }

    return (
        <div className="pagination_wrapper">
            <div className="pagination">
                {currentPage !== 1 ?
                    <img className="pagination_image_size cursor_pointer" src={arrowLeft} alt="left" onClick={() => previousPage()}/>
                        :
                    <div className="pagination_image_size"></div>
                }
                {renderPaginationNumbers()}
                {currentPage !== testArrayLength ?
                    <img className="pagination_image_size cursor_pointer" src={arrowRight} alt="right" onClick={() => nextPage()}/>
                        :
                    <div className="pagination_image_size"></div>
                }
            </div>
        </div>
    )
}

export default Pagination;