import { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import {useTranslation} from "react-i18next";

import { AuthDataContainerContext, CardContextNew, ComponentsContext, MatchContext, NotificationContext, SocketContext, UserDataContext } from "./contexts";
import { 
	LogInPage, 
	MatchesPage, 
	HistoryPage, 
	LearningPage, 
	FacilitatorsPage, 
	NotificationsPage, 
	PurchaseTablePage, 
	MyProfilePage,
	MatchesCardDeck,
	HistoryStatistics,
	CouponsPage,
	TableManagement
} from "./pages";
import { HeaderMenu, SidebarMenu } from "./components";

import "bootstrap/dist/css/bootstrap.min.css";
import "./scss/main.scss";
import { AlertNotification } from "./components/Atoms";
import mapLanguage from "./util/helpers/mapLanguage";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import GeneratePdfPage from "./pages/GeneratePdfPage/GeneratePdfPage";
import { useLocation, useHistory } from 'react-router-dom';

function App() {
	const { socket, registerFacilitator } = useContext(SocketContext);
	const { notifySocketOn } = useContext(NotificationContext);
	const { token } = useContext(AuthDataContainerContext);
	const { userData, setCurrentlySelectedLanguage } = useContext(UserDataContext);
	const { selectedLanguage, setSelectedLanguage } = useContext(ComponentsContext);
	const { refreshPage } = useContext(AuthDataContainerContext);
	const { getTableCountEndPoint } = useContext(MatchContext);
	const {t, i18n} = useTranslation();
	const location = useLocation();

	useEffect(() => {
		notifySocketOn();
	}, [socket, userData]);

	useEffect(() => {
		setCurrentlySelectedLanguage(selectedLanguage);
	}, [selectedLanguage])

	useEffect(() => {
		setSelectedLanguage(mapLanguage(i18n.language));
	}, []);

	useEffect(() => {
		refreshPage();
	});

	useEffect( () => {
		if (token && (!socket || socket.disconnected) )
			registerFacilitator();
	}, [token]);

	useEffect( () => {
		if (userData)
			getTableCountEndPoint();
	}, [getTableCountEndPoint, userData, userData?.email]);

	const [onGeneratePdfPage, setOnGeneratePdfPage] = useState<boolean>(false);

	useEffect(() => {
		const currentURL = window.location.href;
		if (currentURL.includes('/generate-pdf')) {
			setOnGeneratePdfPage(true);
		} else {
			setOnGeneratePdfPage(false);
		}
	  }, [location.pathname]);
	
	const getRouterNew = () => {
		return (
			<div className="router_wrapper">
				{onGeneratePdfPage ? 
				(
					<GeneratePdfPage />
				)
				:
				(
				<>
					<Router>
						<Switch>
							{token
							? 
							(
							<div className="app_core full_screen_vw_vh">
								<div id="dark_background"></div>
								<HeaderMenu />
								<div className="below_header_content_wrapper">
									<SidebarMenu />
									<ProtectedRoute path="/" component={MatchesPage} exact />
									<ProtectedRoute path="/matches/create/:token" component={MatchesPage} exact />								
									<ProtectedRoute path="/matches" component={MatchesPage} exact />
									<ProtectedRoute path="/matches/carddeck" component={MatchesCardDeck} exact />
									<ProtectedRoute path="/history" component={HistoryPage} exact />
									<ProtectedRoute path="/history/statistics" component={HistoryStatistics} exact />
									<ProtectedRoute path="/learning" component={LearningPage} exact />
									<ProtectedRoute path="/facilitators" component={FacilitatorsPage} exact />
									<ProtectedRoute path="/notifications" component={NotificationsPage} exact />
									<ProtectedRoute path="/purchasetables" component={PurchaseTablePage} exact />
									<ProtectedRoute path="/purchasetables/coupons" component={CouponsPage} exact />
									<ProtectedRoute path="/purchasetables/tablemanagement" component={TableManagement} exact />
									<ProtectedRoute path="/myprofile" component={MyProfilePage} exact />
								</div>
								<AlertNotification />
							</div>
							) 
							: 
							(<>
								<ProtectedRoute path="/" component={LogInPage} />
							</>)
							}
						</Switch>
					</Router>
				</>
				)}
			</div> 
		)
	}
	
	return (
		<div className="app_core full_screen_vw_vh">
			{getRouterNew()}
		</div>
	);
}

export default App;
