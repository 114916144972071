import { useContext } from "react";
import {useTranslation} from "react-i18next";
import { CreateNewCardModal, PopUpModal, CreateMatchWarning } from "../..";
import yellowPlus from "../../../assets/icons/yellowPlus.svg";
import { CardContext, CardContextNew, ComponentsContext } from "../../../contexts";
import CustomCardsPanel from "./CustomCardsPanel";
import DefaultCardsPanel from "./DefaultCardsPanel";

const CardsPanel = () => {
  const { selectedCardType } = useContext(CardContext);
  const { t } = useTranslation();
  const { customQuestionCardIds, customActionCardIds } = useContext(CardContextNew);
  const { setToggleModalValue, popUpBoxType, setPopUpBoxType } = useContext(ComponentsContext);
  const theMinimumNumberOfActionCardsYouNeedText: string = t("theMinimumNumberOfActionCardsYouNeedText");
  const theMaximumNumberOfCustomCardsYouCanAddText: string = t("theMaximumNumberOfCustomCardsYouCanAddText");

  const popUpBoxTypeDic : any= {
    "maxNumWarning": <CreateMatchWarning warningText={theMaximumNumberOfCustomCardsYouCanAddText}/>,
    "minNumWarning": <CreateMatchWarning warningText={theMinimumNumberOfActionCardsYouNeedText}/>,
    "createNewQuestionCard": <CreateNewCardModal cardType={selectedCardType} />,
    "createNewActionCard": <CreateNewCardModal cardType={selectedCardType} />,
    "editQuestion":  <CreateNewCardModal cardType={selectedCardType} />,
    "editAction":  <CreateNewCardModal cardType={selectedCardType} />,
    "editSelectedMatchQuestion":  <CreateNewCardModal cardType={selectedCardType} />,
    "editSelectedMatchAction":  <CreateNewCardModal cardType={selectedCardType} />,
  }
  
  const openMaxNumberWarning = () => {
    setToggleModalValue(true);
    setPopUpBoxType("maxNumWarning");
  }

  const openCreateNewCardModal = () => {
    setToggleModalValue(true);

    // if(sumOfCustomCards > 10) {
    //   openMaxNumberWarning();
    //   return; 
    // }

    if (selectedCardType === "Action") {
      setPopUpBoxType("createNewActionCard");
      return;
    }    

    setPopUpBoxType("createNewQuestionCard");
  }  

  return (
    <div className="cards_panel_wrapper">

      <PopUpModal>
        {popUpBoxTypeDic[popUpBoxType]}
      </PopUpModal>
      
      <div className="cards_panel_create_new_match_button_wrapper full_center" > 
          <img className="cursor_pointer create_new_match_plus_image" src={yellowPlus} alt="plus" onClick={() => openCreateNewCardModal() } />
          <div className="underline cursor_pointer" onClick={() => openCreateNewCardModal() }> {t("createNewCardText")} </div> 
      </div>

      <CustomCardsPanel />
      <DefaultCardsPanel />

    </div>
  );
};

export default CardsPanel;
