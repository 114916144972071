import { FC, } from "react";
import { RenderOneUser } from "../..";
import { IUser } from "../../../interfaces/index";
import DataGridHeader from "../DataGridHeader/DatagridHeader";

interface IRenderAllUsers {
    currentPosts:  IUser[] | undefined,
    setOpenUpdateFacilitator: React.Dispatch<React.SetStateAction<boolean>>,
    setOpenDeleteFacilitator: React.Dispatch<React.SetStateAction<boolean>>,
    makeDarkBackground: () => void,
}

const RenderAllUsers: FC<IRenderAllUsers>  = ({currentPosts, setOpenUpdateFacilitator, setOpenDeleteFacilitator, makeDarkBackground}) => { 

    if (!currentPosts)
    return <div></div>

    return (
        <div className="all_users_box">
            <div className="all_users_container">
                <DataGridHeader/>
            </div>
            {currentPosts.map( (user: IUser, index : number) => {
                return (
                    <div key={index} className="all_users_container">
                        <RenderOneUser user={user} setOpenUpdateFacilitator={setOpenUpdateFacilitator} setOpenDeleteFacilitator={setOpenDeleteFacilitator} makeDarkBackground={makeDarkBackground} />
                    </div>
                )
            })}
        </div>
    )
}

export default RenderAllUsers;