import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ComponentsContext, MatchContext } from "../../../contexts";
import { ButtonAnimationUp } from "../../Atoms";

const UpdateTimeModal = () => {
    const { setToggleModalValue, selectedGameUrlToken, setNotificationText, setIsNotificationActivated } = useContext(ComponentsContext);
    const { updateMatchTime } = useContext(MatchContext);
    const [updatedMatchTime, setUpdatedMatchTime] = useState<number>(1);
    const { t } = useTranslation();
    
    const updateTimeOfTheCurrentMatch = () => {
        updateMatchTime(selectedGameUrlToken, updatedMatchTime * 1000);
        setToggleModalValue(false);
        setNotificationText(t("theTimeIsUpdated"));
	    setIsNotificationActivated(true);
    }

    return (
        <div className="create_new_match_modal">

            <div className="create_new_match_modal_title extra_padding_10 text_align_center"> {t("updateTimeText")} </div>

            <div className="input_field_holder_number">
                <input 
                    type="number" 
                    min={10} 
                    max={1800000} 
                    id="unique_name" 
                    onChange={(e: any) => setUpdatedMatchTime(e.target.value)} 
                    value={updatedMatchTime} 
                /> 
            </div>

            {/*Temporary solution, needs fixs in other languages */}
            <div className="notification_below_input"> {t("enterTimeInMinutesText")} </div>

            <div className="create_new_match_button_holder">
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("completeText")} functionality={() => updateTimeOfTheCurrentMatch()} />
                </div>
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("cancelText")} functionality={() => setToggleModalValue(false)}  />
                </div>
            </div>

        </div>
    )
}

export default UpdateTimeModal;