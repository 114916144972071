import axios from "axios";
import React, { FC, createContext, useState, useContext } from "react";
import { AuthDataContainerContext } from "./AuthDataContainer";

interface ILanguageContext {
  selectedLanguage: string | undefined;
  setSelectedLanguage(selectedLanguage: string | undefined): void;
  selectLanguage(language: string): void;
}

const LanguageContext = createContext({} as ILanguageContext);
interface LanguageContextProps {
  children: React.ReactNode;
}

const LanguageContextProvider: FC<LanguageContextProps> = (props) => {
  const { token } = useContext(AuthDataContainerContext);
  const [selectedLanguage, setSelectedLanguage] = useState<string | undefined>("English");

  const selectLanguage = (language: string) => {
    axios({
      method: "get",
      url: process.env.REACT_APP_BACKEND_URL + "matches/language/" + language,
      headers: {
        Authorization: token,
      },
    });
  };

  const providerValue = {
    selectedLanguage,
    setSelectedLanguage,
    selectLanguage,
  };

  return (
    <LanguageContext.Provider value={providerValue}>
      {props.children}
    </LanguageContext.Provider>
  );
};

export { LanguageContext, LanguageContextProvider };
