import axios from "axios";
import { IUser } from "../../../interfaces/UserInterface";

type AddNewUser = (token: any, firstName: string, lastName: string, userEmail: string, isAdmin: boolean) => Promise<any>;

const AddANewUser: AddNewUser = async(token: any, firstName: string, lastName: string, userEmail: string, isAdmin: boolean): Promise<any>  => {
    
    const result =  await axios({
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + "users",
        headers: { 
            Authorization: token
        },
        data: {
            firstName: firstName,
            lastName: lastName,
            email: userEmail,
            isAdmin: isAdmin,
        }
    })
    
    return result;
    
  };
  
  export default AddANewUser;