import PostcardsHome from "./Postcards/PostcardsHome";
import "./Postcards/Postcards.scss";

const GeneratePdfPage = () => {

    return (
        <div className="App">
            <PostcardsHome />
        </div>
    )
}

export default GeneratePdfPage;