import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { IMatchesObject } from "../../../interfaces";
import greyPlus from "../../../assets/icons/greyPlus.svg";
import greyMinus from "../../../assets/icons/greyMinus.svg";
import copyIcon from "../../../assets/icons/copyIcon.svg";
import { msToTime } from "../../Atoms/Inputs/helpers";
import { ComponentsContext } from "../../../contexts";
import useCopyToClipboard from "./usecopyToClipboard";
import UpdateTimeTooltip from "./UpdateTimeTooltip";
import SpectatorButton from "./SpectatorButton";
import EditMatchButton from "./EditMatchButton";

interface IMatchItem {
    preActiveMatch: IMatchesObject,
    testGameUrls: string[]
    isActiveMatch: boolean;
}

const MatchItem: FC<IMatchItem> = ({preActiveMatch, testGameUrls, isActiveMatch}) => {
    let history = useHistory();
    const { setNotificationText, setIsNotificationActivated } = useContext(ComponentsContext);
    const [, copy] = useCopyToClipboard();
    const { t } = useTranslation();
    const maxOfAllUrlLinks = testGameUrls.length;
    const initialNumberOfLinksToShow = 5;

    const [numbersOfTheUrlLinksShown, setNumbersOfTheUrlLinksShown] = useState<number>(initialNumberOfLinksToShow);
    
    const areAllLinksShown: boolean = numbersOfTheUrlLinksShown === initialNumberOfLinksToShow;
    const currentIcon: string = areAllLinksShown ? greyPlus :  greyMinus;
    const defaultNumberOfLinksExpectedToRenderInTable: number = 5;
    const areThereMoreThan5Links: boolean = testGameUrls.length > defaultNumberOfLinksExpectedToRenderInTable;

    const getTables = () => {
        return (
            <div className="match_data_show_in_column">
                <div className="match_general_info_match_name"> {t("tablesText")}: </div>
                {testGameUrls.map( (_urlLink: any, index: number) => {
                    if (index < numbersOfTheUrlLinksShown )
                        return <div key={index} className="match_data_row"> {index+1}. {t("tableText")} </div>
                    return <div key={index}></div>
                })}
            </div>       
        )
    }

    const getPlayers = () => {
        return (
            <div className="match_data_show_in_column">
                <div className="match_general_info_match_name"> {t("playersText")}: </div>
                {testGameUrls.map( (_urlLink: any, index: number) => {
                    if (index < numbersOfTheUrlLinksShown )
                        return <div key={index} className="match_data_row"> 2 - 6  </div>  
                    return <div key={index}></div>
                })}
            </div>       
        )
    }

    const clipBoardIsClicked = (urlLink: string) => {
        copy(urlLink);
        setNotificationText(t("theLinkIsCopiedText"));
	    setIsNotificationActivated(true);
    }    

    const goToTheSelectedRoute = () =>{ 
        if (history)
        history.push("history/statistics");
    }

    const getLinks = () => {
        if (isActiveMatch)
        return (
            <div className="match_data_show_in_column">
                <div className="match_general_info_match_name"> {t("linksText")}: </div>
                {testGameUrls.map( (urlLink: string, index: number) => {
                    if (index < numbersOfTheUrlLinksShown )
                        return (
                            <div key={index} className="match_link_wrapper">
                                {preActiveMatch?.isMatchFinished && !preActiveMatch?.isMatchFinished[0]?.isFinished && <SpectatorButton selectedMatchURL={urlLink} />}
                                {preActiveMatch?.isMatchFinished && !preActiveMatch?.isMatchFinished[0]?.isFinished && <UpdateTimeTooltip urlLink={urlLink} />}
                                {preActiveMatch?.isMatchFinished && !preActiveMatch?.isMatchFinished[0]?.isFinished && <EditMatchButton preActiveMatch={preActiveMatch} urlLink={urlLink} />}
                                {/* {urlLink} */}
                                <div className="match_link"> 
                                    <input 
                                        type="text"
                                        className="form-control" 
                                        placeholder={urlLink}
                                        value={urlLink}
                                        disabled = {true}
                                        onBlur={(e) => console.error(e)}
                                        id="myInput"
                                    />
                                </div>
                                {!window.location.href.includes("history") && 
                                    <img className="cursor_pointer" src={copyIcon} alt="copy" onClick={() => clipBoardIsClicked(urlLink)} />
                                }
                            </div>
                        )
                    return <div key={index}></div>
                })}
            </div>       
        )

        return (
            <div className="match_data_show_in_column">
                <div className="match_general_info_match_name"> {t("statisticsShortText")}: </div>
                {testGameUrls.map( (urlLink: string, index: number) => {
                    if (index < numbersOfTheUrlLinksShown )
                        return (
                            <div key={index} className="match_link_wrapper"> 
                                <div className="match_link see_statistics" onClick={() => goToTheSelectedRoute()}> 
                                   {urlLink}
                                </div>
                                {!window.location.href.includes("history") && 
                                    <img className="cursor_pointer" src={copyIcon} alt="copy" onClick={() => clipBoardIsClicked(urlLink)} />
                                }
                            </div>
                        )
                    return <div key={index}></div>
                })}
            </div>   
        )
    }

    const updateNumbersOfTheUrlLinksShown = () => {
        if (numbersOfTheUrlLinksShown === initialNumberOfLinksToShow)
            setNumbersOfTheUrlLinksShown(maxOfAllUrlLinks)

        if (numbersOfTheUrlLinksShown !== initialNumberOfLinksToShow)
        setNumbersOfTheUrlLinksShown(initialNumberOfLinksToShow)
    }

    return (
        <>
            <div className="match_general_info">
                <div className="match_general_info_match_name"> {preActiveMatch?.matchName} </div>
                <div className="match_general_info_match">
                    <div className="match_general_info_variable"> {t("companyText")}: </div>
                    <div className="match_general_info_company_name"> {preActiveMatch?.companyName} </div>
                </div>
                <div className="match_general_info_match">
                    <div className="match_general_info_variable"> {t("tablesText")}: </div>
                    <div> {testGameUrls?.length}  </div>
                </div>
                {!isActiveMatch &&
                    <div>           
                        <div className="match_general_info_match">
                            <div className="match_general_info_variable"> {t("numberOfPlayersText")}: </div>
                            <div> {preActiveMatch?.numberOfPlayers}  </div>
                        </div>
                        <div className="match_general_info_match">
                            <div className="match_general_info_variable"> {t("roundsPlayedText")}: </div>
                            <div> {preActiveMatch?.roundsPlayed}  </div>
                        </div>
                        <div className="match_general_info_match">
                            <div className="match_general_info_variable"> {t("createdAtText")}: </div>
                            <div> {new Date(preActiveMatch?.createdAt).toLocaleDateString()}  </div>
                        </div>
                    </div>
                }    
                {isActiveMatch &&
                    <div className="match_general_info_match">
                        <div className="match_general_info_variable"> {t("durationText")}: </div>               
                            <div > {msToTime(preActiveMatch?.matchDuration, "letters")} </div>
                    </div>
                }
            </div>
                <div className="match_links_wrapper">
                    {getTables()}
                    {getPlayers()}
                    {getLinks()}
                </div>

            {areThereMoreThan5Links && <div className="matches_links_show_more_wrapper cursor_pointer" onClick={() => updateNumbersOfTheUrlLinksShown() }> 
                <img className="grey_plus" src={currentIcon} alt="plus" />
                {areAllLinksShown ? 
                    <div className="underline"> {t("showMoreText")} </div> 
                        :
                    <div className="underline"> {t("showLessText")} </div> 
                }
            </div>}
        </>
    )
}

export default MatchItem;