import { useContext, useState } from "react";
import {useTranslation} from "react-i18next";
import { AuthDataContainerContext, ComponentsContext, UserDataContext } from "../../../contexts";
import { addANewUser } from "../helpers";
import MessageIcon from "../../../assets/icons/Message.svg";
import ProfileIcon from "../../../assets/icons/profile-2user.svg";
import { ButtonAnimationUp } from "../../Atoms"; 
import axios from "axios";


const AddNewUserForm = () => {
    const { setToggleModalValue } = useContext(ComponentsContext);
    const { fetchAllUsers } = useContext(UserDataContext);
    const { token } = useContext(AuthDataContainerContext);
    const {t} = useTranslation();
    const [newUserEmail, setNewUserEmail] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [hasDiscount, setHasDiscount] = useState<boolean>(false);

    const handleIsAdminCheck = (chkValue: any) => {
        setIsAdmin(chkValue.target.checked);
    }

    const addFacilitator = async() => {
        if(token) {

            try {
                const addFacilitator = await addANewUser(token, firstName, lastName, newUserEmail, isAdmin); 
                fetchAllUsers()

                axios({
                    method: "post",
                    url: process.env.REACT_APP_BACKEND_URL + "notifications/notifyFacilitator",
                    headers: { 
                        Authorization: token
                    },
                    data: {
                        facilitator: addFacilitator?._id,
                        type: "LEARNING",
                    }
                })
                .then((res) => {
                    console.log(res, "proslo");
                })
                .catch((err) => console.error(err));
                
            } catch (error) {console.log(error);}

            const darkBackground : any = document.getElementById("dark_background");
            darkBackground.style.visibility = "hidden"; 

            setToggleModalValue(false)
        }
    }

    const cancelInvite = () => {
        setToggleModalValue(false);

        const darkBackground : any = document.getElementById("dark_background");
        darkBackground.style.visibility = "hidden"; 
    }

    return (
        <div className="invite_facilitator_box">
            <div className="invite_facilitator_heading"> {t("inviteFacilitatorText")} </div>
            <div className="invite_facilitator_input_fields"> 
                <img style={{marginRight: "20px"}} src={MessageIcon} alt="" />
                <input style={{border: "none", background: "transparent", outline: "none"}}
                    autoFocus
                    maxLength={50}
                    type='email'
                    value={newUserEmail}
                    placeholder={t("emailText")}
                    onChange={(event: any) => setNewUserEmail(event?.target?.value)}
                />
            </div>
            <div className="invite_facilitator_input_fields"> 
            <img style={{marginRight: "20px"}} src={ProfileIcon} alt="" />
                <input style={{border: "none", background: "transparent", outline: "none"}}
                    autoFocus
                    maxLength={50}
                    type='text'
                    value={firstName}
                    placeholder={t("firstNameText")}
                    onChange={(event: any) => setFirstName(event?.target?.value)}
                />
            </div>
            <div className="invite_facilitator_input_fields"> 
            <img style={{marginRight: "20px"}} src={ProfileIcon} alt="" />
                <input style={{border: "none", background: "transparent", outline: "none"}}
                    autoFocus
                    maxLength={50}
                    type='text'
                    value={lastName}
                    placeholder={t("lastNameText")}
                    onChange={(event: any) => setLastName(event?.target?.value)}
                />
            </div>
            <div style={{margin: "auto"}} > 
                <input type="checkbox" id="accept" name="accept" value="yes" checked={isAdmin} onChange={(event)=> handleIsAdminCheck(event)}/>
                <span> Is Admin: </span>
            </div>

            <div className="create_new_match_button_holder invite_facilitator_buttons">
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text="Create The New User" functionality={() => addFacilitator()} />
                </div>
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text="Cancel" functionality={cancelInvite}  />
                </div>
            </div>

        </div>
    )
}

export default AddNewUserForm;