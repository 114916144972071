import { useContext, useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import { useHistory } from "react-router-dom";
import yellowPlus from "../../assets/icons/yellowPlus.svg";
import learningItemImage from "../../assets/images/learningItemImage.png";
import { ComponentsContext, LearningContext, UserDataContext } from "../../contexts";
import { LearningType } from "../../util/enums/LearningType";
import EditLearningComponent from "./EditLearningComponent";
import { ButtonAnimationUp } from "../Atoms";

const Learning = () => {
    const { userData, fetchCurrentUser } = useContext(UserDataContext);
    const { learningItems, setSelectedLearningItemId, setSelectedLearningOption, learningGetFilteredEndPoint, learningGetAllEndPoint } = useContext(LearningContext);
    const { setToggleModalValue } = useContext(ComponentsContext);
    const [selectedTag, setSelectedTag] = useState<number>(0);
    const {t} = useTranslation();
    
    const learningTags: string[] = [ t('allText'), t('videosText'), t('presentationsText'), t('eventsText'), t('sharedFilesText') ];
    
    const turnicateTitle = (title: string) => {
        if (!title) return "Lesson";
        
        const maxNumOfCharactersForTitle: number = 50;
        return title?.substr(0, maxNumOfCharactersForTitle) + "...";
    }

    const turnicateDesctiptoin = (title: string) => {
        if (!title) return "Lesson";
        
        const maxNumOfCharactersForDesctiption: number = 80;
        return title?.substr(0, maxNumOfCharactersForDesctiption) + "...";
    }

    const goToTheSelectedRoute = (urlLink: string) =>{ 
        window.open(urlLink, '_blank');
    }

    const createNewLearning = () => {
        setSelectedLearningOption("create");
        setToggleModalValue(true)
    }

    useEffect(() => {
        fetchCurrentUser();
    }, [])

    const learningItemsTypeDic = (index: number) => {
        switch(index) {
            case 0: return LearningType.ALL;
            case 1: return LearningType.VIDEOS;
            case 2: return LearningType.PRESENTATIONS;
            case 3: return LearningType.EVENTS;
            case 4: return LearningType.SHARED_FILE;
            default: return LearningType.ALL;
        }
    }

    const listLiearningItemsByFilter = (index: number) => {
        setSelectedTag(index);
        if (index) {
            learningGetFilteredEndPoint(learningItemsTypeDic(index));
            return;
        }
        learningGetAllEndPoint();
    }

    let history = useHistory();

    const redirectToGeneratePdfs = () => {
        if (history)
            history.push("/generate-pdf");
        window.location.reload();
    }

    return (
        <div className="learning_wrapper">

            <div className="learning_tags">
                {learningTags.map( (learningTag: string, index: number) => {
                    return (
                        <div className={`learning_tag ${(selectedTag === index && "dark_border")} `} onClick={() => listLiearningItemsByFilter(index)}>
                            {learningTag}
                        </div>
                    )
                })}
            </div>

            <div> 
                <div className="redirect_button_wrapper">
                    <ButtonAnimationUp text={t("generatePDF")} functionality={redirectToGeneratePdfs} />
                </div>
            </div>

            {userData?.isAdmin &&
                <div className="matches_create_new_match_button_wrapper full_center" > 
                    <img className="cursor_pointer create_new_match_plus_image" src={yellowPlus} alt="dots" onClick={() => createNewLearning() } />
                    <div className="underline cursor_pointer" onClick={() => createNewLearning() }> {t("addNewItemText")} </div> 
                </div>
            }

            <div className="learning_items_wrapper">
                {learningItems.map((item: any, index: number) => {
                    return (
                        <div className="learning_item" key={index} onClick={() => setSelectedLearningItemId(item.id)}> 
                            <EditLearningComponent selectedItem={item} />
                            <div className="learning_item_title cursor_pointer" onClick={() => goToTheSelectedRoute(item?.link)}> {turnicateTitle(item?.title)} </div>
                            <div className="learning_item_image_wrapper cursor_pointer" onClick={() => goToTheSelectedRoute(item?.link)}>
                                <img className="learning_item_image" src={learningItemImage} alt="learning" />
                            </div>
                            <div className="learning_item_description cursor_pointer" onClick={() => goToTheSelectedRoute(item?.link)}>  
                                {turnicateDesctiptoin(item?.description)}
                                {/* <span className="tooltiptext"> {item?.description} </span> */}
                            </div>
                        </div>
                    )
                })}
            </div>

        </div>
    )
}

export default Learning;