import { FC } from "react";

interface IRedButtonAnimationUp {
    text: string;
    functionality(): void; 
}

const RedButtonAnimationUp: FC<IRedButtonAnimationUp> = ({text, functionality}) => {


    return (
        <div className="red_button_animation_up_wrapper full_center" onClick={() => functionality()} >
            <div className="red_button_animation_up_background"></div>
            <div className="red_button_animation_up_text"> {text} </div>
        </div>
    )
}

export default RedButtonAnimationUp;