import axios from "axios";
import React, { FC, createContext, useState, useContext } from "react";
import { AuthDataContainerContext } from ".";
import { CardContextNew } from ".";

interface IUpdateMatchCardContext {
  createSelectedMatchNewCustomQuestionCard(customActionCard: any, matchId: string, language: any): void;
  createSelectedMatchNewCustomActionCard(customActionCard: any, matchId: string, language: any): void;
  updateSelectedMatchesCustomQuestionCard(customQuestionCard: any): void;
  updateSelectedMatchesCustomActionCard(customActionCard: any): void;
}

const UpdateMatchCardContext = createContext({} as IUpdateMatchCardContext);

interface UpdateMatchCardContextProps {
  children: React.ReactNode;
}

const UpdateMatchCardContextProvider: FC<UpdateMatchCardContextProps> = (props) => {
  const { token } = useContext(AuthDataContainerContext);
  const { setQuestionCustomCardsNew, mapReceivedQuestionCards, setActionCustomCardsNew, mapReceivedActionCards } = useContext(CardContextNew);

  const getUpdatedQuestionCustomCards = (matchId: string) => {
    axios({
      method: "get",
      url: process.env.REACT_APP_BACKEND_URL + `question-cards/get-cards-match/${matchId}`,
      headers: {
        Authorization: token,
      },
    })
      .then((res: any) => {
        setQuestionCustomCardsNew(mapReceivedQuestionCards(res?.data));
      })
      .catch((err: any) => console.error(err));
  };

  const getUpdatedActionCustomCards = (matchId: string) => {
    axios({
      method: "get",
      url: process.env.REACT_APP_BACKEND_URL + `action-cards/get-cards-match/${matchId}`,
      headers: {
        Authorization: token,
      },
    })
      .then((res: any) => {
        setActionCustomCardsNew(mapReceivedActionCards(res?.data));
      })
      .catch((err: any) => console.error(err));
  };

  const createSelectedMatchNewCustomQuestionCard = (customQuestionCard: any, matchId: string, language: any) => {
    axios({
      method: "post",
      url: process.env.REACT_APP_BACKEND_URL + "question-cards/create-card-match",
      headers: {
        Authorization: token,
      },
      data: {
        matchId: matchId,
        card: customQuestionCard,
        language: language,
      },
    })
      .then(() => {
        getUpdatedQuestionCustomCards(matchId);
      })
      .catch((err: any) => console.error(err));
  };

  const updateSelectedMatchesCustomQuestionCard = (customQuestionCard: any) => {
    axios({
      method: "patch",
      url: process.env.REACT_APP_BACKEND_URL + "question-cards/update-card-match",
      headers: {
        Authorization: token,
      },
      data: {
        matchId: customQuestionCard.matchId,
        cardId: customQuestionCard.cardId,
        card: {
          description: customQuestionCard.card.description,
          order: customQuestionCard.card.order,
        },
      },
    })
      .then((res: any) => {
        getUpdatedQuestionCustomCards(customQuestionCard.matchId);
      })
      .catch((err: any) => console.error(err));
  };

  const createSelectedMatchNewCustomActionCard = (customActionCard: any, matchId: string, language: any) => {
    axios({
      method: "post",
      url: process.env.REACT_APP_BACKEND_URL + "action-cards/create-card-match",
      headers: {
        Authorization: token,
      },
      data: {
        matchId: matchId,
        card: customActionCard,
        language: language,
      },
    })
      .then(() => {
        getUpdatedActionCustomCards(matchId);
      })
      .catch((err: any) => console.error(err));
  };

  const updateSelectedMatchesCustomActionCard = (customActionCard: any) => {
    axios({
      method: "patch",
      url: process.env.REACT_APP_BACKEND_URL + "action-cards/update-card-match",
      headers: {
        Authorization: token,
      },
      data: {
        matchId: customActionCard.matchId,
        cardId: customActionCard.cardId,
        card: {
          title: customActionCard.card.title,
          numberOfUpperTokens: customActionCard.card.numberOfUpperTokens,
          description: customActionCard.card.description,
          order: customActionCard.card.order,
        },
      },
    })
      .then((res: any) => {
        getUpdatedActionCustomCards(customActionCard.matchId);
      })
      .catch((err: any) => console.error(err));
  };

  const providerValue = {
    createSelectedMatchNewCustomQuestionCard,
    createSelectedMatchNewCustomActionCard,
    updateSelectedMatchesCustomQuestionCard,
    updateSelectedMatchesCustomActionCard,
  };

  return <UpdateMatchCardContext.Provider value={providerValue}>{props.children}</UpdateMatchCardContext.Provider>;
};

export { UpdateMatchCardContext, UpdateMatchCardContextProvider };
