import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ComponentsContext, CouponContext } from "../../contexts";
import { ButtonAnimationUp, InputNumber, InputText } from "../Atoms";
import editIcon from "../../assets/icons/editIcon.svg";
import { InputFieldsType } from "../../util/enums/InputFieldsType";


const EditCouponModal = () => {
    const { setToggleModalValue } = useContext(ComponentsContext);
    const { 
        patchUpdateCoupon,
        setCoupon,
        selectedCoupon
    } = useContext(CouponContext);
    let history = useHistory();
    const { t } = useTranslation();

    const [couponName, setCouponName] = useState<string>(selectedCoupon.couponName);
    const [couponDiscount, setCouponDiscount] = useState<number>(selectedCoupon.discount);
    const [couponActivity, setCouponActivity] = useState<boolean>(selectedCoupon.isActive);
    
    const updateTimeOfTheCurrentMatch = () => {
        const discount: number = couponDiscount ? couponDiscount/100 : 0;
        const couponNameNew = selectedCoupon.code === couponName ? selectedCoupon.code : couponName;
        const couponDiscountNew = selectedCoupon.discount === couponDiscount ? selectedCoupon.discount : discount;
        const couponActivityNew = selectedCoupon.isActive === couponActivity ? selectedCoupon.isActive : couponActivity;
        const coupon = {
            code: couponNameNew,
            discount: couponDiscountNew,
            isActive: couponActivityNew,
        }

        patchUpdateCoupon(selectedCoupon._id, coupon);

        setCoupon(coupon);
        setToggleModalValue(false);

        if (history)
            history.push("/purchasetables/coupons");
    }

    const activityButtonStyle = () => {
        if (couponActivity) return "coupon_referal_button background_0D062D";
        return "coupon_referal_button background_FF3434";
    }

    return (
        <div className="">

            <div className="create_new_match_modal_title extra_padding_10 text_align_center"> {t("updateTheSelectedCouponText")} </div>

            <InputText icon={editIcon} placeholder={t("enterCouponText")} setCouponName={setCouponName} type={InputFieldsType.EDITCOUPON} />

            <InputNumber icon={editIcon} placeholder={t("enterCouponText")} setCouponDiscount={setCouponDiscount} type={InputFieldsType.EDITCOUPON} />

            <div className="order_in_line referal_type_activity_button_margin"> 
                <button className={activityButtonStyle()} onClick={() => setCouponActivity(!couponActivity)}>  
                    {couponActivity ? t("activatedText") :t("deactivatedText")} 
                </button>
            </div>

            <div className="create_new_match_button_holder">
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("completeText")} functionality={() => updateTimeOfTheCurrentMatch()} />
                </div>
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("cancelText")} functionality={() => setToggleModalValue(false)}  />
                </div>
            </div>

        </div>
    )
}

export default EditCouponModal;