import { FC } from "react"

interface ITextAreaReusable {
    icon: any,
    placeholder: any,
    value: string
    setValue(value: string): any,
}

const TextAreaReusable: FC<ITextAreaReusable> = (props) => {
    const {icon, placeholder, value, setValue} = props;

    return (
        <div className="text_area_reusable_wrapper">
            {icon && <div className="full_center position_icon"> <img src={icon} alt="home" /> </div>}
            <textarea
                value={value}
                onChange={(event: any) => setValue(event.target.value)}
                placeholder={placeholder}
                cols={30}
                rows={5} 
                maxLength={150} />
        </div>
    )
}

export default TextAreaReusable;