import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import clockIcon from "../../../assets/icons/clockIcon.svg";
import { ComponentsContext } from "../../../contexts";

interface IUpdateTimeTooltip {
    urlLink: string
}

const UpdateTimeTooltip : FC<IUpdateTimeTooltip> = ({urlLink}) => {
    const [isFieldhovered, setIsFieldHovered] = useState<boolean>(false);
    const { setToggleModalValue, setMatchPopUpType, setSelectedGameUrlToken } = useContext(ComponentsContext);
    const { t } = useTranslation();
    const urlPathChunks = urlLink.split("/");
	const urlTokenExtracted = urlPathChunks[urlPathChunks.length-1];

    const openPopUpBoxToUpdateTime = () =>{ 
        setMatchPopUpType({"modalType": "updateTime"});
        setToggleModalValue(true);
        setSelectedGameUrlToken(urlTokenExtracted);
    }

    return (
        <div className="update_time_tooltip" onMouseEnter={() => setIsFieldHovered(true)} onMouseLeave={() => setIsFieldHovered(false)} onClick={() => openPopUpBoxToUpdateTime()}>
            <img src={clockIcon} alt="time" />
            <div className={isFieldhovered ? "update_time_tooltip_hover" : "display_none"}> {t("updateTimeText")} </div>
        </div>
    )
} 

export default UpdateTimeTooltip;