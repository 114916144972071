import React, { FC, useState } from 'react';
import { useTranslation } from "react-i18next";

interface ISendPdfToEmail {
  sendEmail(enteredEmail: string): void;
  enteredEmail: string, 
  setEnteredEmail(enteredEmail: string): void;
}

const SendPdfToEmail: FC<ISendPdfToEmail> = ({ sendEmail, enteredEmail, setEnteredEmail }) => {
  const { t } = useTranslation();

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    if (newText.length <= 500) {
      setEnteredEmail(newText);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sendEmail(enteredEmail);
    }
  };

  return (
    <div>
      <div>
        <h2>{t("writeRecipientEmailText")}:</h2>
        <input
          onChange={handleTextChange}
          onKeyPress={handleKeyPress}
          value={enteredEmail}
          type="text"
          name="to_email"
          placeholder="Recipient Email"
          required
          className="input_email"
        />
      </div>
      <div className="textarea_buttons">
          <div></div>
          <div className="CarouselArrows">
              <button onClick={() => sendEmail(enteredEmail)} className="ArrowButton" style={{width: "200px"}}>{t("sendText")}</button>
          </div>
      </div>
    </div>
  );
};

export default SendPdfToEmail;
