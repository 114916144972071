import { FC } from "react";
import BuyGames from "../../components/BuyGames/BuyGames";

const PurchaseTablePage: FC<{}> = () => {
  return (
      <div className="page_content_wrapper custom_scrollbar custom_made_scrollbar">
        <BuyGames />
      </div>
  );
};

export default PurchaseTablePage;
