import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { MatchModal, Matches, PopUpModal } from "../../components";
import { Pagination } from "../../components/Atoms";
import UpdateTimeModal from "../../components/Match/Matches/UpdateTimeModal";
import { ComponentsContext, MatchContext } from "../../contexts";
import { IMatchesObject } from "../../interfaces";

const MatchesPage: FC<{}> = () => {
    const { getMatchesPreactiveMatches, listOfMatches, setRedirectToMatchPage, numberOfTablesUserPosseses } = useContext(MatchContext);
    const { matchPopUpType } = useContext(ComponentsContext);
    const { t } = useTranslation();

    const receivedMatches: any[] = listOfMatches?.docs || [];
    const numberOfPages : number = listOfMatches?.totalPages || 1;
    const [currentPage, setCurrentPage] = useState<number>(1);
    const amountOfTablesYouPossesText = t("amountOfTablesYouPossesText");

    setRedirectToMatchPage(false);
    
    useEffect(() => {
        getMatchesPreactiveMatches(currentPage);
    }, [currentPage]);

    const renderLoader = () => {
        return (
            <div className="match_loader_wrapper">
                <div className="loader"></div>
            </div>
        )
    }

    const matchModalsDic = {
        "createMatch": <MatchModal />,
        "updateMatch": <MatchModal preActiveMatch = {matchPopUpType.matchData} />,
        "updateTime": <UpdateTimeModal />
    }

    if (listOfMatches)
        return (
            <div className="page_content_wrapper custom_scrollbar custom_made_scrollbar">
                <PopUpModal>
                {matchPopUpType &&
                    matchModalsDic[matchPopUpType.modalType]
                 }
                </PopUpModal>

                <div className="history_page_header position_absolute"> 
                    <div className="gray_bold_text"> {amountOfTablesYouPossesText} </div> 
                    <div className="number_of_tables"> {numberOfTablesUserPosseses} </div>
                </div>
                
                <Matches preActiveMatches={receivedMatches} />
                <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </div>
        );

    return renderLoader();
};

export default MatchesPage;
