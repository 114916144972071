import { FC, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next";
import { AuthDataContainerContext, MatchContext, StripeContext, UserDataContext } from "../../contexts";
import { ButtonAnimationUp, Loader } from "../Atoms";
import PurchaseTypeBox from "./PurchaseComponents/PurchaseTypeBox";
import StripeCreditCardModal from "./PurchaseComponents/StripeCreditCardModal";

const BuyGames: FC<{}> = () => {
    const { userData } = useContext(UserDataContext);
    const { numberOfTablesUserPosseses } = useContext(MatchContext);
    const { getProductsEndPoint } = useContext(StripeContext);
    const { token } = useContext(AuthDataContainerContext);
    const { t } = useTranslation();
    let history = useHistory();
    
    useEffect(() => {
        getProductsEndPoint();
        // if I add getProductsEndPoint() it will be reRendering endlessly
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    const openTheDonfirmationModal = () => {
        if (history)
            history.push("purchasetables/coupons");
	}

    const openTableManagement = () => {
        if (history)
            history.push("purchasetables/tablemanagement");
	}

    return (
        <div className="buy_game_wrapper">

            <Loader />

            <div className="buy_games_header"> 
                <div className="buy_games_title_bold"> {numberOfTablesUserPosseses} </div>
                <div className="buy_games_header_text"> {t("tablesLeftText")} </div>   
            </div>   

            <div className="buy_game_body">
                <PurchaseTypeBox />
                <StripeCreditCardModal />
            </div> 

            {userData?.isAdmin &&
                <div className="coupon_button_wrapper">
                    <ButtonAnimationUp text={t("manageAllCouponsText")} functionality={openTheDonfirmationModal} />
                </div>
            }   

            {true &&
                <div className="coupon_button_wrapper" style={{ top: "50px" }}>
                    <ButtonAnimationUp text={t("manageNumberOfTables")} functionality={openTableManagement} />
                </div>
            }  
        </div>
    );
};

export default BuyGames;
