import { FC, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CardContext, CardContextNew, ComponentsContext, MatchContext, UserDataContext } from "../../../contexts";
import { ButtonAnimationUp } from "../../Atoms";
import { ActionCardModal, CreateMatchWarning, QuestionCardCreationModal } from "../..";
import UpdateCustomQuestionCard from "./UpdateCustomQuestionCard";
import UpdateCustomActionCard from "./UpdateCustomActionCard";
import mapLanguage from "../../../util/helpers/mapLanguage";
import { UpdateMatchCardContext } from "../../../contexts/UpdateMatchCardContext";

type TCardType = "Question" | "Action" | "Solution";
interface ICreateNewCardModal {  
    cardType: TCardType;
}

const CreateNewCardModal: FC<ICreateNewCardModal> = ({cardType}) => {
    const { userData } = useContext(UserDataContext);
    const { questionCustomCardsNew, actionCustomCardsNew } = useContext(CardContextNew);
    const { setToggleModalValue, popUpBoxType, selectedLanguage  } = useContext(ComponentsContext);
    const { 
        createCustomQuestionCard, 
        updateCustomActionCards,
    } = useContext(CardContext);
    const { 
        createNewCustomActionCardHTTP,
        createNewCustomQuestionCardHTTP,
        updateCustomQuestionCardHTTP,
        updateCustomActionCardHTTP,
        selectedQuestionCardForEdit, 
        selectedActionCardForEdit
    } = useContext(CardContextNew);
    const {
        createSelectedMatchNewCustomQuestionCard,
        updateSelectedMatchesCustomQuestionCard,
        createSelectedMatchNewCustomActionCard,
        updateSelectedMatchesCustomActionCard
    } = useContext(UpdateMatchCardContext);

    const { enteredMatchData } = useContext(MatchContext);

    const { t, i18n } = useTranslation();
    const updatedQuestionValue = useRef<any>({
        id: "",
        description: "",
        title: "",
        numberOfUpperTokens: 0,
        language: mapLanguage(i18n.language)
    });

    const actionCardTitle = useRef<any>();
    const actionCardDescription = useRef<any>();
    const numberOfPoints = useRef<any>();
    const questionCardOrderNumber = useRef<any>();
    const actionCardOrderNumber = useRef<any>();
    const questionCardDescription = useRef<any>();
    const updateQuestionCardOrderNumber = useRef<any>();
    const updateActionCardOrderNumber = useRef<any>();
    const initialActionObject = {
        description: "",
        id: "",
        numberOfUpperTokens: 0,
        title: ""
    };
    const updatedActionValue = useRef<any>(initialActionObject);
    const customQuestionCardsLength: number = questionCustomCardsNew.length === 0 ? 1 : questionCustomCardsNew.length + 1;
    const customActionCardsLength: number = actionCustomCardsNew.length === 0 ? 1 : actionCustomCardsNew.length + 1;
    const updateCustomQuestionCardsLength: number = questionCustomCardsNew.length === 0 ? 1 : questionCustomCardsNew.length;
    const updateCustomActionCardsLength: number = actionCustomCardsNew.length === 0 ? 1 : actionCustomCardsNew.length;

    const createOrUpdateCardText = (
        popUpBoxType === "editAction" ||
        popUpBoxType === "editQuestion" ||
        popUpBoxType === "editSelectedMatchQuestion" ||
        popUpBoxType === "editSelectedMatchAction") 
        ? t("updateCardText")
         : t("createCardText");

    const popUpTitleDic =  {
        "Action": t("actionText"),
        "Question": t("questionText"),
        "Solution": t("solutionText")
    }
    
    const createOrEdit = (popUpBoxType === "editQuestion" || popUpBoxType === "editAction" || popUpBoxType === "editSelectedMatchQuestion" || popUpBoxType === "editSelectedMatchAction") ? t("editText") : t("createText");
    const popUpBoxTitle = createOrEdit + " " + popUpTitleDic[cardType];

    const checkQuestionCardInput = () => {
        if (questionCardDescription?.current?.value.length < 1) { 
            alert(t("notificationPleaseFillAllTheFieldsText"));
            return;
        }
        createNewCustomQuestionCard();
    }

    const checkActionCardInput = () => {
        if (actionCardTitle?.current?.value.length < 1 || actionCardDescription?.current?.value.length < 1 || numberOfPoints?.current?.value < 1) { 
            alert(t("notificationPleaseFillAllTheFieldsText"));
            return; 
        } 
        if (popUpBoxType === "editQuestion") {
            alert('Now, edit question');
            return;
        }
        createNewCustomActionCard();
    }
    
    const createNewCustomQuestionCard = () => {
        const demoQuestionObject = {
            id: 666,
            title: "",
            description: questionCardDescription.current.value || "",
            order: parseInt(questionCardOrderNumber?.current?.value) || 1,
            language: mapLanguage(i18n.language),
            userEmail: userData?.email || ""
        }

        if (enteredMatchData?.idForUpdate) {
            createSelectedMatchNewCustomQuestionCard(demoQuestionObject, enteredMatchData?.idForUpdate,  selectedLanguage);
        } else {
            createNewCustomQuestionCardHTTP(demoQuestionObject, selectedLanguage);
        }
        createCustomQuestionCard(demoQuestionObject);
        setToggleModalValue(false);
    }

    const createNewCustomActionCard = () => {
        const demoActionObject = {
            id: 999,
            description: actionCardDescription?.current?.value,
            title: actionCardTitle?.current?.value,
            order: parseInt(actionCardOrderNumber?.current?.value) || 1,
            numberOfUpperTokens: numberOfPoints?.current?.value,
            language: mapLanguage(i18n.language),
            userEmail: userData?.email || ""
        }

        if (enteredMatchData?.idForUpdate) {
            createSelectedMatchNewCustomActionCard(demoActionObject, enteredMatchData?.idForUpdate,  selectedLanguage);
        } else {
            createNewCustomActionCardHTTP(demoActionObject, selectedLanguage);
        }
        updateCustomActionCards(demoActionObject);
        setToggleModalValue(false);
    }
    
    const checkInsertedCardData = () => {
        if (popUpBoxType === "editQuestion" && updatedQuestionValue?.current) {
            updateCustomQuestionCardHTTP({ ...selectedQuestionCardForEdit, description: updatedQuestionValue?.current, order: parseInt(updateQuestionCardOrderNumber?.current?.value) || selectedQuestionCardForEdit?.order, }, selectedLanguage)
            setToggleModalValue(false);
            return;
        }

        if (popUpBoxType === "editSelectedMatchQuestion" && updatedQuestionValue?.current) {
            updateSelectedMatchesCustomQuestionCard({
                matchId: enteredMatchData?.idForUpdate,
                cardId: selectedQuestionCardForEdit.id,
                card: {
                  description: updatedQuestionValue.current === "" ? selectedQuestionCardForEdit.description : updatedQuestionValue.current,
                  order: parseInt(updateQuestionCardOrderNumber?.current?.value) || selectedQuestionCardForEdit?.order,
                },             
            })
            setToggleModalValue(false);
            return;
        }

        if (popUpBoxType === "editAction" && updatedActionValue?.current) {
            updateCustomActionCardHTTP({ 
                id: selectedActionCardForEdit.id,
                title: updatedActionValue?.current.title || selectedActionCardForEdit?.title,
                description: updatedActionValue?.current.description || selectedActionCardForEdit?.description,
                order: parseInt(updateActionCardOrderNumber?.current?.value) || selectedActionCardForEdit?.order,
                numberOfUpperTokens: parseInt(updatedActionValue?.current.numberOfUpperTokens) || selectedActionCardForEdit?.numberOfUpperTokens,
            }, selectedLanguage )
            setToggleModalValue(false);
            updatedActionValue.current = initialActionObject
            return;
        }

        if (popUpBoxType === "editSelectedMatchAction" && updatedActionValue?.current) {
            updateSelectedMatchesCustomActionCard({ 
                cardId: selectedActionCardForEdit.id,
                matchId: enteredMatchData?.idForUpdate,
                card: {
                    title: updatedActionValue?.current.title || selectedActionCardForEdit?.title,
                    description: updatedActionValue?.current.description || selectedActionCardForEdit?.description,
                    order: parseInt(updateActionCardOrderNumber?.current?.value) || selectedActionCardForEdit?.order,
                    numberOfUpperTokens: parseInt(updatedActionValue?.current.numberOfUpperTokens) || selectedActionCardForEdit?.numberOfUpperTokens,
                }
            })
            setToggleModalValue(false);
            updatedActionValue.current = initialActionObject
            return;
        }
        
        if (cardType === "Action") checkActionCardInput();
        if (cardType === "Question") checkQuestionCardInput();
    }

    const popUpWindowTypeDic : any= {
        "maxNumWarning": <CreateMatchWarning warningText={t("theMaximumNumberOfCustomCardsYouCanAddText")}/>,
        "minNumWarning": <CreateMatchWarning warningText={t("theMinimumNumberOfActionCardsYouNeedText")}/>,
        "createNewQuestionCard": <QuestionCardCreationModal questionCardDescription={questionCardDescription} questionCardOrderNumber={questionCardOrderNumber} questionCardsUpperLimit={customQuestionCardsLength}/>,
        "createNewActionCard": <ActionCardModal actionCardTitle={actionCardTitle} actionCardDescription={actionCardDescription} numberOfPoints={numberOfPoints} actionCardOrderNumber={actionCardOrderNumber} actionCardsUpperLimit={customActionCardsLength} />,
        "editQuestion": <UpdateCustomQuestionCard updatedQuestionValue={updatedQuestionValue} updateQuestionCardOrderNumber={updateQuestionCardOrderNumber} questionCardsUpperLimit={updateCustomQuestionCardsLength}/>,
        "editAction": <UpdateCustomActionCard updatedActionValue={updatedActionValue} updateActionCardOrderNumber={updateActionCardOrderNumber} actionCardsUpperLimit={updateCustomActionCardsLength} />,
        "editSelectedMatchQuestion": <UpdateCustomQuestionCard updatedQuestionValue={updatedQuestionValue} updateQuestionCardOrderNumber={updateQuestionCardOrderNumber} questionCardsUpperLimit={updateCustomQuestionCardsLength}/>,
        "editSelectedMatchAction": <UpdateCustomActionCard updatedActionValue={updatedActionValue} updateActionCardOrderNumber={updateActionCardOrderNumber} actionCardsUpperLimit={updateCustomActionCardsLength} />,
    }

    return (
        <div className="create_new_match_modal">
            <div className="create_new_match_modal_title"> {popUpBoxTitle} </div>

            {popUpWindowTypeDic[popUpBoxType]}

            <div className="create_new_match_button_holder">
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={createOrUpdateCardText} functionality={() => checkInsertedCardData()} />
                </div>
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("cancelText")} functionality={() => setToggleModalValue(false)} />
                </div>
            </div>
        </div>
    )
}

export default CreateNewCardModal;