import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthDataContainerContext, ComponentsContext, UserDataContext } from "../../../contexts";
import { RenderAllUsers } from "../../index";
import AddNewUserForm from "../InviteFacilitator/AddNewUserForm";
import DeteteTheUser from "../DeleteFacilitator/DeteteTheUser";
import Pagination from "../Pagination/Pagination";
import { PDFDownloadLink, Text, Document, Page, StyleSheet, Image} from '@react-pdf/renderer';
import PlusIcon from "../../../assets/icons/yellowPlus.svg";
import ArrowDownIcon from "../../../assets/icons/arrowDown.svg";
import axios from "axios";

const UserCRUD = () => {
    const { t } = useTranslation();
    const { allUsers, userData, fetchAllUsers } = useContext(UserDataContext);
    const { toggleModalValue ,setToggleModalValue } = useContext(ComponentsContext);
    const { token } = useContext(AuthDataContainerContext);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [postsPerPage] = useState<number>(10);
    const [openUpdateFacilitator, setOpenUpdateFacilitator] = useState<boolean>(false);
    const [openDeleteFacilitator, setOpenDeleteFacilitator] = useState<boolean>(false);
    const [potencialFacilitatorsEmails, setPotencialFacilitatorsEmails] = useState([""]);

    const indexOfLastPost : number = currentPage * postsPerPage;
    const indexOfFirstPost : number = indexOfLastPost - postsPerPage;
    let currentPosts = allUsers?.slice(indexOfFirstPost, indexOfLastPost);
    
    const paginate : Function = (pageNumber: number) => setCurrentPage(pageNumber);

    const makeDarkBackgroundFromInviteFacilitator = () => {
        const darkBackground : any = document.getElementById("dark_background");
        darkBackground.style.visibility = "visible"

        setToggleModalValue(!toggleModalValue);
    }

    useEffect(() => {
        if(token) {
            axios({
                method: "get",
                url: process.env.REACT_APP_BACKEND_URL + "questionary",
                headers: { 
                    Authorization: token
                }
            })
            .then((res: any) => {
                setPotencialFacilitatorsEmails(res.data);
            })
            .catch((err: any) => console.error(err));
        }
    }, [])


    const makeDarkBackground = () => {
        const darkBackground : any = document.getElementById("dark_background");
        darkBackground.style.visibility = "visible";
    } 

    useEffect(() => {
        fetchAllUsers()
    }, []);

    return (
        <div style={{position: "relative"}}>

            { toggleModalValue && <AddNewUserForm /> }

            <div style={{flexDirection: "row", display: "flex", justifyContent: "center"}}>
                {userData?.isAdmin &&
                    <div className="invite_facilitator_button_container">
                        <button onClick={makeDarkBackgroundFromInviteFacilitator} className="invite_facilitator_button"><img src={PlusIcon} alt="" /><span style={{marginLeft: "10px", textDecorationLine: "underline"}}> {t("inviteFacilitatorText")} </span></button>
                    </div>
                }  

                {userData?.isAdmin && (
                    <div>
                        <PDFDownloadLink document={ 
                            <Document>
                                <Page style = {{paddingTop: 35, paddingBottom: 65, paddingHorizontal: 20}} >
                                    {
                                    potencialFacilitatorsEmails.length > 0 &&
                                    potencialFacilitatorsEmails?.map((value: string, index: number) => {
                                            return (
                                                <div key={index}>
                                                <Text style={{fontSize: 10, padding: '0 14', fontWeight: 600}}> {value} </Text>
                                            </div>
                                        )        
                                    })}
                                </Page>
                            </Document>
                        } 
                        fileName="potencialFacilitatorsEmails.pdf">
                            {({ loading }) => (
                                loading
                                    ?
                                    t("loadingDocumentText")
                                    :
                                    <div className="invite_facilitator_button_container">
                                        <div className="invite_facilitator_button"><img src={ArrowDownIcon} alt="" /><span style={{marginLeft: "10px", textDecorationLine: "underline"}}> {t("potentialFacilitatorsEmailsText")} </span></div>
                                    </div>
                            )}
                        </PDFDownloadLink>
                    </div>
                )}
            </div>
            

            <div className="all_users_grid">
                <RenderAllUsers currentPosts={currentPosts} setOpenUpdateFacilitator={setOpenUpdateFacilitator} setOpenDeleteFacilitator={setOpenDeleteFacilitator} makeDarkBackground={makeDarkBackground} />
            </div>

            <div>
                <Pagination currentPosts={currentPosts} setCurrentPage={setCurrentPage} currentPage={currentPage} postsPerPage={postsPerPage} totalPosts={allUsers?.length} paginate={paginate} />
            </div>

            {/* { openUpdateFacilitator && <UpdateTheUser setOpenUpdateFacilitator={setOpenUpdateFacilitator} /> } */}
                

            {openDeleteFacilitator && <DeteteTheUser setOpenDeleteFacilitator={setOpenDeleteFacilitator} /> }

        </div>
    )
}

export default UserCRUD;