import React, { FC, createContext, useState, useContext } from "react";

import axios from "axios";
import https from "https";

import ActionCard from "../util/models/ActionCard";
import QuestionCard from "../util/models/QuestionCard";
import { AuthDataContainerContext } from ".";

type TCardType = "Question" | "Action";
// type TCardType = "Question" | "Action" | "Solution";

interface ICardContext {
  existActionCards: ActionCard[][];
  customActionCards: ActionCard[][];
  existQuestionCards: QuestionCard[][]; // delete this 
  customQuestionCards: QuestionCard[];
  setExistActionCards: React.Dispatch<React.SetStateAction<ActionCard[][]>>;
  setCustomActionCards: React.Dispatch<React.SetStateAction<ActionCard[][]>>;
  setExistQuestionCards: React.Dispatch<React.SetStateAction<QuestionCard[][]>>;
  setCustomQuestionCards(customQuestionCards: QuestionCard[]): void;
  getActionCards: () => void;
  getQuestionCards: () => void;
  updateCustomActionCards: (actionCard: ActionCard) => void;
 
  removeActionCard: (actionCardId: number) => void;
  removeQuestionCard: (questionCard: QuestionCard) => void;
  concatExistAndCustomActionCards: () => ActionCard[];
  concatExistAndCustomQuestionCards: () => QuestionCard[];
  transformCustomActionCardFrom2DTo1DArray: () => ActionCard[];
  transformCustomQuestionCardFrom2DTo1DArray: () => QuestionCard[];


  defaultQuestionCards: any;
  defaultActionCards: any;
  selectedCardType: TCardType; 
  setSelectedCardType(setSelectedCardType: TCardType): void;
  selectedCustomActionCards: number[],
  setselectedCustomActionCards(selectedCustomActionCards: number[]): void;
  selectedDefaultActionCards: number[],
  setSelectedDefaultActionCards(selectedDefaultActionCards: number[]): void;
  selectedDefaultQuestionCards: number[],
  setSelectedDefaultQuestionCards(selectedDefaultQuestionCards: number[]): void;
  selectedQuestionCards: number[],
  setSelectedQuestionCards(selectedQuestionCards: number[]): void;
  createNewCustomActionCard(newCustomActionCard: any): void;
  createCustomQuestionCard: (questionCard: QuestionCard) => void;
  updateCustomQuestionCard: (questionCard: any) => void;
  selectedCustomQuestionCard: QuestionCard | undefined; 
  setSelectedCustomQuestionCard(electedCustomQuestionCard: QuestionCard | undefined): void;
  customCardSentToBack: boolean,
  setCustomCardSentToBack(customCardSentToBack: boolean): void;

  fetchedCustomActionCards: any[];
  setFetchedCustomActionCards(fetchedCustomActionCards: any[]): void;
  fetchedCustomQuestionCards: any;
  setFetchedCustomQuestionCards(fetchedCustomQuestionCards: any[]): void;

  fetchedDefaultActionCards: any[]; // not in use
  setFetchedDefaultActionCards(fetchedDefaultActionCards: any[]): void; // not in use
  fetchedDefaultQuestionCards: any; // not in use
  setFetchedDefaultQuestionCards(fetchedDefaultQuestionCards: any[]): void; // not in use

}

const CardContext = createContext({} as ICardContext);


interface CardContextProps {
  children: React.ReactNode;
}

const CardContextProvider: FC<CardContextProps> = (props) => {
  const { token } = useContext(AuthDataContainerContext);
  const [existActionCards, setExistActionCards] = useState<any[]>([ [] ]);
  const [customActionCards, setCustomActionCards] = useState<ActionCard[][]>([ [] ]);
  const [existQuestionCards, setExistQuestionCards] = useState<QuestionCard[][]>([[]]);
  const [customQuestionCards, setCustomQuestionCards] = useState<QuestionCard[]>([]);

  const [defaultActionCards, setDefaultActionCards] = useState<any>([]);
  const [defaultQuestionCards, setDefaultQuestionCards] = useState<any>([]);
  const [selectedCardType, setSelectedCardType] = useState<TCardType>("Question");
  const [selectedCustomActionCards, setselectedCustomActionCards] = useState<number[]>([]);
  const [selectedDefaultActionCards, setSelectedDefaultActionCards] = useState<number[]>([]);
  const [selectedDefaultQuestionCards, setSelectedDefaultQuestionCards] = useState<number[]>([]);
  const [selectedQuestionCards, setSelectedQuestionCards] = useState<number[]>([]);
  const [selectedCustomQuestionCard, setSelectedCustomQuestionCard] = useState<QuestionCard | undefined>();
  const [customCardSentToBack, setCustomCardSentToBack] = useState<boolean>(false);

  const [fetchedCustomActionCards, setFetchedCustomActionCards] = useState<any[]>([]);
  const [fetchedCustomQuestionCards, setFetchedCustomQuestionCards] = useState<any[]>([]);
  const [fetchedDefaultActionCards, setFetchedDefaultActionCards] = useState<any[]>([]);
  const [fetchedDefaultQuestionCards, setFetchedDefaultQuestionCards] = useState<any[]>([]);

  const agent = new https.Agent({
    rejectUnauthorized: false,
  });

  const getActionCards = () => {
    axios
      .get(process.env.REACT_APP_CMS_URL + "actionCards/getcards", {
        httpsAgent: agent,
      })
      .then((res) => {
        createActionCardsMatrix(res.data); // Milan Marinkovic Logic;
        setDefaultActionCards(res.data);
      });
  };

  const createActionCardsMatrix = (cards: ActionCard[]) => {
    let newCardsArray: ActionCard[][] = [[]];;

    for (const card of cards) {
      newCardsArray[newCardsArray.length - 1].push(card);
    }

    setExistActionCards(newCardsArray);
  };

  const getQuestionCards = () => {
    axios
      .get(process.env.REACT_APP_CMS_URL + "questionCards/getcards", {
        httpsAgent: agent,
      })
      .then((res) => {
        const response = res.data;
        createQuestionCardsMatrix(response); //Milan Marinkovic Logic;
        setDefaultQuestionCards(response);
        setSelectedQuestionCards(response.map( (item: any) => {
          return item.id;
        }))
      });
  };

  const createQuestionCardsMatrix = (cards: QuestionCard[]) => {
    let newCardsArray: QuestionCard[][] = [[]];

    for (const card of cards) {
      newCardsArray[newCardsArray.length - 1].push(card);
    }

    setExistQuestionCards(newCardsArray);
  };

  const createNewCustomActionCard = (actionCard: any) => {
      axios({
        method: "post",
        url: process.env.REACT_APP_BACKEND_URL + "action-card/create-custom-card",
        headers: { 
            Authorization: token
        },
        data: actionCard
        })
        .then((res: any) => {
            console.log(res);
        })
        .catch((err: any) => console.error(err));
  }

  const updateCustomActionCards = (actionCard: ActionCard) => {
    let numberOfRows = customActionCards.length;

    if (numberOfRows === 0) {
      customActionCards.push([]);
      numberOfRows = 1;
    }

    if (customActionCards[numberOfRows - 1].length < 5) {
      customActionCards[numberOfRows - 1].push(actionCard);
    } else {
      customActionCards.push([actionCard]);
    }
  };

  const createCustomQuestionCard = (questionCard: QuestionCard) => {
    customQuestionCards.push(questionCard);
  };

  const updateCustomQuestionCard = (questionCard: any) => {
    setCustomQuestionCards(customQuestionCards.map( (item: any) => {
      if (item.id === questionCard.id) 
        return questionCard;
      return item;
    }))
  }

  const removeActionCard = (actionCardId: number) => {
    let newCustomActionCardArray: ActionCard[][] = [[]];
    let numberOfRows = 1;

    for (const row of customActionCards) {
      for (const card of row) {
        if (card.id === actionCardId) {
          continue;
        }

        if (newCustomActionCardArray[numberOfRows - 1].length < 5) {
          newCustomActionCardArray[numberOfRows - 1].push(card);
        } else {
          newCustomActionCardArray.push([card]);
          numberOfRows = newCustomActionCardArray.length;
        }
      }
    }

    setCustomActionCards(newCustomActionCardArray);
  };

  const removeQuestionCard = (questionCard: QuestionCard) => {
    setCustomQuestionCards(customQuestionCards.filter( (card: any) => {
      return questionCard.id !== card.id;
    }));
  };

  const concatExistAndCustomActionCards = (): ActionCard[] => {
    let actionCards: ActionCard[] = [];

    for (const row of existActionCards) {
      actionCards = actionCards.concat(row);
    }

    for (const row of customActionCards) {
      actionCards = actionCards.concat(row);
    }

    return actionCards;
  };

  const concatExistAndCustomQuestionCards = (): QuestionCard[] => {
    let questionCards: QuestionCard[] = [];

    for (const row of existQuestionCards) {
      questionCards = questionCards.concat(row);
    }

    for (const row of customQuestionCards) {
      questionCards = questionCards.concat(row);
    }

    return questionCards;
  };

  const transformCustomActionCardFrom2DTo1DArray = (): ActionCard[] => {
    let customActionCards1D: ActionCard[] = [];

    for (const row of customActionCards) {
      for (const card of row) {
        customActionCards1D.push(card);
      }
    }

    return customActionCards1D;
  };

  const transformCustomQuestionCardFrom2DTo1DArray = (): QuestionCard[] => {
    let customQuestionCards1D: QuestionCard[] = [];
    return customQuestionCards1D;
  };

  const providerValue = {
    existActionCards,
    customActionCards,
    existQuestionCards,
    customQuestionCards,
    setExistActionCards,
    setCustomActionCards,
    setExistQuestionCards,
    setCustomQuestionCards,
    getActionCards,
    getQuestionCards,
    updateCustomActionCards,
    createCustomQuestionCard,
    removeActionCard,
    removeQuestionCard,
    concatExistAndCustomActionCards,
    concatExistAndCustomQuestionCards,
    transformCustomActionCardFrom2DTo1DArray,
    transformCustomQuestionCardFrom2DTo1DArray,

    defaultQuestionCards,
    defaultActionCards,
    selectedCardType,
    setSelectedCardType,
    selectedCustomActionCards,
    setselectedCustomActionCards,
    selectedDefaultActionCards, 
    setSelectedDefaultActionCards,
    selectedDefaultQuestionCards, 
    setSelectedDefaultQuestionCards,
    selectedQuestionCards,
    setSelectedQuestionCards,
    createNewCustomActionCard,
    updateCustomQuestionCard,
    selectedCustomQuestionCard, 
    setSelectedCustomQuestionCard,
    customCardSentToBack, 
    setCustomCardSentToBack,

    fetchedCustomActionCards, 
    setFetchedCustomActionCards,
    fetchedCustomQuestionCards, 
    setFetchedCustomQuestionCards,
    fetchedDefaultActionCards, 
    setFetchedDefaultActionCards,
    fetchedDefaultQuestionCards, 
    setFetchedDefaultQuestionCards,
  };

  return (
    <CardContext.Provider value={providerValue}>
      {props.children}
    </CardContext.Provider>
  );
};

export { CardContext, CardContextProvider };
