import React, { FC } from "react";

import { AuthContextProvider } from "./AuthContextProvider";
import { AuthDataContainerContextProvider } from "./AuthDataContainer";
import { AxiosContextProvider } from "./AxiosContextProvider";
import { UserDataContextProvider } from "./UserDataContext";
import { CardContextProvider } from "./CardContextProvider";
import { CardContextNewProvider } from "./CardContextNew";
import { CouponContextProvider } from "./CouponContext";
import { MatchContextProvider } from "./MatchContextProvider";
import { StatisticContextProvider } from "./StatisticContextProvider";
import { SocketContextProvider } from "./SocketContext";
import { StripeContextProvider } from "./StripeContext";
import { ComponentsContextProvider } from "./ComponentsContext";
import { LearningContextProvider } from "./LearningContext";
import { LanguageContext, LanguageContextProvider } from "./LanguageContext";
import { NotificationContextProvider } from "./NotificatoinContext";
import { UpdateMatchCardContextProvider } from "./UpdateMatchCardContext";

interface GlobalContextProps {
  children: React.ReactNode;
}

const GlobalContextProvider: FC<GlobalContextProps> = (props) => {
  return (
    <SocketContextProvider>
      <AuthDataContainerContextProvider>
        <AxiosContextProvider>
          <AuthContextProvider>
            <UserDataContextProvider>
              <CardContextProvider>
                <CardContextNewProvider>
                  <UpdateMatchCardContextProvider>
                  <CouponContextProvider>
                    <MatchContextProvider>
                      <StatisticContextProvider>
                        <StripeContextProvider>
                          <ComponentsContextProvider>
                            <LearningContextProvider>
                              <NotificationContextProvider>
                                <LanguageContextProvider>
                                  {props.children}
                                </LanguageContextProvider>
                              </NotificationContextProvider>
                            </LearningContextProvider>
                          </ComponentsContextProvider>
                        </StripeContextProvider>
                      </StatisticContextProvider>
                    </MatchContextProvider>
                  </CouponContextProvider>
                  </UpdateMatchCardContextProvider>
                </CardContextNewProvider>
              </CardContextProvider>
            </UserDataContextProvider>
          </AuthContextProvider>
        </AxiosContextProvider>
      </AuthDataContainerContextProvider>
    </SocketContextProvider>
  );
};

export default GlobalContextProvider;
