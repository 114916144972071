import { FC, useContext, useState } from "react";
import {useTranslation} from "react-i18next";
import { AuthDataContainerContext, UserDataContext } from "../../../contexts";
import { ButtonAnimationUp } from "../../Atoms"; 
import axios from "axios";

interface IChangePassword {
    setOpenChangePassword: React.Dispatch<React.SetStateAction<boolean>>,
    usersEmail: String   
}

const ChangePasswordForm: FC<IChangePassword > = ({setOpenChangePassword, usersEmail: email}) => {
    const { fetchAllUsers } = useContext(UserDataContext);
    const { token } = useContext(AuthDataContainerContext);
    const {t} = useTranslation();
    const [newUserPassword, setNewUserPassword] = useState<string>("");

    const addFacilitator = async() => {
        if(token) {
            axios({
                method: "patch",
                url: process.env.REACT_APP_BACKEND_URL + "users/change-password",
                headers: { 
                    Authorization: token
                },
                data: {
                    email: email,
                    password: newUserPassword
                }
            })
            .then((res) => {
                console.log(res, "proslo");
            })
            .catch((err) => console.error(err));

            fetchAllUsers()

            const darkBackground : any = document.getElementById("dark_background");
            darkBackground.style.visibility = "hidden"; 

            setOpenChangePassword(false)
        }
    }

    const cancelInvite = () => {
        setOpenChangePassword(false)

        const darkBackground : any = document.getElementById("dark_background");
        darkBackground.style.visibility = "hidden"; 
    }

    return (
        <div className="change_password_box">
            <div className="change_password_heading"> Change password </div>
            <div className="change_password_input_fields"> 
                <input style={{border: "none", background: "transparent", outline: "none"}}
                    autoFocus
                    maxLength={50}
                    type='password'
                    value={newUserPassword}
                    placeholder="Enter new password"
                    onChange={(event: any) => setNewUserPassword(event?.target?.value)}
                />
            </div>

            <div className="create_new_match_button_holder change_password_buttons">
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text="Change facilitators password" functionality={() => addFacilitator()} />
                </div>
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text="Cancel" functionality={cancelInvite}  />
                </div>
            </div>

        </div>
    )
}

export default ChangePasswordForm;