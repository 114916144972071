import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { ActionCard, QuestionCard } from "../..";
import { CardContext, CardContextNew, MatchContext } from "../../../contexts";
import SelectAllCards from "./SelectAllCards";
import checkStroke from "../../../assets/icons/checkStroke.svg";

export const DefaultCardsPanelMemo = () => {
    const { selectedCardType} = useContext(CardContext);
    const { isResilienceCardsActive, setIsResilienceCardsActive } = useContext(MatchContext);
    const { 
        actionDefaultCardsNew, 
        questionDefaultCardsNew, 
        setDefaultQuestionCardIds, 
        setDefaultActionCardIds,
        defaultQuestionCardIds,
        defaultActionCardIds
    } = useContext(CardContextNew);
    const { t } = useTranslation();

    const selectCard = () => {
        setIsResilienceCardsActive(!isResilienceCardsActive);   
    }

    const renderQuestionCards = () => {
        return questionDefaultCardsNew.map( (cardData: any, index: number) => {
            return (
                <div key={index}> 
                    <QuestionCard questionCard={cardData} index={index} />
                </div>
            )
        })
    }

    const renderActionCards = () => {
        return actionDefaultCardsNew.map( (cardData: any, index: number) => {
            return (
                <div key={index}> 
                    <ActionCard actionCard={cardData} index={index} isCustom={false} />
                </div>
            )
        })
    }
   
    const renderSolutionCards = () => {
        return (
            <div className="resilience_body_wrapper">
                <div className="card_check_all_box" style={{width: "30px"}} onClick={() => selectCard()} > 
                    {isResilienceCardsActive && <img src={checkStroke} alt="check" />}
                </div>
                <div className="left_margin_20">Use resilience cards </div>
            </div>
        )
    }

    const cardPanelByCardTypeDic = {
        "Question": renderQuestionCards(),
        "Action": renderActionCards(),
        "Solution": renderSolutionCards()
    }

    const selectAllDefaultPanelByCardTypeDic = {
        "Question":  <SelectAllCards arrayOfCards={questionDefaultCardsNew} arrayOfCardsIds={defaultQuestionCardIds} setArrayOfCardsIds={setDefaultQuestionCardIds} isHavingDaliaCardsOption={true} />,
        "Action":  <SelectAllCards arrayOfCards={actionDefaultCardsNew} arrayOfCardsIds={defaultActionCardIds} setArrayOfCardsIds={setDefaultActionCardIds} />
        // "Solution":  null
    }

    const defaultCardsNumberByCardTypeDic = {
        "Question": questionDefaultCardsNew.length || 0,
        "Action": actionDefaultCardsNew.length || 0
        // "Solution": 0
    }

    const numberOfSelectedCards = {
        "Question": <div>{defaultQuestionCardIds?.length ?? 0 } </div>,
        "Action": <div>{defaultActionCardIds?.length ?? 0 } </div>
        // "Solution": 0
    }

    return (
        <div className="cards_panel_board">
            <div className="existing_cards_text"> 
                <div className="flex">
                    {t("existingCardsText")} 
                    <span className="grey_color margin_left_10 flex" > ({numberOfSelectedCards[selectedCardType]}/{defaultCardsNumberByCardTypeDic[selectedCardType]}) </span> 
                </div>
                {selectAllDefaultPanelByCardTypeDic[selectedCardType]}
            </div>
            <div className="default_dilemma_cards_wrapper"> 
                {cardPanelByCardTypeDic[selectedCardType]}
            </div>
        </div>
    )
}

const DefaultCardsPanel = React.memo(DefaultCardsPanelMemo);

export default DefaultCardsPanel;