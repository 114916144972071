import { useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AuthDataContainerContext, ComponentsContext } from "../../contexts";
import logOutIcon from "../../assets/icons/logOut.svg";

const useOutsideAlerter = (ref: any) => {
    const { setIsAnyDropDownBoxOpen } = useContext(ComponentsContext);

    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsAnyDropDownBoxOpen({"logOut": false, "languages": false})
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, setIsAnyDropDownBoxOpen]);
}

const LogOutDropDownBox = () => {
    const { t } = useTranslation();
    const { logOut  } = useContext(AuthDataContainerContext);
    const { setIsAnyDropDownBoxOpen } = useContext(ComponentsContext);

    let history = useHistory();
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const goToLogOut = () => {
        history.push('/');
        setIsAnyDropDownBoxOpen({"logOut": false, "languages": false})
        logOut();
    }
    
    return (
        <div ref={wrapperRef} className="drop_down_box_wrapper">
            <div className="log_out_wrapper" >
                <img className="log_out_icon" src={logOutIcon} alt="logout"  onClick={() => goToLogOut()} />
                <div className="log_out_text"  onClick={() => goToLogOut()}> {t("logOutText")} </div>
            </div>
        </div>
    )
}

export default LogOutDropDownBox;