import { FC } from "react";

type TInputType = "text" | "number" | "email" | "password";

interface IInputFieldNum {
    inputType?: TInputType;
    placeholder: string;
    takeInputValue(input: string | number): void;
    refInput?: any;
    isEnterPressed?(): any;
    isAlignedToRight?: boolean;
    value?: any;
    updateValue?(value: number): void;
}

const InputFieldNum: FC<IInputFieldNum> = ({inputType, placeholder, takeInputValue, refInput, isEnterPressed, isAlignedToRight, value, updateValue}) => {
    const type = inputType ? inputType : "text";
    const maxNumberOfTablesThatUserCanInsert: Number = 51;

    const onBlur = (e: any) => {
        takeInputValue(e.target.value);
    }
    
    const onKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            if (isFunction(isEnterPressed) && isEnterPressed) isEnterPressed();
            takeInputValue(e.target.value);
        }
    }

    const isFunction = (value: any) => {
        return typeof value === "function" ? true : false;
    }

    const inputStyle = () => {
        if (isAlignedToRight) return "form-control text_align_right" ;
            return "form-control";
    }

    const updateNumberOfTables = (event: any) => {
        const enteredValue : any = parseInt(event.target.value);
        if (typeof enteredValue === "number" && enteredValue > 0 && enteredValue < maxNumberOfTablesThatUserCanInsert && updateValue)
		    updateValue(enteredValue);
    }

    const htmlInputField = (type: string) => {
        return (
            <input 
                type={type}  
                className={inputStyle()} 
                placeholder={placeholder} 
                onBlur={(e) => onBlur(e)}
                onKeyPress={onKeyPress}
                ref={refInput}
                value={value}
                onChange={updateNumberOfTables}
            />
        )
    }

    const inputDict = {
        "text": htmlInputField("text"),
        "number": htmlInputField("number"),
        "email": htmlInputField("email"),
        "password": htmlInputField("password"),
    }
    
    return <>{inputDict[type]}</>;
}

export default InputFieldNum;
