import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { AuthDataContainerContext, NotificationContext, UserDataContext } from "../../contexts";
import NotificationRed from "../../assets/icons/notificationRed.svg";
import NotificationGrey from "../../assets/icons/notificationGrey.svg";
import SetNotificationSeen from "../../notificationService/SetNotificationSeen";

interface INotification {
    facilitator: string,
    key: string,
    link: string,
    message: string,
    seen: boolean,
    updatedAt?: string,
    createdAt?: string,
    __v?: number,
    _id: string
}

const Notifications = () => {
    const { token } = useContext(AuthDataContainerContext);
    const { userData } = useContext(UserDataContext);
    const { allNotifications, labelNotificationAsSeen, fetchAllNotifications } = useContext(NotificationContext);
    const userEmail: string = userData?.email || "";

    const updateNotificationToBeSeen = (notification: INotification) => {
        if (!notification.seen) 
            labelNotificationAsSeen(notification);     

        if (notification.seen) {
            const notificatoinMessage: string = notification?.message;
            const firstBracketIndex = notificatoinMessage.lastIndexOf("(")+1;
            const lastBracketIndex = notificatoinMessage.lastIndexOf(")");
            const gameToken = notificatoinMessage.substring(firstBracketIndex, lastBracketIndex); 

            localStorage.setItem(gameToken, userEmail);
            window.open(process.env.REACT_APP_GAME + "spectator/" + gameToken);
        }
    }

    useEffect(() => {
        if(userData !== undefined && token){
            fetchAllNotifications();
        }
    }, [token, userData])

    return (
        <div className="notifications_wrapper"> 
           {allNotifications.map( (notification: any, index: number) => {
               return (
                <div onClick={() => updateNotificationToBeSeen(notification)} key={index} className="notifications cursor_pointer">
                    <img style={{marginRight: "20px"}} src={notification.seen === true ? NotificationGrey : NotificationRed} alt="icon" />
                    {notification?.message}
                </div>
               )
            })}
        </div>
    )
}

export default Notifications;