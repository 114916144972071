import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RegularInput, TextAreaReusableRef } from "../../Atoms";
import messageIcon from "../../../assets/icons/messageIcon.svg";
import tablesIcon from "../../../assets/icons/tablesIcon.svg";

interface IQuestionCardCreationModal {
    questionCardDescription: any;
    questionCardOrderNumber: any;
    questionCardsUpperLimit: number;
}

const QuestionCardCreationModal: FC<IQuestionCardCreationModal> = ({questionCardDescription, questionCardOrderNumber, questionCardsUpperLimit}) => {
    const { t } = useTranslation();
    const upperLimitReachedText = t("youCannotAddACardWithGreaterOrderText") + `${questionCardsUpperLimit}`
    return (
        <div>
            <div className="textarea_field_holder">
                <TextAreaReusableRef
                    icon={messageIcon} 
                    placeholder={t("addDescriptionText")} 
                    refInput={questionCardDescription}
                />
            </div>
            <div className="input_field_holder">
                <RegularInput 
                    icon={tablesIcon} 
                    placeholder={t("newCardOrderNumberText")}
                    inputType="number" 
                    takeInputValue={(data) => console.log(data)} 
                    refForCounter={questionCardOrderNumber} 
                    disabled={true}
                    counter={true} 
                    upDownArrows={true}
                    incrementType="number"
                    initialValue={1}
                    upperLimitValue={questionCardsUpperLimit}
                    upperLimitCustomAlertText={upperLimitReachedText}
                />
            </div>
        </div>
    )
}

export default QuestionCardCreationModal;