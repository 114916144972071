import { FC } from "react";
import { MyProfile } from "../../components";

const MyProfilePage: FC<{}> = () => {
  return (
    <div className="page_content_wrapper custom_scrollbar custom_made_scrollbar" >
        <MyProfile />
    </div>
  );
};

export default MyProfilePage;
