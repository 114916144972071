import React from "react";
import { FC, useContext, useState } from "react"
import { CouponContext } from "../../../contexts";
import { InputFieldsType } from "../../../util/enums/InputFieldsType";

interface IInputNumber {
    icon: any;
    placeholder: any;
    setCouponDiscount(couponDiscount: any): any;
    type: InputFieldsType;
}

const InputNumber: FC<IInputNumber> = (props) => {
    const { selectedCoupon } = useContext(CouponContext);
    const { icon, placeholder, setCouponDiscount, type } = props; 
    const eitCouopnDiscount: number = selectedCoupon?.discount*100 || 0.5;

    const initialValueDic = {
        "EDITCOUPON": eitCouopnDiscount,
        "CREATECOUPON": 0.5
    }

    const minDic = {
        "EDITCOUPON": 0,
        "CREATECOUPON": 0
    }

    const maxDic = {
        "EDITCOUPON": 100,
        "CREATECOUPON": 100
    }

    const [value, setValue] = useState<any>({val: initialValueDic[type], focused: false});

    const updateValue = (e: any) => {
        const enteredValue = e.target.value;
        const condition = enteredValue <= minDic[type] && enteredValue >= maxDic[type];
        if (condition) return;

        setValue({
            val: enteredValue,
            focused: value.focused
        });
    }

    const onBlurSetValues = (e: any) => {
        setValue({ val: e.target.value, focused: false});
        setCouponDiscount(e.target.value);
    }
   
    return (
        <div className="input_text_wrapper">
            {icon && <div className="full_center position_icon"> <img src={icon} alt="home" /> </div>}
            <input
                type="number"
                defaultValue={initialValueDic[type]}
                placeholder={placeholder}

                onChange={(e: any) => updateValue(e)}
                onFocus={(e: any) => setValue({ val: e.target.value, focused: true })}
                onBlur={(e: any) => onBlurSetValues(e)}

                min={minDic[type]}
                max={maxDic[type]}
            />
        </div>
    )
}

export default InputNumber