import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ComponentsContext, UserDataContext } from "../../contexts";
import HeaderMenuDropDownBox from "./HeaderMenuDropDownBox";
import logoUppiness from "../../assets/icons/logoUppiness.svg";

const HeaderMenu = () => {
	const { selectedLanguage, currentlySelectedScreen } = useContext(ComponentsContext);
	const { userData } = useContext(UserDataContext);
	const [profilePicture, setProfilePicture] = useState<any>("");
	const { t } = useTranslation();
	let history = useHistory();
	const imageUrl =  "https://api-admin.uppinessgame.com/images/";
	// const imageUrl =  "http://admin-api.uppiness.marbleit.rs/images/";
	
	const languageAbbrevationDic : any = {
		"English": "EN",
		"Hebrew": "HE",
		"Chinese": "CH",
		"Spanish": "SP",
		"French": "FR",
		"German": "DE",
		"Portuguese" :" PT",
	}
	
	const language: string = languageAbbrevationDic[selectedLanguage];
	
	const userRole = userData?.isAdmin ? t("adminText").toUpperCase() : t("facilitatorTitleText").toUpperCase();

	useEffect(() => {
		const profileImage = `${imageUrl}${userData?.pictureUrl}`
        setProfilePicture(profileImage);

	}, [userData])


	return (
		<div className="header_wrapper">
			<div className="header_left"> 
				<img src={logoUppiness} className="header_left_logo" alt="Uppiness" onClick={()=>history.push('/matches')} />
				<div className="header_left_dashboard_panel"> {t("dashboardPanelCapitalText")} </div>
			</div>
			<div className="header_right"> 
				<div className="header_page_name"> {currentlySelectedScreen} </div>
				<div className="header_personalization">
					<div className="current_language"> {language} </div>
					<HeaderMenuDropDownBox typeOfDropDown="languages" />
					<div className="user_info"> 
						<div> {userData?.firstName} </div>
						<div className="user_info_role"> {userRole} </div>
					</div>
					<img src={profilePicture} className="profile_image" alt="down" />
					<HeaderMenuDropDownBox typeOfDropDown="logOut" />
				</div>
			</div>
		</div> 
	)
};

export default HeaderMenu;
