import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe, Stripe} from '@stripe/stripe-js';

import { AuthDataContainerContext, ComponentsContext, StripeContext, UserDataContext } from "../../../contexts";
import CreditCardForm from "./CreditCardForm";

export interface CreditCard {
    brand: string;
    name: string;
    exp_month: number;
    exp_year: number;
    last4: string;
    token: string;
}

const stripeKey = "pk_live_51JhEWNILJWGWXPyvK0o5LplJB5YdyWmu1gJym4TRFN3rkAIQAg6R064RjJgmJmPrvRSc88cMN9OpJzLl6oNWJn7d005cajebgN";
// const stripeKey = "pk_test_51JhEWNILJWGWXPyvGnCN1zLbIL3p10ZTZwdYqYezlZrY7USwftyRXixudT8hcghwqe4EAsZtjBLMbJbULvBrbBwh00KtJ9NB8B";
// const stripeKey = "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"

const StripeCreditCardModal = () => {
    const { receivedData, selectedNumberOfGames, purchaseTablesEndPoint, setTablePurchaseIsLoading, enteredCouponName } = useContext(StripeContext);
    const { setNotificationText, setIsNotificationActivated } = useContext(ComponentsContext);
    const { token, setJwtToken, setCreditCardToken} = useContext(AuthDataContainerContext);
    const { userData } = useContext(UserDataContext);
    const [stripe, setStripe] = useState<Stripe | null>(null);
    const [creditCard, setCreditCard] = useState({} as CreditCard);
    const userEmail = userData?.email || "";
    const {t} = useTranslation();
    let history = useHistory();

    useEffect(() => {
        if (!creditCard.token) return; 
		axios({
            method: "post",
            url: process.env.REACT_APP_BACKEND_URL + "purchase/" + creditCard.token,
            headers: { 
                Authorization: token
            },
            data: {
                id: receivedData.id,
                amount: selectedNumberOfGames,
                couponCode: enteredCouponName
            }
		})
		.then((res) => {
            const userEmail = userData?.email || "";
            setTablePurchaseIsLoading(false);
            history.push('/matches');
            setJwtToken(res.data);
            purchaseTablesEndPoint(userEmail, 10)
            setCreditCardToken(creditCard.token);
            setNotificationText(t("yourPurchaseWasSuccessfulText"));
            setIsNotificationActivated(true);
		})
		.catch((err) => {
            setTablePurchaseIsLoading(false);
            alert("server responded with some error. Please try again.");
            console.error(err);
            console.log(err);
            console.log("Error", err.message);
        });

	}, [creditCard, history, receivedData.id, selectedNumberOfGames, token]);

    useEffect(() => {
        loadStripe(stripeKey).then((value) => {
            setStripe(value);
            // setPurchaseToken(value?.e._apiKey);
        });
    }, []);

    return (
        <div className="stripe_credit_card_modal_wrapper" >
            <div className="">
                <Elements stripe={stripe}>
                    <CreditCardForm setCreditCard={setCreditCard} />
                </Elements>
            </div>
        </div>
    );
};

export default StripeCreditCardModal;
