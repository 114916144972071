import { FC, useEffect, useState } from "react";
import { InputField, InputFieldNum, UpDownArrows } from "..";
import { msToTime } from "./helpers";

type TInputType = "text" | "number" | "email" | "password";
type TIncrementType = "number" | "time";
interface IRegularInput {
    icon?: string,
    placeholder: string,
    clock?: boolean,
    counter?: boolean,
    editIcon?: any,
    upDownArrows?: boolean,
    inputType?: TInputType,
    refInput?: any;
    refForCounter?: any;
    takeInputValue(input: string | number): void;
    isEnterPressed?(): void;
    edit?(): void
    disabled?: boolean;
    initialValue?: number;
    incrementType?: TIncrementType;
    endIcon?: any;
    setCurrentlyInsertedNumber?(currentlyInsertedNumber: number): void;
    upperLimitValue?: number;
    upperLimitCustomAlertText?: string;
}

const RegularInput: FC<IRegularInput> = (props) => {
  const {icon, placeholder, clock, counter, editIcon, upDownArrows, inputType, refInput, refForCounter, takeInputValue, isEnterPressed, edit, disabled, initialValue, incrementType, endIcon, setCurrentlyInsertedNumber, upperLimitValue, upperLimitCustomAlertText} = props;
  const [currentEnteredNumber, setcurrentEnteredNumber] = useState<number>(initialValue || 1);

  useEffect(() => {
    if (setCurrentlyInsertedNumber) setCurrentlyInsertedNumber(currentEnteredNumber);
    // if I add setCurrentlyInsertedNumber() as a dependancy it will rerender 2 times.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEnteredNumber]);
  
  
  return (
    <div className="input_field_wrapper">
      {icon && <div className="input_field_icon full_center"> <img src={icon} alt="home" /> </div>}
      <div className="input_field_text left_center"> 
        <InputField
          inputType={inputType}
          placeholder={placeholder}
          takeInputValue={takeInputValue}
          refInput={refInput}
          disabled={disabled}
          isEnterPressed={isEnterPressed}
        /> 
      </div>
      {clock && 
      <div className="input_field_time full_center"> 
        <div className="input_field_time_hours_and_minutes"> {msToTime(currentEnteredNumber, "pure")} </div>
        <div className="hide_input_field">
            <InputFieldNum
              inputType="number"
              placeholder={"1"}
              takeInputValue={takeInputValue}
              refInput={refInput}
              isEnterPressed={isEnterPressed}
              isAlignedToRight={true}
              value={currentEnteredNumber}
              updateValue={setcurrentEnteredNumber}
          />
          </div>
      </div>}
      {counter && 
        <div className="input_field_counter full_center"> 
            <InputFieldNum
              inputType="number"
              placeholder={"1"}
              takeInputValue={takeInputValue}
              refInput={refForCounter}
              isEnterPressed={isEnterPressed}
              isAlignedToRight={true}
              value={currentEnteredNumber}
              updateValue={setcurrentEnteredNumber}
            /> 
        </div>}
      {editIcon && edit && <div className="input_field_edit full_center" onClick={edit}> <img src={editIcon} alt="home" /> </div>}
      {upDownArrows && incrementType && setcurrentEnteredNumber && <UpDownArrows currentEnteredNumber={currentEnteredNumber} updateValue={setcurrentEnteredNumber} incrementType={incrementType} upperLimitValue={upperLimitValue} upperLimitCustomAlertText={upperLimitCustomAlertText}/>}
      {endIcon && edit && <div onClick={edit} className="input_field_end_icon">
        <img src={endIcon} alt="end" />
      </div>}
    </div>
  )
}

export default RegularInput;