import React, { FC, createContext, useContext } from "react";

import axios, { AxiosInstance, AxiosResponse } from "axios";

import { AuthDataContainerContext } from "./AuthDataContainer";

interface IAxiosContext {
  adminBackend: AxiosInstance;
}

const AxiosContext = createContext({} as IAxiosContext);

interface AxiosContextProps {
  children: React.ReactNode;
}

const AxiosContextProvider: FC<AxiosContextProps> = (props) => {
  const { logOut } = useContext(AuthDataContainerContext);

  const adminBackend: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });

  const handleError = async (error: any): Promise<any> => {
    const originalRequest = error.config;

    let token: string | null = localStorage.getItem("token");
    let email: string | null = localStorage.getItem("email");

    if (!doesTokenExistCheckStatusAndisFirstRequest(token, error, originalRequest))
      return Promise.reject(error);

    originalRequest._retry = true;

    let a = await refreshToken(token, email, originalRequest);
    
    return a;
  };

  const refreshToken = async (token: string | null, email: string | null, originalRequest: any) => {
    try{
      let result: AxiosResponse<any> = await axios.post(
        process.env.REACT_APP_BACKEND_URL + "auth/refresh-token",
        {
          token,
          email
        }
      );

      if (result.status !== 200) return;

      localStorage.setItem("token", result.data.token);

      originalRequest.headers["authorization"] = result.data.token;

      return await axios(originalRequest);
    }
    catch(e){
      logOut();
    }
  };

  const doesTokenExistCheckStatusAndisFirstRequest = 
  (token: string | null, error: any, originalRequest: any): boolean => {
    if (token && error.response.status === 403 && !originalRequest._retry)
      return true;
    else 
      return false;
  };

  adminBackend.interceptors.request.use(
    (config) => {
      const token: string | null = localStorage.getItem("token");

      if (token) {
        config.headers["authorization"] = token;
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  adminBackend.interceptors.response.use((response) => {
    return response;
  }, handleError);

  const providerValue = { adminBackend };

  return (
    <AxiosContext.Provider value={providerValue}>
      {props.children}
    </AxiosContext.Provider>
  );
};

export { AxiosContext, AxiosContextProvider };
