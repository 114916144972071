import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserDataContext } from "../../../contexts";

const DataGridHeader = () => {
    const { t } = useTranslation();
    const { userData } = useContext(UserDataContext);
    

    return (
        <div className={userData?.isAdmin ? "data_grid_header_container" : "data_grid_header_container_facilitator_view"}>
            <div className="field_name_type_one"> {t("facilitatorTitleText")} </div>
            <div className="field_name_type_one"> {t("emailNoDashText")} </div>
            {userData?.isAdmin && <div className="field_name_type_three"> {("Purchased tables")} </div>}
            {userData?.isAdmin && <div className="field_name_type_three"> {("Used tables")} </div>}
            <div className="field_name_type_two"> {t("socialText")} </div>
            <div className="field_name_type_two"> {t("countryText")} </div>
            {userData?.isAdmin &&
                <div className="field_name_type_two"> {t("removeText")} </div>
            }
        </div>
    )
}

export default DataGridHeader