import { FC, ReactNode, useContext } from "react";
import { ComponentsContext } from "../../contexts";

interface IPopUpModal {
    children: ReactNode;
}

const PopUpModal: FC<IPopUpModal> = ({children}) => {
    const { toggleModalValue } = useContext(ComponentsContext);

    if (!toggleModalValue)
        return <div></div>

    return (
        <div className="popup_modal_background">
            <div className="popup_modal_body">
                {children}
            </div>
        </div>
    )
}

export default PopUpModal;