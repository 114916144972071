type TTime = "pure" | "letters";

const msToTime = (duration: number, tymePrintStyle: TTime) => {
    let minutes: any = Math.floor((duration / (1000 * 60)) % 60);
    let hours: any = Math.floor((duration / (1000 * 60 * 60)) % 24);
  
    hours = (hours < 10) ? "0" + hours : hours;
    minutes = (minutes < 10) ? "0" + minutes : minutes;

    const timePrintStyleDic = {
        "pure": hours + " : " + minutes,
        "letters": hours + "h " + minutes + "min"
    }
  
    return timePrintStyleDic[tymePrintStyle]
}

export default msToTime;