import { FC } from "react";
import { useTranslation } from "react-i18next";

const HistoryStatistics : FC<{}> = () => {
    const { t } = useTranslation();

    return (
        <div className="history_statistics_wrapper">
           {t("helloHistoryStatistics")}
        </div>      
    )
}

export default HistoryStatistics;