import { FC } from "react";
import { UserCRUD } from "../../components";

const FacilitatorsPage: FC<{}> = () => {
	return (
		<div className="page_content_wrapper custom_scrollbar custom_made_scrollbar">
			<UserCRUD />
		</div>
	);
};

export default FacilitatorsPage;
