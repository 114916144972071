import React, {FC, useContext, useEffect} from 'react';
import { Route, useHistory } from "react-router-dom";
import { AuthDataContainerContext } from '../../contexts';
import { LogInPage } from '../../pages';
import { isJwtValid } from "../../util/JwtUtil"

interface ProtectedRoutePrpos {
	component: any;
    exact?: any;
    path?: string;
}

const ProtectedRoute: FC<ProtectedRoutePrpos> = ({ component: Component, ...restOfProps } ) => {
    const { token } = useContext(AuthDataContainerContext);
    const { logOut  } = useContext(AuthDataContainerContext);
    let history = useHistory();
   
    useEffect(() => {
        if (token) {
            if(!isJwtValid(token)) {
                history.push('/');
                logOut();
            }
        }
    }, [token])
    
    return (
        <Route
            {...restOfProps} 
            render = { (props) => 
                isJwtValid(token) ? <Component {... props} /> : <LogInPage />
            } 
        />
    );
}

export default ProtectedRoute;