const mapLanguage = (language: any) => {
    switch(language) {
        case "en": return "English";
        case "he": return "Hebrew";
        case "ch": return "Chinese";
        case "es": return "Spanish";
        case "fr": return "French";
        case "de": return "German";
        case "pt": return "Portuguese";
        default: return "English";
    }
}

export default mapLanguage;