import { FC, createContext, useState, useContext } from "react";

import { AxiosContext } from "./AxiosContextProvider";

import Statistic from "../util/models/Statistic";

interface IStatisticContext {
  statistic: Statistic | undefined;
  getStatisticsById: (id: string) => void;
}

const StatisticContext = createContext({} as IStatisticContext);

interface StatisticContextProps {
  children: React.ReactNode;
}

const StatisticContextProvider: FC<StatisticContextProps> = (props) => {
  const [statistic, setSatatistic] = useState<Statistic>();

  const { adminBackend } = useContext(AxiosContext);

  const getStatisticsById = (id: string): void => {
    adminBackend
      .get(process.env.REACT_APP_BACKEND_URL + `statistics/${id}`)
      .then((res) => {
        if (res) setSatatistic(res.data);
      });
  };

  const providerValue = {
    statistic,
    getStatisticsById,
  };

  return (
    <StatisticContext.Provider value={providerValue}>
      {props.children}
    </StatisticContext.Provider>
  );
};

export { StatisticContext, StatisticContextProvider };
