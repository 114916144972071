import { useContext } from "react";
import { StripeContext } from "../../../contexts"

const Loader = () => {
    const { tablePurchaseIsLoading } = useContext(StripeContext);
    
    if (tablePurchaseIsLoading)
        return (
            <div className="loader_wrapper">
                <div className="loader"></div>
            </div>
        )
    
    return <div></div>
}

export default Loader;