import { useTranslation } from "react-i18next";
import { FC, useContext, useEffect, useState } from "react";
import actionCardImage from "../../../assets/images/actionCard.svg";
import checkStroke from "../../../assets/icons/checkStroke.svg";
import deleteGrey from "../../../assets/icons/DeleteGrey.svg";
import editBoxIcon from "../../../assets/icons/editBoxIcon.svg";
import { CardContextNew, ComponentsContext, MatchContext } from "../../../contexts";

interface IActionCard {
    actionCard: any;
    index: number
    isCustom?: boolean
}

const ActionCard: FC<IActionCard> = ({actionCard, index, isCustom}) => {
    const { t } = useTranslation();
    const {
        customActionCardIds,
        setCustomActionCardIds,
        defaultActionCardIds,
        setDefaultActionCardIds,
        setSelectedActionCardForEdit,
        deleteCustomActionCardHTTP
    } = useContext(CardContextNew);
    const { setToggleModalValue, setPopUpBoxType, selectedLanguage  } = useContext(ComponentsContext);
    const { enteredMatchData } = useContext(MatchContext);

    const orderNumber = isCustom ? index : index + 1;

    useEffect( () => {
        setIsCardChecked(initialIsChecked());
    }, [customActionCardIds])

    useEffect( () => {
        setIsCardChecked(initialIsChecked());
    }, [defaultActionCardIds])
    
    const initialIsChecked = () => {
        if (isCustom)
            if (customActionCardIds.includes(actionCard.id)) return true;
        
        if (!isCustom)
            if (defaultActionCardIds.includes(actionCard.id)) return true;

        return false;        
    } 
    
    const [isCardChecked, setIsCardChecked] = useState<boolean>(initialIsChecked());

    const addIdToArray = () => {
        if (isCustom) {
            setCustomActionCardIds([...customActionCardIds, actionCard.id]);
            return;
        }

        setDefaultActionCardIds([...defaultActionCardIds, actionCard.id ])
    }
    
    const removeIdFromArray = () => {
        if (isCustom) {
            setCustomActionCardIds(customActionCardIds.filter( (itemId: any) => { return itemId !== actionCard.id}));
            return;
        }

        setDefaultActionCardIds(defaultActionCardIds.filter( (itemId: any) => { return itemId !== actionCard.id}));
    }

    const selectCard = () => {
        if (!isCardChecked) addIdToArray();
        if (isCardChecked) removeIdFromArray();

        setIsCardChecked(!isCardChecked);
    }

    const openPopUpBoxForEditing = () => {
        setSelectedActionCardForEdit(actionCard);
        if (enteredMatchData?.idForUpdate) {
            setPopUpBoxType("editSelectedMatchAction");
        } else {
            setPopUpBoxType("editAction");
        }
        setToggleModalValue(true);
    }

    return (
        <div key={index} className="dilemma_card_wrapper" >
            <img className="dilemma_card_image" src={actionCardImage} alt="action" />
            <div className="action_card_body">
                <div className="action_body_title"> {actionCard.title} </div>
                <div className="action_body_description"> {actionCard.description} </div>
                <div className="action_body_description"> (<b>{actionCard.numberOfUpperTokens}</b> {t("uppersText")}) </div>
            </div>
            {isCustom && <img className="card_edit_box" onClick={() => openPopUpBoxForEditing()} src={editBoxIcon} alt="check" />}
            <div className="card_check_box" onClick={() => selectCard()} > 
                {isCardChecked && <img src={checkStroke} alt="check" />}
            </div>
            <div className="card_order_number">
                {orderNumber}
            </div>
            {isCustom && !enteredMatchData?.idForUpdate && <div className="card_delete" onClick={() => deleteCustomActionCardHTTP(actionCard.id, selectedLanguage)}>
            <img className="color_filter_to_white" src={deleteGrey} alt="delete" />
            </div>}
        </div>
    )
}

export default ActionCard;