import { FC } from "react";
import {useTranslation} from "react-i18next";

import { RegularInput, TextAreaReusableRef } from "../../Atoms";
import messageIcon from "../../../assets/icons/messageIcon.svg";
import editIcon from "../../../assets/icons/editIcon.svg";
import tablesIcon from "../../../assets/icons/tablesIcon.svg";

interface IActionCardModal {
    actionCardTitle: any,
    actionCardDescription: any,
    numberOfPoints: any,
    actionCardOrderNumber: any,
    actionCardsUpperLimit: number
}

const ActionCardModal: FC<IActionCardModal> = ({actionCardTitle, actionCardDescription, numberOfPoints, actionCardOrderNumber, actionCardsUpperLimit}) => {
    const { t } = useTranslation();
    const upperLimitReachedText = t("youCannotAddACardWithGreaterOrderText") + `${actionCardsUpperLimit}`
    return (
        <div>
            <div className="input_field_holder">
                <RegularInput
                    icon={editIcon} 
                    placeholder={t("enterTitleText")}
                    inputType="text" 
                    takeInputValue={(data) => console.log(data)} 
                    refInput={actionCardTitle} />
            </div>
            <div className="input_field_holder">
                <TextAreaReusableRef
                    icon={messageIcon} 
                    placeholder={t("addDescriptionText")} 
                    refInput={actionCardDescription}
                />
            </div>
            <div className="input_field_holder">
                <RegularInput 
                    icon={tablesIcon} 
                    placeholder={t("numOfUpperTokensText")}
                    inputType="number" 
                    takeInputValue={(data) => console.log(data)} 
                    refForCounter={numberOfPoints} 
                    disabled={true}
                    counter={true} 
                    upDownArrows={true}
                    incrementType="number"
                    initialValue={1}
                />
            </div>
            <div className="input_field_holder">
                <RegularInput 
                    icon={tablesIcon} 
                    placeholder={t("newCardOrderNumberText")}
                    inputType="number" 
                    takeInputValue={(data) => console.log(data)} 
                    refForCounter={actionCardOrderNumber} 
                    disabled={true}
                    counter={true} 
                    upDownArrows={true}
                    incrementType="number"
                    initialValue={1}
                    upperLimitValue={actionCardsUpperLimit}
                    upperLimitCustomAlertText={upperLimitReachedText}
                />
            </div>
        </div>
    )
}

export default ActionCardModal;