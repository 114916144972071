import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonAnimationUp, DropDown } from "../Atoms";
import { ComponentsContext, LearningContext } from "../../contexts";
import editIcon from "../../assets/icons/editIcon.svg";
import messageIcon from "../../assets/icons/messageIcon.svg";
import linkIcon from "../../assets/icons/linkIcon.svg";
import { LearningType } from "../../util/enums/LearningType";

interface IDropDownInput {
    id: number,
    label: string,
    type: string
}
interface IEditSelectedLearningItem {
    learningTypes: IDropDownInput[]
}

const EditSelectedLearningItem: FC<IEditSelectedLearningItem> = ({learningTypes}) => {
    const { setToggleModalValue } = useContext(ComponentsContext);
    const { selectedLearningIconData, learningUpdateEndPiont } = useContext(LearningContext);

    const [title, setTitle] = useState<string>(selectedLearningIconData.title || "");
    const [link, setLink] = useState<string>(selectedLearningIconData.link || "");
    const [description, setDescription] = useState<string>(selectedLearningIconData.description || "");
    const [selectedItem, setSelectedItem] = useState<IDropDownInput>({id: learningTypes[0].id, label: learningTypes[0].label, type: learningTypes[0].type});

    const { t } = useTranslation();

    const editedObject = {
        _id: selectedLearningIconData.id,
        description: description,
        link: link, 
        title: title,
        type: selectedItem.type
    }
    const EditSelectedLearningItem = () => {
        learningUpdateEndPiont(editedObject)
        setToggleModalValue(false);
    }

    return (
        <div className="create_new_learning_item_wrapper">
            
            <div className="create_new_match_modal_title"> {t("editLearningItemText")} </div>
             
            <div>
                <div>
                    <DropDown placeHolderText={t("selectLearnigItemType")} dropDownItems={learningTypes} setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                </div>
                <div className="edit_input_field" style={{marginTop: "15px"}}> 
                    <img style={{marginRight: "20px"}} src={editIcon} alt="" />
                    <input style={{border: "none", background: "transparent", outline: "none"}}
                        autoFocus
                        maxLength={50}
                        type='text'
                        value={title}
                        placeholder={t("enterTitle")}
                        onBlur={() => console.log() }
                        onChange={(event: any) => setTitle(event?.target?.value)}
                    />
                </div>
                <div className="edit_input_field_wrapper" style={{marginTop: "15px"}}>
                    <div className="edit_input_field"> 
                        <img style={{marginRight: "20px"}} src={linkIcon} alt="" />
                        <input style={{border: "none", background: "transparent", outline: "none"}}
                            autoFocus
                            type='text'
                            value={link}
                            placeholder={t("addLink")}
                            onChange={(event: any) => setLink(event?.target?.value)}
                        />
                    </div>
                </div>
                <div className="textarea_field_holder margin_10">
                    <div className="textarea_field_wrapper">
                        <div className="textarea_field_icon"> <img src={messageIcon} alt="home" /></div>
                        <div className="textarea_field_text left_center"> 
                            <textarea
                                value={description}
                                onChange={(event) => setDescription(event.target.value)}
                                placeholder={t("addDescriptionText")}
                                cols={30}
                                rows={1} 
                                maxLength={500} 
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="create_new_match_button_holder">
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("saveText")} functionality={() => EditSelectedLearningItem()} />
                </div>
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("cancelText")} functionality={() => setToggleModalValue(false)}  />
                </div>
            </div>

        </div>
    )
}

export default EditSelectedLearningItem;