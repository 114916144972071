import React, { FC, createContext, useContext, useState } from "react";

import { CardContext } from "./CardContextProvider";
import { AxiosContext } from "./AxiosContextProvider";

import QuestionCard from "../util/models/QuestionCard";
import ActionCard from "../util/models/ActionCard";
import Match from "../util/models/Match";
import ActiveMatch from "../util/models/ActiveMatch";
import MatchPagination from "../util/models/MatchPagination";
import MatchDetails from "../util/models/MatchDetails";
import MatchCreate from "../util/models/MatchCreate";
import FinishedMatchDetails from "../util/models/FinishedMatchDetails";
import { AuthDataContainerContext, CardContextNew, UserDataContext } from ".";
import axios from "axios";
import { IEnteredMatchData } from "../interfaces";
import PreActiveMatchPagination from "../util/models/PreActiveMatchPagination";

interface IMatchContext {
	matchURL: string | undefined;
	activeMatches: ActiveMatch[];
	finishedMatches: MatchPagination | undefined;
	matchDetails: MatchDetails | undefined;
	finishedMatchDetails: FinishedMatchDetails | undefined;
	toggleModal: boolean;
	enteredMatchData: IEnteredMatchData, 
	setMatchURL(matchURL: string | undefined): void;
	createMatch: (match: Match) => void;
	updateMatchTime: (id: any,  matchDuration: any) => void;
	getActiveGames: () => void;
	getFinishedGames: (page: number) => void;
	getMatchById: (id: string) => void;
	getFinishedMatchById: (id: string) => void;
	countFinished(): void;
	setToggleModal(toggleModal: boolean): void;
	setEnteredMatchData(enteredMatchData: IEnteredMatchData): void;
	setIsResilienceCardsActive(isResilienceCardsActive: boolean): void;
	createNewMatch(selectedLanguage: string): void; 
	editMatch(): void;
	getMatchesPreactiveMatches: (page: number) => void;
	isResilienceCardsActive: boolean;
	listOfMatches: any,
	redirectToMatchPage: boolean;
	setRedirectToMatchPage(redirectToMatchPage: boolean): void;
	numberOfTablesUserPosseses: number; 
	getTableCountEndPoint(): void;
	tableCount: number, 
	setTableCount(tableCount: number): void;
	getAllFinishedMatches(pageNumber: number): void;
}

const MatchContext = createContext({} as IMatchContext);

interface MatchContextProps {
  children: React.ReactNode;
}

const MatchContextProvider: FC<MatchContextProps> = (props) => {
	const { token, jwtToken } = useContext(AuthDataContainerContext);
	const { customActionCardIds, defaultActionCardIds, customQuestionCardIds, defaultQuestionCardIds } = useContext(CardContextNew);
	const { userData } = useContext(UserDataContext);
	const [matchURL, setMatchURL] = useState<string | undefined>("");
	const [matchDetails, setMatchDetails] = useState<MatchDetails>();
	const [activeMatches, setActiveMatches] = useState<ActiveMatch[]>([]);
	const [finishedMatches, setFinishedMatches] = useState<MatchPagination>();
	const [finishedMatchDetails, setFinishedMatchDetails] = useState<FinishedMatchDetails>();
	const [toggleModal, setToggleModal] = useState<boolean>(false);
	const [listOfMatches, setListOfMatches] = useState<PreActiveMatchPagination>();
	const [redirectToMatchPage, setRedirectToMatchPage] = useState<boolean>(false);
	const [numberOfTablesUserPosseses, setNumberOfTablesUserPosseses] = useState<number>(0);
	const [tableCount, setTableCount] = useState<number>(0);
	const [enteredMatchData, setEnteredMatchData] = useState<IEnteredMatchData>({
		idForUpdate: "",
		companyEmail: userData?.email || "",
		matchName: "",
		companyName: "",
		gameCount: 0,
		matchDuration: 30
	});
	const [isResilienceCardsActive, setIsResilienceCardsActive] = useState<boolean>(false);
	const userSessionToken = token;
    const currentUserEmail: string = userData?.email || "";

	const {
		transformCustomActionCardFrom2DTo1DArray,
		transformCustomQuestionCardFrom2DTo1DArray,
		concatExistAndCustomActionCards,
		concatExistAndCustomQuestionCards
	} = useContext(CardContext);

	const { adminBackend } = useContext(AxiosContext);

	const createMatch = (match: Match) => {
		let actionCards: ActionCard[] = concatExistAndCustomActionCards();
		let questionCards: QuestionCard[] = concatExistAndCustomQuestionCards();
		let customActionCards: ActionCard[] = transformCustomActionCardFrom2DTo1DArray();
		let customQuestionCards: QuestionCard[] = transformCustomQuestionCardFrom2DTo1DArray();

		let matchCreate: MatchCreate = {
			companyEmail: match.companyEmail,
			companyName: match.companyName,
			gameCount: match.gameCount,
			matchName: match.matchName,
			matchDuration: match.matchDuration,
			language: "English",
			actionCards,
			questionCards,
			customActionCards,
			customQuestionCards,
		};

		const windowLocationPathname = window.location.pathname;
		const urlToken = windowLocationPathname.split("/");
		const tokenFromUrl = urlToken[3];
		
		if (userData?.isAdmin) createMatchAdmin(matchCreate, tokenFromUrl);
		if (!userData?.isAdmin) createMatchFacilitator(matchCreate, tokenFromUrl);
	};

	const createMatchAdmin = (matchCreate: any, tokenFromUrl: string | undefined) => {

		axios({
			method: "post",
			url: process.env.REACT_APP_BACKEND_URL + "preactive-matches/create-admin-match/",
			headers: {
				Authorization: token,
			},
			data: matchCreate,
			})
			.then((res: any) => {
				setMatchURL(res.data);
			})
			.catch((err: any) => console.error(err));
	};

	const createNewMatch = (selectedLanguage: string) => {
		const newMatchData = {
			companyEmail: userData?.email || "",
			companyName: enteredMatchData.companyName,
			matchName: enteredMatchData.matchName,
			matchDuration: enteredMatchData.matchDuration,
			gameCount: enteredMatchData.gameCount,
			language: selectedLanguage,
			isResilienceCardsActive: isResilienceCardsActive,

			customActionCardIds: customActionCardIds,
			customQuestionCardIds: customQuestionCardIds,  
			actionCardIds: defaultActionCardIds,
			questionCardIds: defaultQuestionCardIds,
		}
		
		axios({
			method: "post",
			url: process.env.REACT_APP_BACKEND_URL + "preactive-matches/create-match",
			headers: {
				Authorization: token,
			},
			data: newMatchData,
			})
			.then((res: any) => {
				setMatchURL(res.data);
				setRedirectToMatchPage(true);
				getTableCountEndPoint();
				setIsResilienceCardsActive(false);
			})
			.catch((err: any) => console.error(err));
	}

	const editMatch = () => {
		const newMatchData = {
			companyEmail: userData?.email || "",
			companyName: enteredMatchData.companyName,
			matchName: enteredMatchData.matchName,
			matchDuration: enteredMatchData.matchDuration,
			gameCount: enteredMatchData.gameCount,
			isResilienceCardsActive: isResilienceCardsActive,

			customActionCardIds: customActionCardIds,
			customQuestionCardIds: customQuestionCardIds,  
			actionCardIds: defaultActionCardIds,
			questionCardIds: defaultQuestionCardIds,
		}
		
		axios({
			method: "patch",
			url: process.env.REACT_APP_BACKEND_URL + `preactive-matches/edit-match/${enteredMatchData.idForUpdate}`,
			headers: {
				Authorization: token,
			},
			data: newMatchData,
			})
			.then((res: any) => {
				setMatchURL(res.data);
				setRedirectToMatchPage(true);
				getTableCountEndPoint();
				setIsResilienceCardsActive(false);

			})
			.catch((err: any) => console.error(err));
	}

	const getTableCountEndPoint = () => {
        axios({
            method: "get",
            url: process.env.REACT_APP_BACKEND_URL +"tables/get-table-count",
            headers: {
                Authorization: userSessionToken
            },
            data: {currentUserEmail},
        })
        .then((res) => {
            setNumberOfTablesUserPosseses(res.data.tableCount);
        })
        .catch((err) => console.error("getTableCountEndPoint: ", err));
    }

	const createMatchFacilitator = (matchCreate: any, tokenFromUrl: string | undefined) => {
		axios({
			method: "post",
			url: process.env.REACT_APP_BACKEND_URL + "preactive-matches/create-facilitator-match/"+jwtToken,
			headers: {
				Authorization: token,
			},
			data: matchCreate,
			})
			.then((res: any) => {
				setMatchURL(res.data);
			})
			.catch((err: any) => console.error(err));
	};

	const updateMatchTime = (id: any, matchDuration: any) => {
		const updatedTimeForAMatch: any = {
			id,
			matchDuration,
		};

		axios({
			method: "post",
			url: process.env.REACT_APP_GAME_BACKEND + "api/matches/change-match-duration",
			headers: {
				Authorization: "Bearer k68WogMPB_so7jrkAMy8pbgyMg2zhArVFjcYyzxUjVLjP2a49IYC-qBsRHz8pv0v9Dz84KqX_IS-5mR1O6Jz-U3lPFH-IPloDp-s7iiapzNpa6Cf2YcornKtzImsUXgTaFw8_hYvud_oyXQmf2BN9Y4un83C_ueceyCerh7KHPZPx2USsh_fLtFMdkOspQdNjxyOAluOJoMXDu1Mpyu4jwja193kmBfQ-Kxv14rgyAw3ocTi4F_zMnyDKACV2cZ3n5VQzriPDInQsVNNnzntgBcZsPv37YY_wkhksxIpgxBLn-wnyJuXuYNg5SFCqHNF15KKUqLWxMXR-GRHPybcnw",
			},
			data: updatedTimeForAMatch,
			})
			.then((res) => {
				console.log(res);
			})
			.catch((err) => console.error(err));
	};

	const getActiveGames = () => {
		adminBackend.get("matches/active").then((res) => {
			if (res) setActiveMatches(res.data);
		});
	};

	const getMatchById = (id: string) => {
		adminBackend
			.get(process.env.REACT_APP_BACKEND_URL + `matches/${id}`)
			.then((res) => {
				if (res) setMatchDetails(res.data);
			});
	};

	const getMatchesPreactiveMatches = (page: number) => {
		axios({
			method: "get",
			url: process.env.REACT_APP_BACKEND_URL + "preactive-matches/get-matches/" + page,
			headers: {
				Authorization: token,
			},
			})
			.then((res: any) => {
				setListOfMatches(res.data);
			})
			.catch((err: any) => console.error(err));
	}

	const getFinishedMatchById = (id: string) => {
		adminBackend
			.get(process.env.REACT_APP_BACKEND_URL + `matches/finished-one/${id}`)
			.then((res) => {
				if (res) setFinishedMatchDetails(res.data);
			});
	};

	const countFinished = () => {
        axios({
          method: "get",
          url: process.env.REACT_APP_BACKEND_URL + "/matches/count-finished/" + userData?.email,
          headers: { 
              Authorization: token
          }
          })
          .then((res: any) => {
				setTableCount(res.data.count);
          })
          .catch((err: any) => {
			  console.error("count finished", err)
		  });
    }

	const getFinishedGames = (page: number) => {
		// adminBackend.get(`matches/finished/${page}`).then((res) => {
		// 	if (res) setFinishedMatches(res.data);
		// });

		axios({
			method: "get",
			url: process.env.REACT_APP_BACKEND_URL + "matches/finished/" + page,
			headers: { 
				Authorization: token
			},
			})
			.then((res: any) => {
				  setFinishedMatches(res.data);
			})
			.catch((err: any) => {
				console.error("finished games", err)
			});
	};

	const getAllFinishedMatches = (pageNumber: number) => {
        axios({
          method: "get",
          url: process.env.REACT_APP_BACKEND_URL + "matches/finished/" + pageNumber,
          headers: { 
              Authorization: token
          },
		  data: { email: userData?.email }
          })
          .then((res: any) => {
                console.log(res);
          })
          .catch((err: any) => {
			  console.error("count finished", err)
		  });
    }

	const providerValue = {
		matchURL,
		activeMatches,
		finishedMatches,
		matchDetails,
		finishedMatchDetails,
		toggleModal,
		enteredMatchData, 
		setMatchURL,
		createMatch,
		updateMatchTime,
		getActiveGames,
		getFinishedGames,
		getMatchById,
		getFinishedMatchById,
		countFinished,
		setToggleModal,
		setEnteredMatchData,
		setIsResilienceCardsActive,
		createNewMatch,
		editMatch,
		getMatchesPreactiveMatches,
		isResilienceCardsActive,
		listOfMatches,
		redirectToMatchPage,
		setRedirectToMatchPage,
		numberOfTablesUserPosseses,
		getTableCountEndPoint,
		tableCount,
		setTableCount,
		getAllFinishedMatches
	};

	return (
		<MatchContext.Provider value={providerValue}>
		{props.children}
		</MatchContext.Provider>
	);
};

export { MatchContext, MatchContextProvider };
