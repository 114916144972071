import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ButtonAnimationUp } from "../../Atoms";
import { routeNamesDic } from "../../../interfaces/Dictionaries";
import { CardContext, CardContextNew, ComponentsContext, MatchContext } from "../../../contexts";

const ListAllCardsByTypeHeader = () => {
    const { selectedCardType, setSelectedCardType } = useContext(CardContext);
    const { selectedLanguage } = useContext(ComponentsContext);
    const { customActionCardIds, defaultActionCardIds, customQuestionCardIds, defaultQuestionCardIds } = useContext(CardContextNew);
    const { createNewMatch, editMatch, enteredMatchData } = useContext(MatchContext);
    const actionCardsTotalSum: number = customActionCardIds.length + defaultActionCardIds.length;
    const questionCardsTotalSum: number = customQuestionCardIds.length + defaultQuestionCardIds.length;
    const { t } = useTranslation();
    let history = useHistory();

    const sidebarMenuItemNamesDic : any= {
        0: t("matchesText"),
        1: t("historyText"),
        2: t("learningText"),
        3: t("FacilitatorText"),
        4: t("notificationText"),
        5: t("purchaseTablesText"),
        6: t("myProfileText"),
        7: t("matchesCardDeckText")
    }

    const goBack = () => {
        const selectedRouteName =  routeNamesDic[sidebarMenuItemNamesDic[0]];
        if (history)
            history.push(selectedRouteName);
    }

    const handleComplete = () => {
        if (actionCardsTotalSum < 10){
            alert(t("youDoNothaveEnoughActionCardsText"));
            return;
        }	

        if (questionCardsTotalSum < 10) {
            alert(t("youDoNothaveEnoughQuestionCardsText"));
            return;
        }

        if(enteredMatchData?.idForUpdate === "") {
            createNewMatch(selectedLanguage);
        } else {
            editMatch();
        }
	};

    return (
        <div className="list_all_cards_by_type_header">
            <div className="list_all_cards_by_type_header_button_wrapper">
                <ButtonAnimationUp text={t("backText")} functionality={() => goBack()} />
            </div>
            <div className="action_or_question_selectors_wrapper">
                <div className={selectedCardType === "Question" ? "item_selected_wrapper" : "item_deselected_wrapper"} onClick={() => setSelectedCardType("Question")}>
                    {t("questionCardText")}
                </div>
                <div className={selectedCardType === "Action" ? "item_selected_wrapper" : "item_deselected_wrapper"} onClick={() => setSelectedCardType("Action")}> 
                    {t("actionCardText")}
                </div>
                {/* {selectedLanguage === "English" && <div className={selectedCardType === "Solution" ? "item_selected_wrapper" : "item_deselected_wrapper"} onClick={() => setSelectedCardType("Solution")}> 
                    {t("solutionCardText")}
                </div>} */}
            </div>
            <div className="list_all_cards_by_type_header_button_wrapper">
                <ButtonAnimationUp text={enteredMatchData?.idForUpdate === "" ? t("createMatchText") :  t("updateMatchText")} functionality={handleComplete} />
            </div>
      </div>
    )
}

export default ListAllCardsByTypeHeader;