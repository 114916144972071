export const sidebarMenuItemNamesDic : any= {
    0: "Matches",
    1: "History",
    2: "Learning",
    3: "Facilitators",
    4: "Notifications",
    5: "Purchase Tables",
    6: "My Profile",
    7: "Matches/Card Deck"
}

export const routeNamesDic : any= {
    "Matches": "/matches",
    "Matches/Card Deck": "/matches/carddeck",
    "History": "/history",
    "Learning": "/learning",
    "Facilitators": "/facilitators",
    "Notifications": "/notifications",
    "Purchase Tables": "/purchasetables",
    "My Profile": "/myprofile"
}
