import { FC, useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router";
import {useTranslation} from "react-i18next";

import ListAllCardsByType from "../../components/Cards/ListAllCardsByType/ListAllCardsByType"
import { CardContextNew, ComponentsContext, MatchContext, UserDataContext } from "../../contexts";
import mapLanguage from "../../util/helpers/mapLanguage";

const MatchesCardDeck: FC<{}> = () => {
    const { userData } = useContext(UserDataContext);
    const { redirectToMatchPage, enteredMatchData } = useContext(MatchContext);
    const { selectedLanguage, setNotificationText, setIsNotificationActivated } = useContext(ComponentsContext);
   
    const {t, i18n} = useTranslation();
    const history = useHistory();
    const { 
        getActionCustomCardsNew,
        getActionDefaultCardsNew,
        getQuestionCustomCardsNew,
        getQuestionDefaultCardsNew
    } = useContext(CardContextNew);

    useEffect(() => {
        getActionDefaultCardsNew(mapLanguage(i18n.language));
        getQuestionDefaultCardsNew(mapLanguage(i18n.language));
        // if I add this dependancy it will cause endless ReRendering;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!enteredMatchData?.idForUpdate) {
            getActionCustomCardsNew(userData?.email || "", selectedLanguage);
            getQuestionCustomCardsNew(userData?.email || "", selectedLanguage);  
        }
    }, [])
    
    const redirectRouteToMatch = useCallback(() => {
		if (history)
			history.push(`/matches`);
            enteredMatchData?.idForUpdate === "" ?  setNotificationText(t("youCreatedANewMatchText")) :   setNotificationText(t("youUpdatedMatchText"));
            setIsNotificationActivated(true);
	}, [history]);

    useEffect(() => {
        if (redirectToMatchPage )
            redirectRouteToMatch()
    }, [redirectToMatchPage]);

    return(
        <div className="page_content_wrapper custom_scrollbar custom_made_scrollbar">
            <ListAllCardsByType />
        </div>
    )
}

export default MatchesCardDeck;