import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { History } from "../../components";
import { Pagination } from "../../components/Atoms";
import { MatchContext, UserDataContext } from "../../contexts";

const HistoryPage: FC<{}> = () => {
    const { userData } = useContext(UserDataContext);
    const { t } = useTranslation();
    const { finishedMatches, getFinishedGames, tableCount, countFinished } = useContext(MatchContext);
    const receivedHistoryMatches: any[] = finishedMatches?.docs || [];
    const numberOfPages : number = finishedMatches?.totalPages || 1;
    const titleText: string = tableCount === 1 ? t("tableUsedSoFarText") : t("tablesUsedSoFarText");
	const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        getFinishedGames(currentPage);
        countFinished();
        // if I add it, it will ReRender more times
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

	return (
		<div className="page_content_wrapper custom_scrollbar custom_made_scrollbar">
            <div className="history_page_header"> 
                <div className="number_of_tables"> {tableCount} </div> 
                <div className="gray_bold_text"> {titleText} </div>
            </div>
			<History finishedMatches={receivedHistoryMatches} /> 
			<Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
		</div>
	);
};

export default HistoryPage;
