import { FC, useContext } from "react";
import "./SpectatorButton.scss";
import { SocketContext, UserDataContext } from "../../../contexts";
import eye from "../../../assets/icons/eye.svg";

interface ISpectatorButton {
    selectedMatchURL: string;
}

const SpectatorButton: FC<ISpectatorButton> = ({selectedMatchURL}) => {
    const { joinRoomSpectatorSocketEmit } = useContext(SocketContext);
    const { userData } = useContext(UserDataContext);

    const userEmail: string = userData?.email || "";
    const urlPathChunks = selectedMatchURL.split("/");
    const urlTokenExtracted = urlPathChunks[urlPathChunks.length-1];

    const joinAsSpectator = () => {
		localStorage.setItem(urlTokenExtracted, urlTokenExtracted + userEmail);
        console.log(userEmail)
		let payload = {
            matchId: urlTokenExtracted,
            gameId: 1,
            spectator: {
                email: urlTokenExtracted + userEmail,
            }
   		}
		joinRoomSpectatorSocketEmit(payload);
        console.log("joinRoomSpectator payload: ", payload);
        // localStorage.setItem("userEmailInCaseHeLogsOut", userEmail);
        window.open(process.env.REACT_APP_GAME + "spectator/" + urlTokenExtracted);
	}

    // return <button onClick={() => joinAsSpectator()} style={{position: "absolute", top: 0, left: 0, zIndex: 10}}> eye </button> 
    return <img className="eye_icon" onClick={() => joinAsSpectator()} src={eye} alt="check" />
}

export default SpectatorButton;