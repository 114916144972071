import { FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AreYouSureModal, CreateNewLearningItem, EditSelectedLearningItem, Learning, PopUpModal } from "../../components";
import { LearningContext } from "../../contexts";
import { LearningType } from "../../util/enums/LearningType";

interface IDropDownInput {
  id: number,
  label: string,
  type: string
}


const LearningPage: FC<{}> = () => {
    const { learningGetAllEndPoint, selectedLearningOption } = useContext(LearningContext);
    const { t } = useTranslation();
    const learningTypes: IDropDownInput[] = [
      {id: 0, label: t("allText"), type: LearningType.ALL}, 
      {id: 1, label: t("videosText"), type: LearningType.VIDEOS},
      {id: 2, label: t("presentationsText"), type: LearningType.PRESENTATIONS},
      {id: 3, label: t("eventsText"), type: LearningType.EVENTS},
      {id: 4, label: t("sharedFilesText"), type: LearningType.SHARED_FILE},
  ];

    useEffect(() => {
      learningGetAllEndPoint();
      // I want this to happen only once when the component is initialised.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const learningDic = {
      "create": <CreateNewLearningItem learningTypes={learningTypes} />,
      "edit": <EditSelectedLearningItem learningTypes={learningTypes} />,
      "delete": <AreYouSureModal />
    }

    return (
      <div className="page_content_wrapper custom_scrollbar custom_made_scrollbar">
        <PopUpModal>
          {learningDic[selectedLearningOption]}
        </PopUpModal>
        
        <Learning />
      </div>
    )
}

export default LearningPage;