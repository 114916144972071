import { FC } from "react"

interface ITextAreaReusableRef {
    icon: any,
    placeholder: any,
    refInput: any
}

const TextAreaReusableRef: FC<ITextAreaReusableRef> = (props) => {
    const {icon, placeholder, refInput} = props;

    return (
        <div className="text_area_reusable_wrapper">
            {icon && <div className="full_center position_icon"> <img src={icon} alt="home" /> </div>}
            <textarea
                placeholder={placeholder}
                cols={30}
                rows={5} 
                ref={refInput}
                maxLength={150}
            />
        </div>
    )
}

export default TextAreaReusableRef;