import { useTranslation } from "react-i18next";
import arrowUp from "../../../assets/icons/arrowUp.svg";
import arrowDown from "../../../assets/icons/arrowDown.svg";
import { FC } from "react";

type TIncrementType = "number" | "time";

interface IUpDownArrows {
    currentEnteredNumber: number;
    updateValue(updatedNumber: number): void;
    incrementType: TIncrementType;
    upperLimitValue?: number;
    upperLimitCustomAlertText?: string;
}

const UpDownArrows: FC<IUpDownArrows> = ({currentEnteredNumber, updateValue, incrementType, upperLimitValue, upperLimitCustomAlertText}) => {
    const { t } = useTranslation();

    const incrementTypeDic = {
        "number": 1,
        "time": 600000
    }

    const minValuesDic = {
        "number": 1,
        "time": 600000
    }

    const maxValuesDic = {
        "number": 50,
        "time": 10800000
    }
    
    const maxValue : number = maxValuesDic[incrementType];
    const minValue : number = minValuesDic[incrementType];
    const incrementValue = incrementTypeDic[incrementType];

    if (currentEnteredNumber < minValue) updateValue(0);

    const arrowUpFunctionality = () => {
        if (upperLimitValue && upperLimitCustomAlertText) {
            if (currentEnteredNumber >= upperLimitValue) {
                alert(upperLimitCustomAlertText);
                return;
            }
        }
        else if (upperLimitValue) {
            if (currentEnteredNumber >= upperLimitValue) {
                alert(t("currentlyyouCanCreateOnlyText") + " " + upperLimitValue + " " + t("tablesOrYouCanGoToThePurchaseText") );
                return;
            }
        }

        if (currentEnteredNumber < maxValue)
            updateValue(currentEnteredNumber+incrementValue);
    }
    
    const arrowDownFunctionality = () => {
        if (currentEnteredNumber > minValue)
            updateValue(currentEnteredNumber-incrementValue);
    }
    
    return (
        <div className="input_field_updown">
            <img className="arrow" src={arrowUp} onClick={() => arrowUpFunctionality()} alt="home" />
            <img className="arrow" src={arrowDown} onClick={() => arrowDownFunctionality()} alt="home" />
        </div>
    )
}

export default UpDownArrows;