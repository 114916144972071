import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ComponentsContext, LearningContext } from "../../contexts";
import { ButtonAnimationUp, RedButtonAnimationUp } from "../Atoms";

const AreYouSureModal = () => {
    const { setToggleModalValue } = useContext(ComponentsContext);
    const { learningDeleteEndPiont, selectedlearningItemId } = useContext(LearningContext);
    const { t } = useTranslation();

    const deleteSelectedItem = () => {
        learningDeleteEndPiont(selectedlearningItemId);
        setToggleModalValue(false);
    }

    return (
        <div className="create_new_learning_item_wrapper">
            <div className="create_new_match_modal_title" style={{marginBottom: "50px"}}> {t("areYouSureYouWishToDeleteText")} </div>
            <div className="create_new_match_button_holder">
                <div className="create_new_match_button_wrapper">
                    <RedButtonAnimationUp text={t("deleteText")} functionality={() => deleteSelectedItem()} />
                </div>
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("cancelText")} functionality={() => setToggleModalValue(false)}  />
                </div>
            </div>
        </div>
    )
}

export default AreYouSureModal;