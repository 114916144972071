import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import messageIcon from "../../../assets/icons/messageIcon.svg";
import editIcon from "../../../assets/icons/editIcon.svg";
import tablesIcon from "../../../assets/icons/tablesIcon.svg";
import {CardContextNew, ComponentsContext } from "../../../contexts";
import { RegularInput } from "../../Atoms";


interface IUpdateCustomActionCard {
    updatedActionValue: any;
    updateActionCardOrderNumber: any;
    actionCardsUpperLimit: number;
}

const UpdateCustomActionCard: FC<IUpdateCustomActionCard> = ({updatedActionValue, updateActionCardOrderNumber, actionCardsUpperLimit}) => {
    const { selectedActionCardForEdit } = useContext(CardContextNew);
    const { popUpBoxType } = useContext(ComponentsContext);
    const { t } = useTranslation();
    const [title, setTitle] = useState<string>(selectedActionCardForEdit?.title || "");
    const [value, setValue] = useState<string>(selectedActionCardForEdit?.description || "");
    const [points, setPoints] = useState<string>(selectedActionCardForEdit?.numberOfUpperTokens || "");
    const currentOrder: number = selectedActionCardForEdit?.order || 1;


    const upperLimitReachedText = t("youCannotAddACardWithGreaterOrderText") + `${actionCardsUpperLimit}`
  
    const onChange = (e: any) => {
        setValue(e.target.value);
    }
 
    if ((popUpBoxType === "editAction" || popUpBoxType === "editSelectedMatchAction") && selectedActionCardForEdit)
        return (
            <div>
                <div className="edit_input_field"> 
                    <img style={{marginRight: "20px"}} src={editIcon} alt="" />
                    <input style={{border: "none", background: "transparent", outline: "none"}}
                        autoFocus
                        maxLength={50}
                        type='text'
                        value={title}
                        placeholder={t("titleText")}
                        onBlur={() => updatedActionValue.current.title = title }
                        onChange={(event: any) => setTitle(event?.target?.value)}
                    />
                </div>
                <div className="textarea_field_holder margin_10">
                    <div className="textarea_field_wrapper">
                        <div className="textarea_field_icon"> <img src={messageIcon} alt="home" /></div>
                        <div className="textarea_field_text left_center"> 
                            <textarea
                                value={value}
                                onChange={(event) => onChange(event)}
                                placeholder={t("addDescriptionText")}
                                cols={30}
                                rows={1} 
                                onBlur={() => updatedActionValue.current.description = value } 
                                maxLength={150} 
                            />
                        </div>
                    </div>
                </div>
                <div className="edit_input_field_wrapper">
                    <div className="edit_input_field"> 
                        <img style={{marginRight: "20px"}} src={tablesIcon} alt="" />
                        <input style={{border: "none", background: "transparent", outline: "none"}}
                            autoFocus
                            maxLength={50}
                            type='number'
                            value={points}
                            placeholder={t("numberOfTables")}
                            onBlur={() => updatedActionValue.current.numberOfUpperTokens = points } 
                            onChange={(event: any) => setPoints(event?.target?.value)}
                            min={1}
                            max={10}
                        />
                    </div>
                </div>
                <div className="input_field_holder">
                    <RegularInput
                        icon={tablesIcon} 
                        placeholder={t("newCardOrderNumberText")}
                        inputType="number" 
                        takeInputValue={(data) => console.log(data)} 
                        refForCounter={updateActionCardOrderNumber} 
                        disabled={true}
                        counter={true} 
                        upDownArrows={true}
                        incrementType="number"
                        initialValue={currentOrder}
                        upperLimitValue={actionCardsUpperLimit}
                        upperLimitCustomAlertText={upperLimitReachedText}
                    />
                </div>
            </div>
        )

    return <div></div>
}

export default UpdateCustomActionCard;