import React, { FunctionComponent, useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import {
	CardCvcElement,
	CardExpiryElement,
	CardNumberElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';

import { ComponentsContext, StripeContext } from '../../../contexts';
import { ButtonAnimationUp } from '../../Atoms';
import {CrediCardBrandType} from '../../../enums'

import walletIcon from "../../../assets/icons/walletIcon.svg";
import userIcon from "../../../assets/icons/userIcon.svg";
import calendarIcon from "../../../assets/icons/calendarIcon.svg";
import cvvIcon from "../../../assets/icons/cvvIcon.svg";
import masterCardIcon from "../../../assets/icons/mastercard.png";
import maestroIcon from "../../../assets/icons/maestro.png";
import visaIcon from "../../../assets/icons/visa.png";
import jcbIcon from "../../../assets/icons/jcb.png";
import amexIcon from "../../../assets/icons/american-express.png";
import discoverIcon from "../../../assets/icons/discover.png";
import dinersClubIcon from "../../../assets/icons/diners-club.png";

import PurchaseConfirmationModal from './PurchaseConfirmationModal';
import { PopUpModal } from '../..';

type CreditCard = {
	name: string;
	brand: string;
	exp_month: number;
	exp_year: number;
	token: string;
	last4: string;
	id?: string;
	default?: boolean;
	cardHolder?: string;
};

interface CreditCardFormProps {
	setCreditCard?: React.Dispatch<React.SetStateAction<CreditCard>>;
}

const CreditCardForm: FunctionComponent<CreditCardFormProps> = ({setCreditCard}) => {
	const { t } = useTranslation();
	const { addNewCard, setIsStripeCreditCardModalOpen, setTablePurchaseIsLoading } = useContext(StripeContext);
	const { setToggleModalValue } = useContext(ComponentsContext);
	const getCustomerDataFromToken: any = {customerData: {myAddress: {zip: "", street: "", country: "", state: "", city: ""}}}

	const [creditCardData, setCreditCardData] = useState({ cardHolder: ''});
	const [creditCardBrand, setCreditCardBrand] = useState<CrediCardBrandType>();

	const stripe = useStripe();
	const elements = useElements();

	const creditCardIcon = () => {
		switch(creditCardBrand) {
			case CrediCardBrandType.MASTERCARD:
				return masterCardIcon;
			case CrediCardBrandType.MAESTRO:
				return maestroIcon;
			case CrediCardBrandType.VISA:
				return visaIcon;
			case CrediCardBrandType.JCB:
				return jcbIcon;
			case CrediCardBrandType.AMEX:
				return amexIcon;
			case CrediCardBrandType.DISCOVER:
				return discoverIcon;
			case CrediCardBrandType.DINERS:
				return dinersClubIcon;
			default:
				return undefined;
		}
	};

	const handleCreditCardBrand = (brand: CrediCardBrandType) => {
		if(brand !== CrediCardBrandType.UNKNOWN)
			return setCreditCardBrand(brand);

		return setCreditCardBrand(undefined);
	}

	const inputHandler = (fieldName: string, event: React.ChangeEvent<HTMLInputElement>) => {
		setCreditCardData({ ...creditCardData, [fieldName]: event.target.value });
	};

	const handleContinueCreditCard = () => {
		setIsStripeCreditCardModalOpen(true);
		setToggleModalValue(false);

		if (stripe && elements) {
			const cardNumberElement = elements.getElement(CardNumberElement);

			if (cardNumberElement) {
				stripe
				.createToken(cardNumberElement, {
					name: creditCardData.cardHolder,
				})
				.then((value) => {
										
					if (value.error) {
						alert(value.error.message);
						setIsStripeCreditCardModalOpen(false)
						return;
					} 

					setTablePurchaseIsLoading(true);
					
					const name = value.token.card?.name ? value.token.card.name : '';
					const brand = value.token.card?.brand
						? value.token.card.brand
						: '';
					const exp_month = value.token.card?.exp_month
						? value.token.card.exp_month
						: 1;
					const exp_year = value.token.card?.exp_year
						? value.token.card.exp_year
						: 1;

					const last4 = value.token.card?.last4
						? value.token.card?.last4
						: '';

					const token = value.token.id;
					
					if (setCreditCard) {
						setCreditCard({
							name,
							brand,
							exp_month,
							exp_year,
							token,
							last4,
						});
						setIsStripeCreditCardModalOpen(false)
						return;
					} 

					addNewCard(token)
						.then(() => {
							getCustomerDataFromToken();
						})
						.then(() => {
							setIsStripeCreditCardModalOpen(false);
						})					
				});
			}
		}
	};

	const openTheDonfirmationModal = () => {
		// setPaymentPopUpType("createCoupon");
		setToggleModalValue(true);
	}

	const displayCardBrand = () => {
		if (creditCardBrand) {
			return (
				<img 
					className="brand_icon"
					src={creditCardIcon()} 
					alt="brand"
				/>
			)
		} return <div></div>
	}

	return (
		<div className="form__wrapper">

			<div className="credit_card_wrapper">
				<div className="purchase_input_card_field_wrapper">
					<img className="purchase_input_icon" src={walletIcon} alt="wallet" />
					<CardNumberElement
						className="creditcard__stripe__card_number"
						onChange={(details) => handleCreditCardBrand(details.brand as CrediCardBrandType)}
						options={{
							style: {
								base: {
									iconColor:'gray',
									fontSize: '16px',
									color: '#0D062D',
									backgroundColor: 'transparent',
									fontFamily: " 'Poppins', sans-serif",
									'::placeholder': {
										fontSize: '16px',
										color: '#787486',
										fontWeight: 100
									},
								},
							},
							placeholder: t("cardNumberText"),
						}}
					/>
				</div>
				<div className="brand_icon_wrapper">
					{displayCardBrand()}
				</div>
			</div>

			<div className="purchase_input_field_wrapper">
				<img className="purchase_input_icon" src={userIcon} alt="user" />
				<input
					className="creditcard__stripe__card_exp_cvc"
					type="text"
					placeholder={t("cardHolderText")}
					value={creditCardData.cardHolder}
					onChange={(e) => inputHandler('cardHolder', e)}
				/>
			</div>

			<div className="purchase_input_field_wrapper">
				<img className="purchase_input_icon" src={calendarIcon} alt="calendar" />
				<CardExpiryElement
					className="creditcard__stripe__card_exp_cvc"
					options={{
						style: {
						base: {
							fontSize: '16px',
							color: '#0D062D',
							backgroundColor: 'transparent',
							fontFamily: " 'Poppins', sans-serif",
							'::placeholder': {
								fontSize: '16px',
								color: '#787486',
								fontWeight: '100',
							},
						},
						},
						placeholder: t("expirityDateText"),
					}}
				/>
			</div>

			<div className="purchase_input_field_wrapper">
				<img className="purchase_input_icon" src={cvvIcon} alt="cvv" />
				<CardCvcElement
					className="creditcard__stripe__card_exp_cvc"
					options={{
						style: {
						base: {
							fontSize: '16px',
							color: '#0D062D',
							backgroundColor: 'transparent',
							fontFamily: " 'Poppins', sans-serif",
							'::placeholder': {
								fontSize: '16px',
								color: '#787486',
								fontWeight: '100',
							},
						},
						},
						placeholder: t("securityCodeText"),
					}}
				/>
			</div>
			
			<div className="purchase_pop_up_modal_wrapper">
				<PopUpModal>
					<PurchaseConfirmationModal confirmPurchase={handleContinueCreditCard} />
				</PopUpModal>
			</div>

			<div className="buttons__wrapper">
				<ButtonAnimationUp text={t("purchaseText")} functionality={openTheDonfirmationModal} />
			</div>
		</div>
	);
};

export default CreditCardForm;
