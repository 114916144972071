import React, { useEffect } from "react";
import { useContext, } from "react";
import {useTranslation} from "react-i18next";
import { ActionCard, QuestionCard } from "../..";
import { CardContext, CardContextNew } from "../../../contexts";
import SelectAllCards from "./SelectAllCards";

export const CustomCardsPanelMemo = () => {
    const { selectedCardType } = useContext(CardContext);
    const { 
        actionCustomCardsNew, 
        questionCustomCardsNew, 
        setCustomQuestionCardIds, 
        setCustomActionCardIds,
        customQuestionCardIds,
        customActionCardIds
    } = useContext(CardContextNew);
    const { t } = useTranslation();

    const renderCustomQuestionCards = () => {
        return questionCustomCardsNew.map( (cardData: any, index: number) => {
            return (
                <div key={index}>
                    <QuestionCard questionCard={cardData} index={cardData?.order} isCustom={true} />
                </div>
            )
        })
    }

    const renderCustomActionCards = () => {
        return actionCustomCardsNew.map( (cardData: any, index: number) => {
            return (
                <div key={index}>
                    <ActionCard actionCard={cardData} index={cardData?.order} isCustom={true} />
                </div>
            )
        })
    }

    const customCardPanelByCardTypeDic = {
        "Question": renderCustomQuestionCards(),
        "Action": renderCustomActionCards()
        // "Solution": renderCustomActionCards()
    }

    const selectAllCustomCardPanelByCardTypeDic = {
        "Question":  <SelectAllCards arrayOfCards={questionCustomCardsNew} arrayOfCardsIds={customQuestionCardIds} setArrayOfCardsIds={setCustomQuestionCardIds} />,
        "Action":  <SelectAllCards arrayOfCards={actionCustomCardsNew} arrayOfCardsIds={customActionCardIds} setArrayOfCardsIds={setCustomActionCardIds} />
        // "Solution":  <SelectAllCards arrayOfCards={actionCustomCardsNew} arrayOfCardsIds={customActionCardIds} setArrayOfCardsIds={setCustomActionCardIds} />
    }

    const defaultCardsNumberByCardTypeDic = {
        "Question": questionCustomCardsNew.length || 0,
        "Action": actionCustomCardsNew.length || 0
        // "Solution": actionCustomCardsNew.length || 0
    }

    if (defaultCardsNumberByCardTypeDic[selectedCardType])
        return (
            <div className="cards_panel_custom_board">
                <div className="existing_cards_text"> 
                    <div>
                        {t("customCardsText")} 
                        <span className="grey_color margin_left_10" > ({defaultCardsNumberByCardTypeDic[selectedCardType]}) </span> 
                    </div>
                   {selectAllCustomCardPanelByCardTypeDic[selectedCardType]}
                </div>
                <div className="custom_dilemma_cards_wrapper"> 
                    {customCardPanelByCardTypeDic[selectedCardType]}
                </div>
            </div>
        )

    return <div> </div>
}

const CustomCardsPanel = React.memo(CustomCardsPanelMemo);

export default CustomCardsPanel