import { useContext, useEffect, useState } from "react";
import {useTranslation} from "react-i18next";
import { useHistory } from "react-router-dom";
import { CreateCoupnModal, EditCouponModal, PopUpModal, RenderAllCoupons } from "../../components";
import { ButtonAnimationUp, Pagination } from "../../components/Atoms";
import { ComponentsContext, CouponContext, UserDataContext } from "../../contexts";
import yellowPlus from "../../assets/icons/yellowPlus.svg";

const CouponsPage = () => {
    const { getAllCoupons, coupons } = useContext(CouponContext); 
    const { userData } = useContext(UserDataContext);
    const { paymentPopUpType, setToggleModalValue, setPaymentPopUpType } = useContext(ComponentsContext);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [postsPerPage] = useState<number>(10);
    let history = useHistory();
    const { t } = useTranslation();

    useEffect(() => {
        getAllCoupons();
        // getAllValidateCoupons("626bb2ad2e5aaa08dc6f85f0");
    }, []);

    if (!coupons) return <div> coupons </div>
    

    const goBack = () => {
        if (history)
            history.push("/purchasetables");
    }

    const couponModalDic = {
        "createCoupon": <CreateCoupnModal />,
        "editCoupon": <EditCouponModal />
    }

    const openPutchaseModal = () => {
        setToggleModalValue(true);
        setPaymentPopUpType("createCoupon");
    }  

    const renderAllCoupons = () => {
        if (!coupons.length) return <div></div>;
    
        const indexOfLastPost : number = currentPage * postsPerPage;
        const indexOfFirstPost : number = indexOfLastPost - postsPerPage;
        let selectedPageCoupons = coupons?.slice(indexOfFirstPost, indexOfLastPost);
        return  <RenderAllCoupons allCoupons={selectedPageCoupons} />;
    }

    const pagination = () => {
        if (!coupons.length) return <div></div>;
        const numberOfPages: number = Math.floor(coupons?.length/postsPerPage)+1;

        return (
            <div className="coupons_paginatoin">
                <Pagination numberOfPages={numberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
            </div>
        )
    }

    return (
        <div className="coupons_page_wrapper custom_scrollbar custom_made_scrollbar">

            <PopUpModal>
                {couponModalDic[paymentPopUpType]}
            </PopUpModal>

            <div className="">
                <div className="coupon_header">
                    <div className="go_back_button_wrapper">    
                        <ButtonAnimationUp text={t("goBackText")} functionality={goBack} />
                    </div>
                </div>

                {userData?.isAdmin &&
                    <div className="create_new_coupon_wrapper" > 
                        <div className="create_new_coupon_button_positoin" > 
                            <img className="cursor_pointer create_new_match_plus_image margin_right" src={yellowPlus} alt="dots" onClick={() => openPutchaseModal() } />
                            <div className="underline cursor_pointer" onClick={() => openPutchaseModal() }> {t("createNewCouponText")} </div> 
                        </div>
                    </div>
                }

                {renderAllCoupons()}
            </div>

            {pagination()}
        </div>
    )
}

export default CouponsPage;