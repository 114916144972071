import axios from "axios";
import React, { FC, createContext, useState, useContext } from "react";
import { ILearningItem } from "../interfaces";
import { AuthDataContainerContext } from "./AuthDataContainer";

type TCreateEditDelete = "create" | "edit" | "delete";
interface ILearningContext {
    learningItems: ILearningItem[],
    selectedLearningItemId: string, 
    setLearningItems(learning: ILearningItem[]): void;
    setSelectedLearningItemId(selectedLearningItemId: string): void;

    learningGetAllEndPoint(): void;
    learningGetFilteredEndPoint(learningItemType: string): void;
    learningDeleteEndPiont(selectedItemId: string): void;
    learningUpdateEndPiont(selectedItem: ILearningItem): void;
    learningAddNewEndpoint(selectedItem: ILearningItem): void;
    selectedlearningItemId: any, 
    setSelectedlearningItemId(selectedlearningItemId: any): void;
    selectedLearningOption: TCreateEditDelete;
    setSelectedLearningOption(selectedLearningOption: TCreateEditDelete): void;
    selectedLearningIconData: any;
    setSelectedLearningIconData(selectedLearningIconData: any): void;
}

const LearningContext = createContext({} as ILearningContext);

interface LearningContextProps {
  children: React.ReactNode;
}

const LearningContextProvider: FC<LearningContextProps> = (props) => {
    const { token } = useContext(AuthDataContainerContext);
    const [learningItems, setLearningItems] = useState<ILearningItem[]>([]);
    const [selectedLearningItemId, setSelectedLearningItemId] = useState<string>("");
    const [selectedlearningItemId, setSelectedlearningItemId] = useState<any>();
    const [selectedLearningOption, setSelectedLearningOption] = useState<TCreateEditDelete>("create");
    const [selectedLearningIconData, setSelectedLearningIconData] = useState<TCreateEditDelete>("create");

    const learningAddNewEndpoint = (learningItem: ILearningItem) => {
        axios({
			method: "post",
			url: process.env.REACT_APP_BACKEND_URL + "learning/add-new",
			headers: {
				Authorization: token,
			},
			data: learningItem,
			})
			.then((res: any) => {
				learningGetAllEndPoint();
			})
			.catch((err: any) => console.error(err));
    }

    const learningGetAllEndPoint = () => {
        axios({
			method: "get",
			url: process.env.REACT_APP_BACKEND_URL + "learning/get-all",
			headers: {
				Authorization: token,
			}
			})
			.then((res: any) => {
                setLearningItems(mapLearningItems(res?.data));
			})
			.catch((err: any) => console.error(err));
    }

    const learningGetFilteredEndPoint = (learningItemType: string) => {
        axios({
			method: "post",
			url: process.env.REACT_APP_BACKEND_URL + "learning/get-filtered",
			headers: {
				Authorization: token,
			},
            data: {
                type: learningItemType
             }
			})
			.then((res: any) => {
                setLearningItems(mapLearningItems(res?.data));
			})
			.catch((err: any) => console.error(err));
    }

    const mapLearningItems = (receivedLearningItems: any) : ILearningItem[] => {
        const mappedMatches =  receivedLearningItems.map( (learningItem: any) => {
            return {
                id: learningItem._id || "",
                description: learningItem.description || "",
                link: learningItem.link || "",
                title: learningItem.title || "",
                type: learningItem?.type || "",
                createdAt: learningItem.createdAt || "",
                updatedAt: learningItem.updatedAt || "",
            }
        })

        const matchesOrderedByCreationDate =  mappedMatches.sort( (a: any, b: any) => {
			let dateA: any = new Date(a.createdAt);
			let dateB: any = new Date(b.createdAt);
			return dateB - dateA;
		})

        return matchesOrderedByCreationDate;
    }

    const learningUpdateEndPiont = (learningObject: ILearningItem) => {
        axios({
			method: "patch",
			url: process.env.REACT_APP_BACKEND_URL + "learning/update",
			headers: {
				Authorization: token,
			},
            data: learningObject
			})
			.then((res: any) => {
				learningGetAllEndPoint();
			})
			.catch((err: any) => console.error(err));
    }

    const learningDeleteEndPiont = (selectedItemId: string) => {
        axios({
			method: "delete",
			url: process.env.REACT_APP_BACKEND_URL + "learning/delete",
			headers: {
				Authorization: token,
			},
            data: {id: selectedItemId }
			})
			.then((res: any) => {
				learningGetAllEndPoint();
			})
			.catch((err: any) => console.error(err));
    }

    
    const providerValue = {
        learningItems,
        selectedLearningItemId, 
        setLearningItems,
        setSelectedLearningItemId,

        learningGetAllEndPoint,
        learningGetFilteredEndPoint,
        learningDeleteEndPiont,
        learningUpdateEndPiont,
        learningAddNewEndpoint,
        selectedlearningItemId,
        setSelectedlearningItemId,
        selectedLearningOption,
        setSelectedLearningOption,
        selectedLearningIconData,
        setSelectedLearningIconData
    };

    return (
        <LearningContext.Provider value={providerValue}>
        {props.children}
        </LearningContext.Provider>
    );
};

export { LearningContext, LearningContextProvider };
