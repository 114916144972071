import React, { FC, createContext, useState, useContext } from "react";
import { SocketContext } from "./SocketContext";

interface IAuthDataContainerContext {
	token: string;
	email: string;
	jwtToken: any, 
	creditCardToken: any, 
	setEmail(email: string): void;
	setToken(token: string): void;
	refreshPage: () => void;
	logOut: () => void;
	setJwtToken(jwtToken: any): void;
	setCreditCardToken(creditCardToken: any): void;
}

const AuthDataContainerContext = createContext({} as IAuthDataContainerContext);

interface AuthContextProps {
	children: React.ReactNode;
}

const AuthDataContainerContextProvider: FC<AuthContextProps> = (props) => {
	const [token, setToken] = useState<string>("");
	const [email, setEmail] = useState<string>("");
	const [jwtToken, setJwtToken] = useState<any>("");
	const [creditCardToken, setCreditCardToken] = useState<any>("");

	const { socket } = useContext(SocketContext);

	const refreshPage = () => {
		let token = localStorage.getItem("token");
		let email = localStorage.getItem("email");

		if (token) 
			setToken(token);
		if (email) 
			setEmail(email);
	};

	const logOut = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("email");

		socket?.disconnect();
		
		setEmail("");
		setToken("");
	};

	const providerValue = {
		token,
		email,
		jwtToken, 
		creditCardToken, 
		setEmail,
		setToken,
		refreshPage,
		logOut,
		setJwtToken,
		setCreditCardToken
	};

	return (
		<AuthDataContainerContext.Provider value={providerValue}>
			{props.children}
		</AuthDataContainerContext.Provider>
	);
};

export { AuthDataContainerContext, AuthDataContainerContextProvider };
