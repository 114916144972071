import { FC, useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonAnimationUp, DropDown, RegularInput, TextAreaReusable } from "../Atoms";
import { ComponentsContext, LearningContext } from "../../contexts";
import editIcon from "../../assets/icons/editIcon.svg";
import messageIcon from "../../assets/icons/messageIcon.svg";
import linkIcon from "../../assets/icons/linkIcon.svg";
import { LearningType } from "../../util/enums/LearningType";

interface IDropDownInput {
    id: number,
    label: string,
    type: string
}

interface ICreateNewLearningItem {
    learningTypes: IDropDownInput[];
}

const CreateNewLearningItem: FC<ICreateNewLearningItem> = ({learningTypes}) => {
    const { setToggleModalValue } = useContext(ComponentsContext);
    const { learningAddNewEndpoint } = useContext(LearningContext);
    const learningTitle = useRef<string>("");
    
    const [title, setTitle] = useState<string>("");
    const learningLink = useRef<string>("");
    const [link, setLink] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const { t } = useTranslation();
    
    const [selectedItem, setSelectedItem] = useState<IDropDownInput>({id: learningTypes[0].id, label: learningTypes[0].label, type: learningTypes[0].type});

    const editedObject = {
        description: description,
        link: link, 
        title: title,
        type: selectedItem.type
    }
    const createNewLearningItem = () => {
        console.log(editedObject);
        setToggleModalValue(false);
        learningAddNewEndpoint(editedObject)
    }

    return (
        <div className="create_new_learning_item_wrapper">
            <div className="create_new_match_modal_title"> {t("addLearningItemText")} </div>
            <div>
                <DropDown placeHolderText={t("selectLearnigItemType")} dropDownItems={learningTypes} setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            </div>
            <div className="input_field_holder">
                <RegularInput 
                    icon={editIcon} 
                    placeholder={t("enterTitle")} 
                    inputType="text" 
                    takeInputValue={(data: any) => setTitle(data)} 
                    refInput={learningTitle} />
            </div>
            <div className="input_field_holder">
                <RegularInput 
                    icon={linkIcon} 
                    placeholder={t("addLink")} 
                    inputType="text" 
                    takeInputValue={(data: any) => setLink(data)} 
                    refInput={learningLink} />
            </div>
            <div className="input_field_holder" style={{width: "100%", padding: "0 8px" }}>
                <TextAreaReusable 
                    icon={messageIcon} 
                    placeholder={t("addDescriptionText")}
                    setValue={(data: any) => setDescription(data)} 
                    value={description}
                />
            </div>

            <div className="create_new_match_button_holder">
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("addItemText")} functionality={() => createNewLearningItem()} />
                </div>
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("cancelText")} functionality={() => setToggleModalValue(false)}  />
                </div>
            </div>
            
        </div>
    )
}

export default CreateNewLearningItem;