import axios from "axios";
import {useTranslation} from "react-i18next";
import { ButtonAnimationUp, RegularInput } from "../Atoms";
import editIcon from "../../assets/icons/editIcon.svg";
import uploadIcon from "../../assets/icons/uploadIcon.svg";
import defaultProfileIcon from "../../assets/icons/defaultProfileIcon.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthDataContainerContext, ComponentsContext, UserDataContext } from "../../contexts";
import ChangePasswordModal from "./ChangePasswordModal";
import { PopUpModal } from "..";
import DropDown from "./DropDown/DropDown"

const MyProfile = () => {
    const {t} = useTranslation();
    const { token } = useContext(AuthDataContainerContext);
    const { userData, fetchCurrentUser, setUserData } = useContext(UserDataContext);
    const { setToggleModalValue, setNotificationText, setIsNotificationActivated } = useContext(ComponentsContext);
    const inputFirstName = useRef<any>();
    const inputLastName = useRef<any>();
    const inputLinkedIn = useRef<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [profilePicture, setProfilePicture] = useState<any>("");
    
    const [updatedFirstName, setUpdatedFirstName] = useState<string>(userData?.firstName || "");
    const [updatedLastName, setUpdatedLastName] = useState<string>(userData?.lastName || "");
    const [updatedLinkedIn, setUpdatedLinkedIn] = useState<string>(userData?.linkedin || t("enterYourLinkedInAddress"));
    const [updatedCountry, setUpdatedCountry] = useState<string>(userData?.country || "Argentina");

    const imageUrl =  " https://api-admin.uppinessgame.com/images/";
    // const imageUrl =  "http://admin-api.uppiness.marbleit.rs/images/";

    const openEditPasswordPopUpModal = () => {
        setToggleModalValue(true);
    }

    useEffect(() => {
        if (userData) {
          setUpdatedFirstName(userData.firstName || "");
          setUpdatedLastName(userData.lastName || "");
          setUpdatedLinkedIn(userData.linkedin || "");
          setUpdatedCountry(userData.country || "Argentina");
        }
      }, [userData]);

    const fileSelectedHandler = (e: any) => {
        
		const imageFile = e.target.files[0];
		
		if (imageFile?.type !== "image/jpeg" && imageFile?.type !== "image/png") {
			alert(t("onlyPNGandJPGimagesAllowedText"));
			return;
		}
		let fileSizeIniMiB = imageFile?.size / 1024 / 1024;

		if (fileSizeIniMiB > 2) {
			alert(t("fileSizeExceededText"));
			return;
		}

		if (!imageFile) return;

        let fd = new FormData();
        fd.append('avatar', imageFile);
        
        setIsLoading(true);

        axios({
            method: "post",
            url: process.env.REACT_APP_BACKEND_URL + `users/upload-avatar-photo/${userData?.email}`,
            headers: { 
                Authorization: token,
                'content-type': 'multipart/form-data'
            },
            data: fd
        })
        .then((res: any) => {
            const result = `${imageUrl}${imageFile.name}`
            setIsLoading(false);
            fetchCurrentUser();
            setProfilePicture(result);  
        })
        .catch((err: any) => console.error(err));

	};

    const updateUserData = () => {
        const updateData = {
            firstName: updatedFirstName,
            lastName: updatedLastName,
            linkedin: updatedLinkedIn,
            country: updatedCountry
        }

        let updatedUserData = {
            ...userData, 
            firstName: updatedFirstName,
            lastName: updatedLastName,
            linkedin: updatedLinkedIn,
            country: updatedCountry
        }

        setUserData(updatedUserData)

        axios({
            method: "patch",
            url: process.env.REACT_APP_BACKEND_URL + `users/${userData?._id}`,
            headers: { 
                Authorization: token,
            },
            data: updateData
        })
        .then((res: any) => {
            fetchCurrentUser();
        })
        .catch((err: any) => console.error(err));
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, inputName: string) => {
        switch (inputName) {
            case "firstName" : 
                    inputFirstName.current.classList.add("input-border");
                    setUpdatedFirstName(event.target.value);
                    break;
            case "lastName" :
                    inputLastName.current.classList.add("input-border");
                    setUpdatedLastName(event.target.value);
                    break;
            case "linkedin":
                    inputLinkedIn.current.classList.add("input-border");
                    setUpdatedLinkedIn(event.target.value);
                    break;
        }        
    }

    const chosenCountry = (country: string) => {  
        setUpdatedCountry(country);
    }

    const onBlurInputFirstName = () => {
        inputFirstName.current.classList.remove("input-border")
    }

    const onBlurInputLastName = () => {
        inputLastName.current.classList.remove("input-border")
    }

    const onBlurInputLinkedIn = () => {
        inputLinkedIn.current.classList.remove("input-border")
    }

    useEffect(() => {
        inputFirstName.current.addEventListener("keyup", (e: any) => {
            if(e.key === "Enter" || e.key === 13) {
                inputFirstName.current.classList.remove("input-border")
            }
        })

        inputLastName.current.addEventListener("keyup", (e: any) => {
            if(e.key === "Enter" || e.key === 13) {
                inputLastName.current.classList.remove("input-border")
            }
        })

        inputLinkedIn.current.addEventListener("keyup", (e: any) => {
            if(e.key === "Enter" || e.key === 13) {
                inputLinkedIn.current.classList.remove("input-border")
            }
        })
    }, [])

    useEffect(() => {
        const profileImage = `${imageUrl}${userData?.pictureUrl}`
        setProfilePicture(profileImage);
       
    }, [userData])

    useEffect(() => {
        fetchCurrentUser();
    }, [])
    
    const saveChangesAlert = () => {
        updateUserData();
        setNotificationText(t("profileChangesAreSaved"));
        setIsNotificationActivated(true);
    }

    return (
        <div className="my_profile_wrapper">

        <PopUpModal>
            <ChangePasswordModal />
        </PopUpModal>

           <div className="my_profile_upload_photo"> 
                <img className={profilePicture !== "" ? "profile_photo" : "profile_photo_default"} src={profilePicture !== "" ? profilePicture : defaultProfileIcon}  alt="default" />
                <img className="upload_icon" src={uploadIcon} alt="upload" />
                <input accept="image/*" className="my_profile_upload_picture_button" id="icon-button-file" type="file" onChange={fileSelectedHandler} />
                {isLoading && <div className="loader"></div>}
            </div>

            <div className="my_profile_input_container">
                <div className="my_profile_left_input_container">

                    <div className="my_profile_input_label"> {t("firstNameText")}: </div>
                    <div ref={inputFirstName}>
                        <div className="input_field_wrapper">
                            <input onBlur={onBlurInputFirstName} className="input_field_text drop-down"  
                                type="text" 
                                onChange={(event: any) => onChange(event, "firstName")}
                                placeholder={updatedFirstName}
                            /> 
                        </div>
                    </div>

                    <div className="my_profile_input_label"> {t("lastNameText")}: </div>
                    <div ref={inputLastName}>
                        <div className="input_field_wrapper">
                            <input onBlur={onBlurInputLastName} className="input_field_text drop-down"  
                                type="text" 
                                onChange={(event: any) => onChange(event, "lastName")}
                                placeholder={updatedLastName}
                            /> 
                        </div>
                    </div>

                    <div className="my_profile_input_label"> {t("passwordText")}: </div>
                    <RegularInput placeholder="............" inputType="text" takeInputValue={(data) => console.log(data)} editIcon={editIcon} edit={() => openEditPasswordPopUpModal()} disabled={true} /> 
                </div>

                <div className="my_profile_right_input_container">
                    <div className="my_profile_input_label"> {t("countryText")}: </div>
                    <DropDown onChange={chosenCountry}/>

                    <div className="my_profile_input_label"> {t("enterYourLinkedIn")}: </div>
                    <div ref={inputLinkedIn}>
                        <div className="input_field_wrapper">
                            <input onBlur={onBlurInputLinkedIn} className="input_field_text drop-down"  
                                type="text" 
                                placeholder={updatedLinkedIn}
                                onChange={(event: any) => onChange(event, "linkedin")}
                            /> 
                        </div>
                    </div>
                    <div className="save_profile_updates_wrapper" >
                        <ButtonAnimationUp text={t("saveText")} functionality={() => saveChangesAlert()} />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default MyProfile;
