import { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import threeDotsIcon from "../../assets/icons/threeDotsIcon.svg";
import editIcon from "../../assets/icons/editIcon.svg";
import deleteIcon from "../../assets/icons/deleteIcon.svg";
import { ComponentsContext, CouponContext } from "../../contexts";

interface IEditCouponDropDown {
    selectedItem: any;
}

const useOutsideAlerter = (ref: any, setIsLearningEditBoxClicked: any) => {

    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsLearningEditBoxClicked(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

const EditCouponDropDown: FC<IEditCouponDropDown> = ({selectedItem}) => {
    const { setToggleModalValue, setPaymentPopUpType } = useContext(ComponentsContext);
    const { setSelectedCoupon, deleteCoupon } = useContext(CouponContext);
    const [isLearningEditBoxClicked, setIsLearningEditBoxClicked ] = useState<boolean>(false);
    const { t } = useTranslation();
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setIsLearningEditBoxClicked);
  
    const editItem = () => {
        setToggleModalValue(true);
        setPaymentPopUpType("editCoupon");
        setIsLearningEditBoxClicked(false);
    }
  
    const deleteSelectedItem = () => {
        deleteCoupon(selectedItem._id);
        setIsLearningEditBoxClicked(false);
    }

    const toggleCouponEditDeleteBox = () => {
        setSelectedCoupon(selectedItem); 
        setIsLearningEditBoxClicked(!isLearningEditBoxClicked);
    }
    
    const learningEditBox = () => {
        return (
            <div ref={wrapperRef} className="learning_item_edit_delete_box">
                <div className="edit_delete_box border_bottom" onClick={() => editItem()}>
                    <img className="edit_delete_box_icon" src={editIcon} alt="edit"/>
                    {t("editText")} 
                </div>
                <div className="separating_line"></div>
                <div className="edit_delete_box delete_color" onClick={() => deleteSelectedItem()}> 
                    <img  className="edit_delete_box_icon" src={deleteIcon} alt="delete"/>
                    <div className="edit_delete_box_text">{t("deleteText")}</div> 
                </div>
            </div>
        )
    }

    return (
        <div className="learning_item_edit_delete" onClick={() => toggleCouponEditDeleteBox()} >
            <img className="cursor_pointer" src={threeDotsIcon} alt="plus" />
            {isLearningEditBoxClicked && learningEditBox()}
        </div>
    )
}

export default EditCouponDropDown;