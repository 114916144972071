import { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import threeDotsIcon from "../../assets/icons/threeDotsIcon.svg";
import editIcon from "../../assets/icons/editIcon.svg";
import deleteIcon from "../../assets/icons/deleteIcon.svg";
import { ComponentsContext, LearningContext } from "../../contexts";

interface IEditLearningComponent {
    selectedItem: any;
}

const useOutsideAlerter = (ref: any, setIsLearningEditBoxClicked: any) => {

    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsLearningEditBoxClicked(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
}

const EditLearningComponent: FC<IEditLearningComponent> = ({selectedItem}) => {
    const { setToggleModalValue } = useContext(ComponentsContext);
    const { setSelectedLearningOption, setSelectedlearningItemId, setSelectedLearningIconData } = useContext(LearningContext);
    const [isLearningEditBoxClicked, setIsLearningEditBoxClicked ] = useState<boolean>(false);
    const { t } = useTranslation();
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, setIsLearningEditBoxClicked);

    const deleteSelectedItem = () => {
        setSelectedLearningOption("delete");
        setToggleModalValue(true)
        setIsLearningEditBoxClicked(false);
        setSelectedlearningItemId(selectedItem.id);
    }

    const editItem = () => {
        setSelectedLearningIconData(selectedItem)
        setSelectedLearningOption("edit");
        setToggleModalValue(true);
    }
    
    const learningEditBox = () => {
        return (
            <div ref={wrapperRef} className="learning_item_edit_delete_box">
                <div className="edit_delete_box border_bottom" onClick={() => editItem()}>
                    <img className="edit_delete_box_icon" src={editIcon} alt="edit"/>
                    {t("editText")} 
                </div>
                <div className="separating_line"></div>
                <div className="edit_delete_box delete_color" onClick={() => deleteSelectedItem()}> 
                    <img  className="edit_delete_box_icon" src={deleteIcon} alt="delete"/>
                    <div className="edit_delete_box_text">{t("deleteText")}</div> 
                </div>
            </div>
        )
    }

    return (
        <div className="learning_item_edit_delete">
            <img className="cursor_pointer" src={threeDotsIcon} alt="plus" onClick={() => setIsLearningEditBoxClicked(!isLearningEditBoxClicked)} />
            {isLearningEditBoxClicked && learningEditBox()}
        </div>
    )
}

export default EditLearningComponent;