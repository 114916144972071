import { FC, useEffect } from "react";
import { IUser } from "../../../interfaces/index";
import leftArrow from "../../../assets/icons/arrowRight.svg";
import rightArrow from "../../../assets/icons/arrowLeft.svg";

interface IPagination {
  currentPosts: IUser[] | undefined;
  postsPerPage: number;
  totalPosts: number | undefined;
  paginate: Function;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const Pagination: FC<IPagination> = ({
  currentPosts,
  setCurrentPage,
  currentPage,
  postsPerPage,
  totalPosts,
  paginate,
}) => {
  const pageNumbers = [];

  if (totalPosts) {
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
      pageNumbers.push(i);
    }
  }

  const nextPage = (pageNumber: number) => {
    if (pageNumbers.length >= pageNumber) {
      setCurrentPage(pageNumber);
    }
  };

  const previousPage = (pageNumber: number) => {
    if (pageNumber <= pageNumbers.length && pageNumber >= 1) {
      setCurrentPage(pageNumber);
    }
  };

  useEffect(() => {
    if (currentPosts == undefined || currentPosts?.length == 0) {
      previousPage(currentPage - 1);
    }
  }, [currentPosts]);

  return (
    <nav className="pagination_container">
      <ul className="admin_pagination">
        <li>
          <button
            style={{
              paddingRight: "10px",
              border: "none",
              background: "transparent",
            }}
            onClick={() => previousPage(currentPage - 1)}
          >
            <img src={rightArrow} alt="" />
          </button>
        </li>

        {pageNumbers.map((number) => (
          <li key={number} className="admin_page_item">
            {number < 8 && (
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  border: "none",
                  background: "transparent",
                }}
                className={
                  currentPage === number
                    ? "admin_page_link selected_page"
                    : "admin_page_link"
                }
                onClick={() => paginate(number)}
              >
                {number}
              </button>
            )}
          </li>
        ))}

        <li>
          {pageNumbers.length > 8 && (
            <button
              style={{ border: "none", background: "none", color: "#787486" }}
              onClick={() => paginate(pageNumbers.length)}
            >
              &nbsp;. &nbsp; . &nbsp; . {pageNumbers.length}
            </button>
          )}
        </li>

        <li>
          <button
            style={{
              paddingLeft: "10px",
              border: "none",
              background: "transparent",
            }}
            onClick={() => nextPage(currentPage + 1)}
          >
            <img src={leftArrow} alt="" />
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
