import { FC, useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import "./LogIn.scss";
import { ButtonAnimationUp, RegularInput } from "../Atoms";
import logoUppiness from "../../assets/icons/logoUppiness.svg";
import home from "../../assets/icons/home.svg";
import validateEmail from "../../util/validateEmail";
import { AuthContext } from "../../contexts";


const LogIn: FC<{}> = () => {
  const { logIn, logInError, sendPasswordToEmail } = useContext(AuthContext);
  const [isPasswordForgotten, setIsPasswordForgotten] = useState<boolean>(false);
  const emailRef = useRef<any>();
  const passwordRef = useRef<any>();
  const forgottenEmailRef = useRef<any>();
  const { t } = useTranslation();

  useEffect(() =>{
    if (logInError)  alert(t("yourEmailOrPasswordIsNotGoodText"));
  }, [logInError] )

  const sendPasswordToMyEmail = (email: string) => {
    sendPasswordToEmail(email);
  }

  const passwordForgottenForm = () => {
    return (
      <div className="forgotten_password"> 
        <div className="forgotten_password_instruction"> {t("pleaseEnterYourEmailSoWeCanSendYouAPasswordText")} </div>
        <div className="forgotten_password_insert"> 
          <RegularInput icon={home} placeholder={t("emailText")} inputType="email" takeInputValue={(data: any) => console.log(data)}refInput={forgottenEmailRef} />
          <div className="forgotten_password_back_button_wrapper" onClick={() => setIsPasswordForgotten(false)}>
            <ButtonAnimationUp text={t("backText")} functionality={() => setIsPasswordForgotten(true)} />
          </div>
        </div>
        <div className="forgotten_password_send_button_wrapper" onClick={() => setIsPasswordForgotten(false)}>
            <ButtonAnimationUp text={t("sendText")} functionality={() => sendPasswordToMyEmail(forgottenEmailRef.current.value)} />
        </div>
      </div>
    )
  } 
  
  const postLogInData = () => {
      logIn(mapEmail(emailRef.current.value), mapPassword(passwordRef.current.value));
  };

  const mapEmail = (email: string | number) => {
    return (isString(email) && validateEmail(email)) ?  email : "";
  }

  const mapPassword = (password: string | number) => {
    return isString(password) ? password : "";
  }

  const isString = (data: any) : data is string=> {
    return typeof data === 'string';
  }

  const logInForm = () => {
    return(
      <div className="log_in_page_fields">
        <div className="log_in_page_inputs ">
            <div className="regular_input_holder">
              <RegularInput placeholder={t("emailText")} inputType="email" takeInputValue={(enteredEmail : string | number) =>  mapEmail(enteredEmail)}refInput={emailRef} isEnterPressed={() => postLogInData()} />
            </div>
            <div className="regular_input_holder">
              <RegularInput placeholder={t("passwordText")} inputType="password" takeInputValue={(enteredPassword : string | number) => mapPassword(enteredPassword)}refInput={passwordRef} isEnterPressed={() => postLogInData()} />
            </div>
            <div className="log_in_forgot_password" onClick={() => setIsPasswordForgotten(true)}> 
              {t("forgotYourPasswordText")}
            </div>
            <div className="terms_and_conditions">
              {t("T&CAcceptText")}
            </div>
        </div>
        <div className="log_in_button_wrapper">
            <ButtonAnimationUp text={t("logInText")} functionality={postLogInData} />
        </div>
      </div>
    )
  }

  return (
    <div className="log_in_page_wrapper full_center font_family">
      <div className="log_in_page_content flex_direction_column center_vertically ">
        <img className="log_in_page_logo" src={logoUppiness} alt="Uppiness" />
          {isPasswordForgotten ? passwordForgottenForm() : logInForm() }
      </div>
    </div>
  )

};

export default LogIn;
