import axios from "axios";
import React, { FC, createContext, useState, useContext } from "react";
import { AuthDataContainerContext, UserDataContext } from ".";

interface ICardContextNew {
    getActionCustomCardsNew(selectedLanguage: string | undefined, language: any): void;
    getActionDefaultCardsNew(selectedLanguage: string | undefined): void;
    getQuestionCustomCardsNew(selectedLanguage: string | undefined, language: any): void;
    getQuestionDefaultCardsNew(selectedLanguage: string | undefined): void;
    createNewCustomQuestionCardHTTP(customActionCard: any, language: any): void;
    mapReceivedQuestionCards(receivedQuestionCustomCards: any[] | undefined): any;
    mapReceivedActionCards(receivedActionCustomCards: any[] | undefined): any;
    deleteCustomQuestionCardHTTP(customQuestionId: any, language: any): void;
    updateCustomQuestionCardHTTP(customQuestionCard: any, language: any): void;
    createNewCustomActionCardHTTP(customActionCard: any, language: any): void;
    deleteCustomActionCardHTTP(customActionId: any, language: any): void;
    updateCustomActionCardHTTP(customQuestionCard: any, language: any): void;

    actionCustomCardsNew: any[];
    setActionCustomCardsNew(actionCustomCardsNew: any[]): void;
    actionDefaultCardsNew: any[];
    setActionDefaultCardsNew(actionDefaultCardsNew: any[]): void;
    questionCustomCardsNew: any[];
    setQuestionCustomCardsNew(questionCustomCardsNew: any[]): void;
    questionDefaultCardsNew: any[];
    setQuestionDefaultCardsNew(questionDefaultCardsNew: any[]): void;

    customActionCardIds: any[];
    setCustomActionCardIds(customActionCardIds: any[]): void;
    defaultActionCardIds: any[];
    setDefaultActionCardIds(defaultActionCardIds: any[]): void;
    customQuestionCardIds: any[];
    setCustomQuestionCardIds(customQuestionCardIds: any[]): void;
    defaultQuestionCardIds: any[];
    setDefaultQuestionCardIds(defaultQuestionCardIds: any[]): void;

    selectedQuestionCardForEdit: any;
    setSelectedQuestionCardForEdit(selectedQuestionCardForEdit: any): void;
    selectedActionCardForEdit: any;
    setSelectedActionCardForEdit(selectedActionCardForEdit: any): void;
}

const CardContextNew = createContext({} as ICardContextNew);

interface CardContextNewProps {
	children: React.ReactNode;
}

const CardContextNewProvider: FC<CardContextNewProps> = (props) => {
    const { token } = useContext(AuthDataContainerContext);
    const { userData } = useContext(UserDataContext);

	const [actionCustomCardsNew, setActionCustomCardsNew] = useState<any[]>([]);
	const [actionDefaultCardsNew, setActionDefaultCardsNew] = useState<any[]>([]);
	const [questionCustomCardsNew, setQuestionCustomCardsNew] = useState<any[]>([]);
	const [questionDefaultCardsNew, setQuestionDefaultCardsNew] = useState<any[]>([]);
    
    const [customActionCardIds, setCustomActionCardIds] = useState<any[]>([]);
    const [defaultActionCardIds, setDefaultActionCardIds] = useState<any[]>([]);
    const [customQuestionCardIds, setCustomQuestionCardIds] = useState<any[]>([]);
    const [defaultQuestionCardIds, setDefaultQuestionCardIds] = useState<any[]>([]);

    const [selectedQuestionCardForEdit, setSelectedQuestionCardForEdit] = useState<any>();
    const [selectedActionCardForEdit, setSelectedActionCardForEdit] = useState<any>();

    const  getActionCustomCardsNew = (email: string | undefined, language: any) => {
        axios({
            method: "post",
            url: process.env.REACT_APP_BACKEND_URL + "action-cards/get-custom-cards/language",
            data:{
                language: language,
                email:email
            },
            headers: { 
                Authorization: token
            }
            })
            .then((res: any) => {
                setActionCustomCardsNew(mapReceivedActionCards(res?.data));
                if (customActionCardIds.length === 0) setCustomActionCardIds(getActionCard_Ids(res?.data));
            })
            .catch((err: any) => console.error(err));
    }

    const  getActionDefaultCardsNew = (selectedLanguage: string | undefined) => {
        axios({
            method: "get",
            url: process.env.REACT_APP_BACKEND_URL + "action-cards/get-default-cards/" + selectedLanguage,
            headers: { 
                Authorization: token
            }
            })
            .then((res: any) => {
                setActionDefaultCardsNew(mapReceivedActionCards(res?.data));
                setDefaultActionCardIds(getActionCard_Ids(res?.data));
            })
            .catch((err: any) => console.error(err));
    }

    const mapReceivedActionCards = (receivedActionCustomCards: any) => {
        return receivedActionCustomCards.map( (actionCard: any, index: number) => {
            return {
                id: actionCard._id,
                title: actionCard.title,
                description: actionCard.description,
                numberOfUpperTokens: actionCard.numberOfUpperTokens,
                order: actionCard.order
            }
        })
    }

    const getActionCard_Ids = (receivedActionCards: any) => {
        return receivedActionCards.map( (actionCard: any) => {
            return actionCard._id;
        })
    }

    const  getQuestionCustomCardsNew = (userEmail: string | undefined, language: any) => {
        axios({
            method: "post",
            url: process.env.REACT_APP_BACKEND_URL + "question-cards/get-custom-cards/language",
            data:{
                language: language,
                email:userEmail
            },
            headers: { 
                Authorization: token
            }
            })
            .then((res: any) => {
                setQuestionCustomCardsNew(mapReceivedQuestionCards(res?.data));
                if (customQuestionCardIds.length === 0) setCustomQuestionCardIds(getQuestionCard_Ids(res?.data));
            })
            .catch((err: any) => console.error(err));
    }

    const  getQuestionDefaultCardsNew = (selectedLanguage: string | undefined) => {
        axios({
            method: "get",
            url: process.env.REACT_APP_BACKEND_URL + "question-cards/get-default-cards/" + selectedLanguage,
            headers: { 
                Authorization: token
            }
            })
            .then((res: any) => {
                setQuestionDefaultCardsNew(mapReceivedQuestionCards(res?.data));
                setDefaultQuestionCardIds(getQuestionCard_Ids(res?.data));
            })
            .catch((err: any) => console.error(err));
    }
	
    const mapReceivedQuestionCards = (receivedQuestionCustomCards: any) => {
        return receivedQuestionCustomCards.map( (questionCard: any) => {
            return {
                id: questionCard.id || questionCard._id,
                title: questionCard.title,
                description: questionCard.description,
                order: questionCard.order
            }
        })
    }

    const getQuestionCard_Ids = (receivedQuestionCards: any) => {
        return receivedQuestionCards.map( (questionCard: any) => {
            return questionCard._id;
        })
    }

    const createNewCustomQuestionCardHTTP = (customQuestionCard: any, language: any) => {
        axios({
          method: "post",
          url: process.env.REACT_APP_BACKEND_URL + "question-cards/create-custom-card",
          headers: { 
              Authorization: token
          },
          data: customQuestionCard
          })
          .then((res: any) => {
                getQuestionCustomCardsNew(userData?.email, language);
                setCustomQuestionCardIds([...customQuestionCardIds, res?.data?._id])
          })
          .catch((err: any) => console.error(err));
    }

    const deleteCustomQuestionCardHTTP = (customQuestionId: any, language: any) => {
        axios({
          method: "delete",
          url: process.env.REACT_APP_BACKEND_URL + "question-cards/" + customQuestionId,
          headers: { 
              Authorization: token
          }
          })
          .then((res: any) => {
                getQuestionCustomCardsNew(userData?.email, language);
          })
          .catch((err: any) => console.error(err));
    }

    const updateCustomQuestionCardHTTP = (customQuestionCard: any, language: any) => {
        axios({
          method: "patch",
          url: process.env.REACT_APP_BACKEND_URL + "question-cards/update-custom-card/" + customQuestionCard.id,
          headers: { 
              Authorization: token
          },
          data: {description: customQuestionCard.description, order: customQuestionCard.order }
          })
          .then((res: any) => {
              getQuestionCustomCardsNew(userData?.email, language);
          })
          .catch((err: any) => console.error(err));
    }

    const createNewCustomActionCardHTTP = (customActionCard: any, language: any) => {
        axios({
          method: "post",
          url: process.env.REACT_APP_BACKEND_URL + "action-cards/create-custom-card",
          headers: { 
              Authorization: token
          },
          data: customActionCard
          })
          .then((res: any) => {
                getActionCustomCardsNew(userData?.email, language);
                setCustomActionCardIds([...customActionCardIds, res?.data?._id])
          })
          .catch((err: any) => console.error(err));
    }

    const deleteCustomActionCardHTTP = (customActionId: any, language: any) => {
        axios({
          method: "delete",
          url: process.env.REACT_APP_BACKEND_URL + "action-cards/" + customActionId,
          headers: { 
              Authorization: token
          }
          })
          .then((res: any) => {
                getActionCustomCardsNew(userData?.email, language);
          })
          .catch((err: any) => console.error(err));
    }

    const updateCustomActionCardHTTP = (customActionCard: any, language: any) => {

        axios({
            method: "patch",
            url: process.env.REACT_APP_BACKEND_URL + "action-cards/update-custom-card/" + customActionCard.id,
            headers: { 
                Authorization: token
            },
            data: customActionCard
            })
            .then((res: any) => {
                getActionCustomCardsNew(userData?.email, language);
            })
            .catch((err: any) => console.error(err));
    }

	const providerValue = {
        getActionCustomCardsNew,
        getActionDefaultCardsNew,
        getQuestionCustomCardsNew,
        getQuestionDefaultCardsNew,

        actionCustomCardsNew,
        setActionCustomCardsNew,
        actionDefaultCardsNew,
        setActionDefaultCardsNew,
        questionCustomCardsNew,
        setQuestionCustomCardsNew,
        questionDefaultCardsNew,
        setQuestionDefaultCardsNew,
        
        customActionCardIds,
        setCustomActionCardIds,
        defaultActionCardIds,
        setDefaultActionCardIds,
        customQuestionCardIds,
        setCustomQuestionCardIds,
        defaultQuestionCardIds,
        setDefaultQuestionCardIds,
        createNewCustomQuestionCardHTTP,
        deleteCustomQuestionCardHTTP,
        updateCustomQuestionCardHTTP,
        createNewCustomActionCardHTTP,
        deleteCustomActionCardHTTP,
        updateCustomActionCardHTTP,

        mapReceivedQuestionCards,
        mapReceivedActionCards,

        selectedQuestionCardForEdit,
        setSelectedQuestionCardForEdit,
        selectedActionCardForEdit,
        setSelectedActionCardForEdit
	};

	return (
		<CardContextNew.Provider value={providerValue}>
			{props.children}
		</CardContextNew.Provider>
	);
};

export { CardContextNew, CardContextNewProvider };
