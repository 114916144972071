import axios from "axios";
import React, { FC, createContext, useState, useContext } from "react";
import { AuthDataContainerContext } from ".";
import { ICoupon } from "../interfaces";

interface ICouponContext {
    getAllCoupons(): void;
    getAllValidateCoupons(code: string): void;

    postCreateDiscountCoupon(updatedCoupon: any): void;
    postCreateCompanyCoupon(pdatedCoupon: any): void;
    postCreateReferalCoupon(updatedCoupon: any): void;

    patchUpdateCoupon(couponId: string, updatedCoupon: any): void;
    patchActivateCoupon(couponId: string, updatedCoupon: any): void;
    patchDeactivateCoupon(couponId: string, updatedCoupon: any): void;

    deleteCoupon(couponId: string): void;

    coupons: ICoupon[] | undefined;
    setCoupons(coupons: ICoupon[] | undefined): void;
    validateCoupons: any;
    setValidateCoupons(validateCoupons: any): void;
    coupon: any | undefined;
    setCoupon(coupon: any | undefined): void;
    selectedCoupon: any;
    setSelectedCoupon(selectedCoupon: any ): void;
    addTablesToUser(userEmail: string, numberOfNewTables: number): void;
}

const CouponContext = createContext({} as ICouponContext);

interface CouponContextProps {
	children: React.ReactNode;
}

const CouponContextProvider: FC<CouponContextProps> = (props) => {
    const { token } = useContext(AuthDataContainerContext);
	const [coupons, setCoupons] = useState<ICoupon[] | undefined>();
	const [validateCoupons, setValidateCoupons] = useState<any>();
    const [coupon, setCoupon] = useState<any| undefined>();
    const [selectedCoupon, setSelectedCoupon] = useState();

    const getAllCoupons = () => {
        axios({
            method: "get",
            url: process.env.REACT_APP_BACKEND_URL + "coupons/",
            headers: { 
                Authorization: token
            }
            })
            .then((res: any) => {
                console.log("get coupons/ ", res);
                setCoupons(res.data);
            })
            .catch((err: any) => console.error(err));
    }

    const addTablesToUser = (userEmail: string, numberOfNewTables: number) => {
        axios({
            method: "post",
            url: process.env.REACT_APP_BACKEND_URL + "tables/purchase-tables-for-user",
            headers: { 
                Authorization: token
            },
            data: {
                email: userEmail,
                tables: numberOfNewTables,
              },
            })
            .then((res: any) => {
                console.log("get coupons/ ", res);
                alert(`All good! The number of tables for ${userEmail} is increased for ${numberOfNewTables} tables`);
                setCoupons(res.data);
            })
            .catch((err: any) => {
                let output = err?.message ? err?.message : "";
                alert(output);
                console.error(err)
            })
    }

    const getAllValidateCoupons = (code: string) => {
        axios({
            method: "get",
            url: process.env.REACT_APP_BACKEND_URL + "coupons/"+code+"/validate",
            headers: { 
                Authorization: token
            }
            })
            .then((res: any) => {
                console.log("get coupons/:code/validate ", res);
                setCoupons(res.data);
            })
            .catch((err: any) => console.error(err));
    }

    const postCreateDiscountCoupon = (updatedCoupon: any) => {
        axios({
            method: "post",
            url: process.env.REACT_APP_BACKEND_URL + "coupons/discount-coupon",
            headers: { 
                Authorization: token
            },
            data: updatedCoupon
            })
            .then((res: any) => {
                console.log("post coupons/discount-coupon", res);
                getAllCoupons();
            })
            .catch((err: any) => console.error(err));
    }

    const postCreateCompanyCoupon = (updatedCoupon: any) => {
        axios({
            method: "post",
            url: process.env.REACT_APP_BACKEND_URL + "coupons/company-coupon",
            headers: { 
                Authorization: token
            },
            data: updatedCoupon
            })
            .then((res: any) => {
                console.log("post coupons/company-coupon", res);
                getAllCoupons();
            })
            .catch((err: any) => console.error(err));
    }

    const postCreateReferalCoupon = (updatedCoupon: any) => {
        axios({
            method: "post",
            url: process.env.REACT_APP_BACKEND_URL + "coupons/referal-coupon",
            headers: { 
                Authorization: token
            },
            data: updatedCoupon
            })
            .then((res: any) => {
                console.log("post coupons/referal-coupon", res);
                getAllCoupons();
            })
            .catch((err: any) => console.error(err));
    }

    const patchUpdateCoupon = (couponId: string, updatedCoupon: any) => {
        axios({
            method: "patch",
            url: process.env.REACT_APP_BACKEND_URL + "coupons/"+couponId,
            headers: { 
                Authorization: token
            },
            data: updatedCoupon
            })
            .then((res: any) => {
                console.log("post coupons/:id", res);
                getAllCoupons();
            })
            .catch((err: any) => console.error(err));
    }

    const patchActivateCoupon = (couponId: string, updatedCoupon: any) => {
        axios({
            method: "patch",
            url: process.env.REACT_APP_BACKEND_URL + "coupons/"+couponId+"/activate",
            headers: { 
                Authorization: token
            },
            data: {
                coupon: updatedCoupon
            }
            })
            .then((res: any) => {
                console.log("patch coupons/:id/activate", res);
            })
            .catch((err: any) => console.error(err));
    }

    const patchDeactivateCoupon = (couponId: string, updatedCoupon: any) => {
        axios({
            method: "patch",
            url: process.env.REACT_APP_BACKEND_URL + "coupons/"+couponId+"/deactivate",
            headers: { 
                Authorization: token
            },
            data: {
                coupon: updatedCoupon
            }
            })
            .then((res: any) => {
                console.log("patch coupons/:id/deactivate", res);
            })
            .catch((err: any) => console.error(err));
    }

    const deleteCoupon = (couponId: string) => {
       alert(couponId);
        axios({
            method: "delete",
            url: process.env.REACT_APP_BACKEND_URL + "coupons/"+couponId,
            headers: { 
                Authorization: token
            }
            })
            .then((res: any) => {
                console.log("delete coupons/:id", res);
                getAllCoupons();
            })
            .catch((err: any) => console.error(err));
    }

	const providerValue = {
        getAllCoupons,
        getAllValidateCoupons,

        postCreateDiscountCoupon,
        postCreateCompanyCoupon,
        postCreateReferalCoupon,

        patchUpdateCoupon,
        patchActivateCoupon,
        patchDeactivateCoupon,

        deleteCoupon,

        coupons,
        setCoupons,
        validateCoupons,
        setValidateCoupons,
        coupon,
        setCoupon,
        selectedCoupon,
        setSelectedCoupon,
        addTablesToUser  
	};

	return (
		<CouponContext.Provider value={providerValue}>
			{props.children}
		</CouponContext.Provider>
	);
};

export { CouponContext, CouponContextProvider };
