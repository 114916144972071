import { useContext, useEffect, useRef } from "react";
import { ComponentsContext } from "../../../contexts";

const AlertNotification = () => {
    const { notificationText, isNotificationActivated, setIsNotificationActivated } = useContext(ComponentsContext);

    const alertRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        if (alertRef.current)
            clearTimeout(alertRef.current);

        alertRef.current = setTimeout(() => {
            setIsNotificationActivated(false);
        }, 4000);
    }, [isNotificationActivated, setIsNotificationActivated])

    if (isNotificationActivated && notificationText)
        return (
            <div className="notification_wrapper" onClick={() => setIsNotificationActivated(false)}>  
                <div className="notification" onClick={() => setIsNotificationActivated(false)}>  
                    {notificationText} 
                </div>
            </div>
        )
    
    return <div></div>
    
}

export default AlertNotification;