import { FC, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthDataContainerContext, ComponentsContext, LearningContext, NotificationContext, UserDataContext } from "../../contexts";
import { routeNamesDic } from "../../interfaces/Dictionaries";
import yellowPlusIcon from "../../assets/icons/yellowPlusIcon.svg";

interface ISidebarmenuItem {
    index: number,
    icon: any,
    iconSelected: any,
    itemName: string,
    isLeftIconShown: boolean,
    sidebarMenuItemName: string,
}

const SidebarmenuItem: FC<ISidebarmenuItem> = ({index, icon, iconSelected, itemName, isLeftIconShown, sidebarMenuItemName}) => {
    const { token } = useContext(AuthDataContainerContext);
    const { currentlySelectedScreen, setCurrentlySelectedScreen, setToggleModalValue, setMatchPopUpType } = useContext(ComponentsContext);
    const { userData } = useContext(UserDataContext);
    const { setSelectedLearningOption } = useContext(LearningContext);
    const { getUnseenCountEndPoint, count } = useContext(NotificationContext);
    
    const isThisItemActive = sidebarMenuItemName === currentlySelectedScreen;
    const sidebarMenuItemStyle = isThisItemActive ? "sidebar_menu_item sidebar_menu_item_selected" : "sidebar_menu_item"
    let history = useHistory();
    const { t } = useTranslation();

    const sidebarMenuItemNamesDic : any= {
        0: t("matchesText"),
        1: t("historyText"),
        2: t("learningText"),
        3: t("FacilitatorText"),
        4: t("notificationText"),
        5: t("purchaseTablesText"),
        6: t("myProfileText"),
        7: t("matchesCardDeckText")
    }

    const sidebarMenuItemRouteNamesDic : any= {
        0: "Matches",
        1: "History",
        2: "Learning",
        3: "Facilitators",
        4: "Notifications",
        5: "Purchase Tables",
        6: "My Profile",
        7: "Matches/Card Deck"
    }

    const redirectScreen = () => {
        setCurrentlySelectedScreen(sidebarMenuItemNamesDic[index])
        const selectedRouteName =  routeNamesDic[sidebarMenuItemRouteNamesDic[index]];
        if (history)
            history.push(selectedRouteName);
    }

    const getIcon = () => {
        if (isThisItemActive)
            return  <img className="sidebar_icon" src={iconSelected} alt="icon" />;
        return  <img className="sidebar_icon" src={icon} alt="icon" />;
    }

    const onRightIconClick = () => {
        setSelectedLearningOption("create");
        setMatchPopUpType({"modalType": "createMatch"});
        setToggleModalValue(true)
        
        if(index === 3) {
            const darkBackground : any = document.getElementById("dark_background");
            darkBackground.style.visibility = "visible";
        }

    }

    useEffect(() => {
        getUnseenCountEndPoint();
    }, [userData])


    // useEffect(() => {
    //     fetchCurrentUser();
    // }, [])
    

    return (
        <div className={sidebarMenuItemStyle} key={index} onClick={() => redirectScreen()}>
            <div className="sidebar_menu_left_icon"> {getIcon()} </div>
            <div className="sidebar_menu_text">  {itemName} </div>
            <div className="sidebar_menu_right_icon" onClick={onRightIconClick}> 
                {isLeftIconShown && userData?.isAdmin ? <img src={yellowPlusIcon} alt="plus" /> :
                    isLeftIconShown && !userData?.isAdmin && itemName === "Matches" && <img src={yellowPlusIcon} alt="plus" /> } 
                {index === 4 && <div className="notification-count">{count}</div>}
            </div>
        </div>
    )
}

export default SidebarmenuItem;