import { FC } from "react";

type TInputType = "text" | "number" | "email" | "password";

interface IInputField {
    inputType?: TInputType;
    placeholder: string;
    takeInputValue(input: string | number): void;
    refInput?: any;
    isEnterPressed?(): any;
    disabled?: boolean;
}

const InputField: FC<IInputField> = ({inputType, placeholder, takeInputValue, refInput, isEnterPressed, disabled}) => {
    const type = inputType ? inputType : "text";

    const onBlur = (e: any) => {
        takeInputValue(e.target.value);        
    }
    
    const onKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            if (isFunction(isEnterPressed) && isEnterPressed) isEnterPressed();
            takeInputValue(e.target.value);
        }
    }

    const isFunction = (value: any) => {
        return typeof value === "function" ? true : false;
    }

    const htmlInputField = (type: string) => {
        return (
            <input 
                type={type}  
                className="form-control" 
                placeholder={placeholder} 
                disabled={disabled}
                onBlur={(e) => onBlur(e)}
                onKeyPress={onKeyPress}
                ref={refInput}
            />
        )
    }

    const inputDict = {
        "text": htmlInputField("text"),
        "number": htmlInputField("number"),
        "email": htmlInputField("email"),
        "password": htmlInputField("password"),
    }
    
    return <>{inputDict[type]}</>;
}

export default InputField;