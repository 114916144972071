import axios from "axios";
import React, { FC, createContext, useState, useContext, useEffect, useCallback } from "react";
import { AuthDataContainerContext, SocketContext } from ".";
import { IUser } from "../interfaces";

interface IPurchases {
	amount: number, 
	purchases_id: string
}

interface IUserData { 
	_id: string,
    country: string,
	firstName: string,
    lastName: string,
	email: string,
    linkedin: string,
	isAdmin: boolean,
	hasDiscounts: boolean,
	password: string,
	createdAt: string,
	updatedAt: string,
	purchases: IPurchases[],
    pictureUrl: string
	__v: number
}

interface IUserDataContext {
    userData: IUserData | undefined;
    currentUser: IUser | undefined;
    allUsers: IUser[] | undefined
    setCurrentUser(currentUser: IUser | undefined): void;
    selectedUser: IUser | undefined;
    setSelectedUser(selectedUser: IUser | undefined): void;
    setAllUsers(allUsers: IUser[] | undefined): void;
    fetchCurrentUser(): void;
    fetchAllUsers(): void
    currentlySelectedLanguage: string;
    setCurrentlySelectedLanguage(currentlySelectedLanguage: string): void;
    setUserData(userData: any): void;
}

const UserDataContext = createContext({} as IUserDataContext);
interface UserDataContextProps {
  children: React.ReactNode;
}

const UserDataContextProvider: FC<UserDataContextProps> = (props) => {
    const { token, setToken, setEmail } = useContext(AuthDataContainerContext);
    const { socket } = useContext(SocketContext);
    const [ currentUser, setCurrentUser ] = useState<IUser | undefined>();
    const [userData, setUserData] = useState<IUserData | undefined>();
    const [selectedUser, setSelectedUser] = useState<IUser | undefined>();
    const [allUsers, setAllUsers] = useState<IUser[] | undefined>();
    const [currentlySelectedLanguage, setCurrentlySelectedLanguage] = useState<string>("English");
    
    const fetchCurrentUser = useCallback( () => {
        
        axios({
            method: "get",
            url: process.env.REACT_APP_BACKEND_URL + "users/current",
            headers: { 
                Authorization: token
            }
        })
        .then((res: any) => {
            setUserData(res.data);
        if(res.data?.expired === true){
                localStorage.removeItem("token");
                localStorage.removeItem("email");
        
                socket?.disconnect();
                
                setEmail("");
                setToken("");
            }
        })
        .catch((err: any) => {
        });
    }, [token]);

    const fetchAllUsers = useCallback( () => {
        axios({
            method: "get",
            url: process.env.REACT_APP_BACKEND_URL + "users/get-all",
            headers: { 
                Authorization: token
            }
        })
        .then((res: any) => {
            setAllUsers(res.data);
        })
        .catch((err: any) => console.error(err));
    }, [token]);

    useEffect( () => {
		if (token)
            fetchCurrentUser();
    }, [token, fetchCurrentUser]);

    useEffect( () => {
		if (token)
            fetchAllUsers();
    }, [token, fetchAllUsers]);

    const providerValue = {
        allUsers,
        userData,
        currentUser, 
        selectedUser, 
        currentlySelectedLanguage, 
        setCurrentUser,
        setSelectedUser,
        setAllUsers,
        fetchCurrentUser,
        fetchAllUsers,
        setCurrentlySelectedLanguage,
        setUserData
    };

    return (
        <UserDataContext.Provider value={providerValue}>
        {props.children}
        </UserDataContext.Provider>
    );
};

export { UserDataContext, UserDataContextProvider };
