import axios from "axios";
import { FC, useContext } from "react";
import { AuthDataContainerContext, UserDataContext } from "../../../contexts";
import { RedButtonAnimationUp, ButtonAnimationUp } from "../../Atoms";

interface IDeteteTheUser {
    setOpenDeleteFacilitator: React.Dispatch<React.SetStateAction<boolean>>,   
}

const DeteteTheUser: FC<IDeteteTheUser> = ({setOpenDeleteFacilitator}) => {
    const { token } = useContext(AuthDataContainerContext);
    const { selectedUser, fetchAllUsers } = useContext(UserDataContext);

    const deleteTheUser = async() => {
        if(token) {
            axios({
                method: "delete",
                url: process.env.REACT_APP_BACKEND_URL + `users/${selectedUser?._id}`,
                headers: { 
                    Authorization: token
                }
            })
            .then((res: any) => {
                fetchAllUsers();
                const darkBackground : any = document.getElementById("dark_background");
                darkBackground.style.visibility = "hidden"; 
    
            })
            .catch((err: any) => console.error(err));
        }
        
        setOpenDeleteFacilitator(false);      
 
    }

    const cancelDelete = () => {

        const darkBackground : any = document.getElementById("dark_background");
        darkBackground.style.visibility = "hidden"; 

        setOpenDeleteFacilitator(false);
    }

    return (
        <div className="delete_facilitator_box">
            <div className="delete_facilitator_heading"> Are You Sure You Want To Remove This Facilitator </div>

            <div className="create_new_match_button_holder invite_facilitator_buttons">
                <div className="create_new_match_button_wrapper red_button">
                    <RedButtonAnimationUp text="delete the user" functionality={() => deleteTheUser()} />
                </div>

                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text="Cancel" functionality={() => cancelDelete()} />
                </div>
            </div>
        </div>      
    )
}

export default DeteteTheUser;