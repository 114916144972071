import axios from "axios";
import React, { FC, createContext, useState, useContext, useCallback } from "react";
import { AuthDataContainerContext } from "./AuthDataContainer";
import { SocketContext } from "./SocketContext";
import { UserDataContext } from "./UserDataContext";

interface INotification {
    facilitator: string,
    key: string,
    link: string,
    message: string,
    seen: boolean,
    updatedAt?: string,
    createdAt?: string,
    __v?: number,
    _id: string
}

interface INotificationContext {
    toggleModalValue: boolean,
	setToggleModalValue(toggleModalValue: boolean): void;
	allNotifications: INotification[];
	count: any;

	getUnseenCountEndPoint(): void;
	notifySocketOn(): void;
	labelNotificationAsSeen(notification: any): void;
	fetchAllNotifications(): void;
}

const NotificationContext = createContext({} as INotificationContext);

interface AuthContextProps {
	children: React.ReactNode;
}

const NotificationContextProvider: FC<AuthContextProps> = (props) => {
	const { joinRoomSpectatorSocketEmit } = useContext(SocketContext);
	const { socket } = useContext(SocketContext);
	const { token } = useContext(AuthDataContainerContext);
    const { userData } = useContext(UserDataContext);
	const [toggleModalValue, setToggleModalValue] = useState<boolean>(false);
	const [allNotifications, setAllNotifications] = useState<INotification[]>([]);
	const [count, setCount] = useState<any>();
	const userEmail: string = userData?.email || "";

	const alerWithSound = () => {
        var context = new AudioContext();
        var o = context.createOscillator();
        o.type = "triangle";
        o.frequency.value = 440;
        o.connect(context.destination);
        o.start();
        
        setTimeout(() => o.stop(), 150);
    }

	const getGameURL = (notification: any) => {
        const notificatoinMessage: string = notification?.message;
        const firstBracketIndex = notificatoinMessage.lastIndexOf("(")+1;
        const lastBracketIndex = notificatoinMessage.lastIndexOf(")");
        const gameToken = notificatoinMessage.substring(firstBracketIndex, lastBracketIndex);    

		localStorage.setItem(gameToken, gameToken+userEmail);
		console.log(userEmail)
        window.open(process.env.REACT_APP_GAME + "spectator/" + gameToken);

		let payload = {
            matchId: gameToken,
            gameId: 1,
            spectator: {
                email: gameToken+userEmail,
            }
   		}
		joinRoomSpectatorSocketEmit(payload);
        console.log("joinRoomSpectator payload: ", payload);

        // return process.env.REACT_APP_GAME + "force/" + gameToken;
    }

	const getUnseenCountEndPoint = () => {
		if (userData?._id){
			axios({
				method: "get",
				url: process.env.REACT_APP_BACKEND_URL  + `notifications/get-unseen-count/${userData?._id}`,
				headers: {
					Authorization: token,
				}
			})
			.then((res: any) => {
				console.log(res.data);
				setCount(res.data.unseen);
			})
			.catch((err: any) => console.error(err));
		}
	}

	const labelNotificationAsSeen = (notification: any) => {
		console.log("labeled as SEEN")
		axios({
			method: "patch",
			url: process.env.REACT_APP_BACKEND_URL  + `notifications/set-seen`,
			headers: {
				Authorization: token,
			},
			data: {id: notification?._id}
		})
		.then((res: any) => {
			console.log(res.data);
			console.log("notification set-seen data received")
			fetchAllNotifications();
			getGameURL(notification)
			// window.open(getGameURL(notification), '_blank'); 
		})
		.catch((err: any) => console.error(err));
	}

	const fetchAllNotifications = () => {
        axios({
            method: "get",
            url: process.env.REACT_APP_BACKEND_URL + `notifications/get-all`,
            headers: {
                Authorization: token,
            },
        })
        .then((res: any) => {
                const result = res.data
                result.forEach((notification: any) => {
                    notification.key = notification.facilitator
                })
                setAllNotifications(result.reverse());
                if (result.length > 0 ) {
					getUnseenCountEndPoint();
				}
        })
        .catch((err: any) => console.error(err));
    };

	const notifySocketOn = () => {
		if(userData){
			socket?.on("notify", (data: any) => {
				fetchAllNotifications();
				alerWithSound();
			})
		}
	};

	const providerValue = {
		toggleModalValue,
		setToggleModalValue,
		allNotifications,
		count,

		getUnseenCountEndPoint,
		notifySocketOn,
		labelNotificationAsSeen,
		fetchAllNotifications
	};

	return (
		<NotificationContext.Provider value={providerValue}>
			{props.children}
		</NotificationContext.Provider>
	);
};

export { NotificationContext, NotificationContextProvider };
