import { useContext, useEffect, useRef } from "react";
import {useTranslation} from "react-i18next";
import { CardContextNew, ComponentsContext, UserDataContext } from "../../contexts";
import { ILanguage } from "../../interfaces";

const useOutsideAlerter = (ref: any) => {
    const { setIsAnyDropDownBoxOpen } = useContext(ComponentsContext);

    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      const handleClickOutside = (event: any) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsAnyDropDownBoxOpen({"logOut": false, "languages": false})
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, setIsAnyDropDownBoxOpen]);
}

const LanguageDropDownBox = () => {
    const { userData } = useContext(UserDataContext);
    const { setSelectedLanguage, setIsAnyDropDownBoxOpen } = useContext(ComponentsContext);
    const {t, i18n} = useTranslation();
    
    const listOfLanguages: ILanguage[] = [
        {language: "English", abbrevation: "en"}, 
        {language: "Hebrew", abbrevation: "he"}, 
        {language: "Chinese", abbrevation: "ch"}, 
        {language: "Spanish", abbrevation: "es"}, 
        {language: "French", abbrevation: "fr"}, 
        {language: "German", abbrevation: "de"},
        {language: "Portuguese", abbrevation: "pt"}
    ] ;

    const { 
        getActionCustomCardsNew,
        getActionDefaultCardsNew,
        getQuestionCustomCardsNew,
        getQuestionDefaultCardsNew
    } = useContext(CardContextNew);
    
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <div ref={wrapperRef} className="drop_down_box_wrapper custom_scrollbar custom_made_scrollbar">
            {listOfLanguages.map( (item: ILanguage, index: number) => {
                return(
                    <div key={index} 
                    onClick={() =>{
                        getActionCustomCardsNew(userData?.email || "", item.language);
                        getQuestionCustomCardsNew(userData?.email || "", item.language);
                        getActionDefaultCardsNew(item.language);
                        getQuestionDefaultCardsNew(item.language); 
                        //selectLanguage(item.language);
                        //setLanguage(item.language);
                        i18n.changeLanguage(item.abbrevation);
                        setIsAnyDropDownBoxOpen({"logOut": false, "languages": false});
                        setSelectedLanguage(item.language);
                    }}
                    >
                        <div className="drop_down_box_item" > {item.language}  </div>
                        <div className={` ${(index+1 < listOfLanguages.length) && "border_bottom"} `} > </div>
                    </div>
                )
            })}
        </div>
    )
}

export default LanguageDropDownBox;