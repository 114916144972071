import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ButtonAnimationUp, RegularInput } from "../Atoms"
import passwordIcon from "../../assets/icons/passwordIcon.svg";
import hidePasswordIcon from "../../assets/icons/hidePasswordIcon.svg";
import showPasswordIcon from "../../assets/icons/showPasswordIcon.svg";
import { AuthDataContainerContext, ComponentsContext, UserDataContext } from "../../contexts";


interface IChangePassword {
    email: string,
    oldPassword: string,
    newPassword: string,
}

const ChangePasswordModal = () => {
    const { token } = useContext(AuthDataContainerContext);
    const { userData } = useContext(UserDataContext);
    const { setToggleModalValue, setNotificationText, setIsNotificationActivated } = useContext(ComponentsContext);
    const { t } = useTranslation();

    const [oldPasswordShown, setOldPasswordShown] = useState(false);
    const [newPasswordShown, setNewPasswordShown] = useState(false);
    const [repeatPasswordShown, setRepeatPasswordShown] = useState(false);
    const [oldPasswordShownIcon, setOldPasswordShownIcon] = useState(false);
    const [newPasswordShownIcon, setNewPasswordShownIcon] = useState(false);
    const [repeatPasswordShownIcon, setRepeatPasswordShownIcon] = useState(false);
    const [changePassword, setChangePassword] = useState<IChangePassword>({
        email:  userData?.email || "",
        oldPassword: "",
        newPassword: "",
    })

    const saveTheNewPassword = () => {

        axios({
            method: "patch",
            url: process.env.REACT_APP_BACKEND_URL + "users/change-password",
            headers: { 
                Authorization: token,
            },
            data: changePassword
        })
        .then((res: any) => {
            setNotificationText(t("thePasswordIsUpdatedText"));
            setIsNotificationActivated(true);
        })
        .catch((err: any) => console.error(err));

        setToggleModalValue(false);
    }

    const enterOldPassword = (password: any) => {
      if(password) {
          setChangePassword({...changePassword, oldPassword: password});
      }
    }

    const enterNewPassword = (password: any) => {
        if(password) {
            setChangePassword({...changePassword, newPassword: password})
        }
    }

    const repeatNewPassword = (password: any) => {
        if(password === changePassword.newPassword) {
            return
        }else alert(t("noMatchText"))

    }

    const toggleOldPassword = () => {
        setOldPasswordShownIcon(!oldPasswordShownIcon)
        setOldPasswordShown(!oldPasswordShown);
    };

    const toggleNewPassword = () => {
        setNewPasswordShownIcon(!newPasswordShownIcon)
        setNewPasswordShown(!newPasswordShown);
    };

    const toggleRepeatPassword = () => {
        setRepeatPasswordShownIcon(!repeatPasswordShownIcon)
        setRepeatPasswordShown(!repeatPasswordShown);
    };


    return (
        <div className="create_match_warning">
            <div className="create_match_warning_body">
                {t("changePasswordText")}
            </div>
            <div style={{marginBottom: "20px"}}>
                <RegularInput icon={passwordIcon} placeholder={t("enterOldPasswordText")} inputType={oldPasswordShown ? "text" : "password"} takeInputValue={(data) => enterOldPassword(data)} endIcon={oldPasswordShownIcon? hidePasswordIcon : showPasswordIcon}  edit={toggleOldPassword} />
            </div>
            <div style={{marginBottom: "20px"}}>
                <RegularInput icon={passwordIcon} placeholder={t("enterNewPasswordText")} inputType={newPasswordShown ? "text" : "password"} takeInputValue={(data) => enterNewPassword(data)} endIcon={newPasswordShownIcon? hidePasswordIcon : showPasswordIcon} edit={toggleNewPassword} />
            </div>
            <div style={{marginBottom: "20px"}}>
                <RegularInput icon={passwordIcon} placeholder={t("enterNewPasswordText")} inputType={repeatPasswordShown ? "text" : "password"} takeInputValue={(data) => repeatNewPassword(data)} endIcon={repeatPasswordShownIcon? hidePasswordIcon : showPasswordIcon}  edit={toggleRepeatPassword} />
            </div>
            <div className="create_new_match_button_wrapper">
                <ButtonAnimationUp text={t("saveText")} functionality={() => saveTheNewPassword()}  />
            </div>
            <div className="create_new_match_button_wrapper">
                <ButtonAnimationUp text={t("cancelText")} functionality={() => setToggleModalValue(false)}  />
            </div>
        </div>      
    )
}

export default ChangePasswordModal;