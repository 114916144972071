import { useContext, useEffect, useRef, useState } from "react"
import {useTranslation} from "react-i18next";
import Flags from "../Helpers/Flags";
import ToggleDown from "../../../assets/icons/toggleDown.svg";
import ToggleUp from "../../../assets/icons/toggleUp.svg";
import { UserDataContext } from "../../../contexts";
import SearchIcon from "../../../assets/icons/Search.svg"

interface ICountry {
    key: number,
    title: string,
    icon: any
}

const DropDown = (props: any) => {
    const { userData } = useContext(UserDataContext);
    const {onChange} = props;
    const [listOpen, setListOpen] = useState(false);
    const [headerTitle, setHeaderTitle] = useState<string | undefined>(undefined);
    const [searchResult, setSearchResult] = useState<ICountry[]>()
    const dropDown = useRef<any>()
    const inputSearchContainer = useRef<any>();
    const {t} = useTranslation();
    const search: string = t("serchText") + "...";
    
    // let countriesFromSearch: ICounty[] = []

    const toggleList = () => {
        setListOpen(!listOpen);
    }

    const clickOnList = (title : any) => {
        setHeaderTitle(title);
        setListOpen(false);
        onChange(title);
        setSearchResult(undefined)
        
    }

    const onToggleUp = () => {
        setSearchResult(undefined)
    }

    const typingSearch = (e: any) => {
        if(e.target.value === "") {
            setSearchResult(undefined);
            return;
        } 
        const countriesFromSearch: ICountry[] = Flags.Countries.filter((country) => {
            return country.title.charAt(0).toLocaleLowerCase() === e.target.value;
        })

        setSearchResult(countriesFromSearch);

        if(e.target.value.length === 2) {
            const countriesFromSearch: ICountry[] = Flags.Countries.filter((country) => {
                return country.title.charAt(0).toLocaleLowerCase().concat(country.title.charAt(1).toLocaleLowerCase()) === e.target.value;
            })
    
            setSearchResult(countriesFromSearch);
        }

        if(e.target.value.length === 3) {
            const countriesFromSearch: ICountry[] = Flags.Countries.filter((country) => {
                return country.title.charAt(0).toLocaleLowerCase().concat(country.title.charAt(1).toLocaleLowerCase()).concat(country.title.charAt(2).toLocaleLowerCase()) === e.target.value;
            })
    
            setSearchResult(countriesFromSearch);
        }

        if(e.target.value.length === 4) {
            const countriesFromSearch: ICountry[] = Flags.Countries.filter((country) => {
                return country.title.charAt(0).toLocaleLowerCase().concat(country.title.charAt(1).toLocaleLowerCase()).concat(country.title.charAt(2).toLocaleLowerCase()).concat(country.title.charAt(3).toLocaleLowerCase()) === e.target.value;
            })
    
            setSearchResult(countriesFromSearch);
        }

        if(e.target.value.length === 5) {
            const countriesFromSearch: ICountry[] = Flags.Countries.filter((country) => {
                return country.title.charAt(0).toLocaleLowerCase().concat(country.title.charAt(1).toLocaleLowerCase()).concat(country.title.charAt(2).toLocaleLowerCase()).concat(country.title.charAt(3).toLocaleLowerCase()).
                concat(country.title.charAt(4).toLocaleLowerCase()) === e.target.value;
            })
    
            setSearchResult(countriesFromSearch);
        }

        if(e.target.value.length === 6) {
            const countriesFromSearch: ICountry[] = Flags.Countries.filter((country) => {
                return country.title.charAt(0).toLocaleLowerCase().concat(country.title.charAt(1).toLocaleLowerCase()).concat(country.title.charAt(2).toLocaleLowerCase()).concat(country.title.charAt(3).toLocaleLowerCase()).
                concat(country.title.charAt(4).toLocaleLowerCase()).concat(country.title.charAt(5).toLocaleLowerCase()) === e.target.value;
            })
    
            setSearchResult(countriesFromSearch);
        }

        if(e.target.value.length === 7) {
            const countriesFromSearch: ICountry[] = Flags.Countries.filter((country) => {
                return country.title.charAt(0).toLocaleLowerCase().concat(country.title.charAt(1).toLocaleLowerCase()).concat(country.title.charAt(2).toLocaleLowerCase()).concat(country.title.charAt(3).toLocaleLowerCase()).
                concat(country.title.charAt(4).toLocaleLowerCase()).concat(country.title.charAt(5).toLocaleLowerCase()).concat(country.title.charAt(6).toLocaleLowerCase()) === e.target.value;
            })
    
            setSearchResult(countriesFromSearch);
        }

        if(e.target.value.length === 8) {
            const countriesFromSearch: ICountry[] = Flags.Countries.filter((country) => {
                return country.title.charAt(0).toLocaleLowerCase().concat(country.title.charAt(1).toLocaleLowerCase()).concat(country.title.charAt(2).toLocaleLowerCase()).concat(country.title.charAt(3).toLocaleLowerCase()).
                concat(country.title.charAt(4).toLocaleLowerCase()).concat(country.title.charAt(5).toLocaleLowerCase()).concat(country.title.charAt(6).toLocaleLowerCase())
                .concat(country.title.charAt(7).toLocaleLowerCase()) === e.target.value;
            })
    
            setSearchResult(countriesFromSearch);
        }

        if(e.target.value.length === 9) {
            const countriesFromSearch: ICountry[] = Flags.Countries.filter((country) => {
                return country.title.charAt(0).toLocaleLowerCase().concat(country.title.charAt(1).toLocaleLowerCase()).concat(country.title.charAt(2).toLocaleLowerCase()).concat(country.title.charAt(3).toLocaleLowerCase()).
                concat(country.title.charAt(4).toLocaleLowerCase()).concat(country.title.charAt(5).toLocaleLowerCase()).concat(country.title.charAt(6).toLocaleLowerCase())
                .concat(country.title.charAt(7).toLocaleLowerCase()).concat(country.title.charAt(8).toLocaleLowerCase()) === e.target.value;
            })
    
            setSearchResult(countriesFromSearch);
        }

        if(e.target.value.length === 10) {
            const countriesFromSearch: ICountry[] = Flags.Countries.filter((country) => {
                return country.title.charAt(0).toLocaleLowerCase().concat(country.title.charAt(1).toLocaleLowerCase()).concat(country.title.charAt(2).toLocaleLowerCase()).concat(country.title.charAt(3).toLocaleLowerCase()).
                concat(country.title.charAt(4).toLocaleLowerCase()).concat(country.title.charAt(5).toLocaleLowerCase()).concat(country.title.charAt(6).toLocaleLowerCase())
                .concat(country.title.charAt(7).toLocaleLowerCase()).concat(country.title.charAt(8).toLocaleLowerCase()).concat(country.title.charAt(9).toLocaleLowerCase()) === e.target.value;
            })
    
            setSearchResult(countriesFromSearch);
        }

        if(e.target.value.length === 11) {
            const countriesFromSearch: ICountry[] = Flags.Countries.filter((country) => {
                return country.title.charAt(0).toLocaleLowerCase().concat(country.title.charAt(1).toLocaleLowerCase()).concat(country.title.charAt(2).toLocaleLowerCase()).concat(country.title.charAt(3).toLocaleLowerCase()).
                concat(country.title.charAt(4).toLocaleLowerCase()).concat(country.title.charAt(5).toLocaleLowerCase()).concat(country.title.charAt(6).toLocaleLowerCase())
                .concat(country.title.charAt(7).toLocaleLowerCase()).concat(country.title.charAt(8).toLocaleLowerCase()).concat(country.title.charAt(9).toLocaleLowerCase())
                .concat(country.title.charAt(10).toLocaleLowerCase()) === e.target.value;
            })
    
            setSearchResult(countriesFromSearch);
        }

        if(e.target.value.length === 12) {
            const countriesFromSearch: ICountry[] = Flags.Countries.filter((country) => {
                return country.title.charAt(0).toLocaleLowerCase().concat(country.title.charAt(1).toLocaleLowerCase()).concat(country.title.charAt(2).toLocaleLowerCase()).concat(country.title.charAt(3).toLocaleLowerCase()).
                concat(country.title.charAt(4).toLocaleLowerCase()).concat(country.title.charAt(5).toLocaleLowerCase()).concat(country.title.charAt(6).toLocaleLowerCase())
                .concat(country.title.charAt(7).toLocaleLowerCase()).concat(country.title.charAt(8).toLocaleLowerCase()).concat(country.title.charAt(9).toLocaleLowerCase())
                .concat(country.title.charAt(10).toLocaleLowerCase()).concat(country.title.charAt(11).toLocaleLowerCase()) === e.target.value;
            })
    
            setSearchResult(countriesFromSearch);
        }

        inputSearchContainer.current.classList.remove("border-black");
        inputSearchContainer.current.classList.add("input-border");
        
    }

    const onBlurSearchTypingContainer = () => {
        inputSearchContainer.current.classList.add("border-black");
        inputSearchContainer.current.classList.remove("input-border");
    }

    useEffect(() => {
        setHeaderTitle(userData?.country || "Argentina");
    }, [userData])


    return(
        <div ref={dropDown} className="dd-wrapper">
            <div className="dd-header" onClick={() => toggleList()}>
                {Flags.Countries.map((country) => (
                    country.title === headerTitle && <div style={{}}><img className="icon-img" src={country.icon} alt="icon" /></div>
                ))}
                <div className="dd-header-title"> {t(headerTitle || "")} </div>
                {listOpen
                  ? <div onClick={onToggleUp} className="toggle-icon"><img src={ToggleUp} alt="" /></div>
                  : <div className="toggle-icon"><img src={ToggleDown} alt="" /></div>
                }
            </div>

            {listOpen && 
            <div onBlur={onBlurSearchTypingContainer} ref={inputSearchContainer} className="search-input border-black">
                <img style={{marginRight: "10px"}} src={SearchIcon} alt="" />
                <input placeholder={t(search)} onChange={(event: any) => typingSearch(event)} type="text" />
            </div>}

            {listOpen && !searchResult ?
            <ul className="dd-list">
                {Flags.Countries.map((country) => (
                <li onClick={(event) => clickOnList(country.title)} className="dd-list-item" key={country.key}>
                    <img className="icon-img" src={country.icon} alt="" /> {t(country.title)}
                </li>
                ))}
            </ul>:

            listOpen && searchResult &&
            <ul className="dd-list search-country">
            {searchResult.map((country) => (
            <li onClick={(event) => clickOnList(country.title)} className="dd-list-item" key={country.key}>
                <img className="icon-img" src={country.icon} alt="" /> {t(country.title)}
            </li>
            ))}
        </ul>
            }

        </div>
    )
}

export default DropDown

