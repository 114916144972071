import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import checkStroke from "../../../assets/icons/checkStroke.svg";
import toggleDown from "../../../assets/icons/toggleDown.svg";

interface ISelectAllCards {
    arrayOfCards: any;
    arrayOfCardsIds: number[];
    setArrayOfCardsIds(arrayOfCardsIds: number[]): void;
    isHavingDaliaCardsOption?: boolean;
}

const SelectAllCards: FC<ISelectAllCards> = ({arrayOfCards, arrayOfCardsIds, setArrayOfCardsIds, isHavingDaliaCardsOption}) => {
    const [selectedOption, setSelectedOption] = useState<string>("selectAll"); // State to track the selected option
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const { t } = useTranslation();
    
    useEffect(() => {
        if (selectedOption === "selectAll") {
            selectAll();
        } else if (selectedOption === "deselectAll") {
            deselectAll();
        } else if (selectedOption === "selectDEI") {
            selectDEI();
        } else if (selectedOption === "selectWithoutDEI") {
            selectWithoutDEI();
        }
    }, [selectedOption]);

    const selectAll = () => {
        var allIds: number[] = [];
        arrayOfCards.forEach((element: any) => {
            allIds.push(element.id);
        });
        setArrayOfCardsIds(allIds);
    }

    const deselectAll = () => {
        setArrayOfCardsIds([]);
    }

    const selectDEI = () => {
        var deiIds: number[] = [];
        arrayOfCards.slice(37).forEach((element: any) => deiIds.push(element.id));
        setArrayOfCardsIds(deiIds);
    }

    const selectWithoutDEI = () => {
        var withoutDeiIds: number[] = [];
        arrayOfCards.slice(0, 37).forEach((element: any) => withoutDeiIds.push(element.id));
        setArrayOfCardsIds(withoutDeiIds);
    }

    const selectedOptions: any = {
        "selectAll": "All selected",
        "deselectAll": "All deselected",
        "selectDEI": "Only DEI selected",
        "selectWithoutDEI": "All but DEI selected"
    }

    return (
        <div className="card_checker_wrapper" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
            <div className="card_check_all_box_wrapper" style={{ display: isHovering ? "block" : "none" }}>
                {/* {selectedOption !== "selectAll" && ( */}
                { true && (
                    <label className="radio-label">
                        <input type="radio" value="selectAll" checked={selectedOption === "selectAll"} onChange={() => setSelectedOption("selectAll")} />
                        <span className="radio-label-text">{t("Select All")}</span>
                    </label>
                )}
                {/* {selectedOption !== "deselectAll" && ( */}
                { true && (
                        <label className="radio-label">
                        <input type="radio" value="deselectAll" checked={selectedOption === "deselectAll"} onChange={() => setSelectedOption("deselectAll")} />
                        <span className="radio-label-text">{t("Deselect All")}</span>
                    </label>
                )}
                {isHavingDaliaCardsOption && (
                    <>
                        {/* {selectedOption !== "selectDEI" && ( */}
                        { true && (
                            <label className="radio-label">
                                <input type="radio" value="selectDEI" checked={selectedOption === "selectDEI"} onChange={() => setSelectedOption("selectDEI")} />
                                <span className="radio-label-text">{t("Select DEI")}</span>
                            </label>
                        )}
                        {/* {selectedOption !== "selectWithoutDEI" && ( */}
                        { true && (
                                <label className="radio-label">
                                <input type="radio" value="selectWithoutDEI" checked={selectedOption === "selectWithoutDEI"} onChange={() => setSelectedOption("selectWithoutDEI")} />
                                <span className="radio-label-text">{t("Select without DEI")}</span>
                            </label>
                        )}
                    </>
                )}
            </div>
            {!isHovering && <div className="dropdown_box_selectOptions">
                {selectedOptions[selectedOption]}
                <img src={toggleDown} alt="up" style={{ marginLeft: "10px" }} />
            </div>}
        </div>
    )
}

export default SelectAllCards;