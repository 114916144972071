import React, { FC, createContext, useState } from "react";

import { io, Socket } from "socket.io-client";
import { IJoinRoomSpectator } from "../interfaces";

const ENDPOINT = process.env.REACT_APP_GAME_BACKEND;

interface ISocketContext {
  socket?: Socket;
  facilitatorUrl: string;
  registerFacilitator: () => void;
  removeCallFromReceivedNotificationsList: (gameId: string) => void;
  joinRoomSpectatorSocketEmit(payload: IJoinRoomSpectator): void;
}

const SocketContext = createContext({} as ISocketContext);

interface SocketContextProps {
  children: React.ReactNode;
}

const SocketContextProvider: FC<SocketContextProps> = (props) => {
  const [socket, setSocket] = useState<Socket | undefined>();
  const [facilitatorUrl, setFacilitatorUrl] = useState<string>("");
  const [receivedNotifications, setReceivedNotifications] = useState<string[]>(
    []
  );

  const registerFacilitator = (): void => {
    const email: string | null = localStorage.getItem("email");

    const socketInitialisation = io(ENDPOINT || "");

    initialiseAllEvents(socketInitialisation);

    socketInitialisation?.emit("registerFacilitator", { email });

    setSocket(socketInitialisation);
  };

  const callToJoinGameSocketOn = (socketInitialisation: any): void => {
    socketInitialisation?.on("callToJoinGame", (data: any) => {
      handleNewCallToJoinGame(data.gameId);
    });
  };

  const initialiseAllEvents = (socketInitialisation: any): void => {
    callToJoinGameSocketOn(socketInitialisation);
  };

  const handleNewCallToJoinGame = (gameId: string): void => {
    const index: number = receivedNotifications.findIndex(
      (game) => game === gameId
    );

    if (index !== -1) {
      return;
    }

    receivedNotifications.push(gameId);
  };

  const joinRoomSpectatorSocketEmit = (payload: IJoinRoomSpectator ) => {
    socket?.emit("joinRoomSpectator", payload );
  }

  const removeCallFromReceivedNotificationsList = (gameId: string): void => {
    const index: number = receivedNotifications.findIndex(
      (game) => game === gameId
    );

    if (index === -1) {
      return;
    }

    receivedNotifications.splice(index, 1);
  };
  const providerValue = {
    socket,
    facilitatorUrl,
    registerFacilitator,
    removeCallFromReceivedNotificationsList,
    joinRoomSpectatorSocketEmit
  };

  return (
    <SocketContext.Provider value={providerValue}>
      {props.children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketContextProvider };
