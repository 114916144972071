import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ComponentsContext, MatchContext } from "../../../contexts";
import yellowPlus from "../../../assets/icons/yellowPlus.svg";
import { MatchItem } from "../..";

interface IMatches {
    preActiveMatches: any
}

const Matches: FC<IMatches> = ({preActiveMatches}) => {
    const { t } = useTranslation();
    const { setToggleModalValue, setMatchPopUpType } = useContext(ComponentsContext);
    const { numberOfTablesUserPosseses } = useContext(MatchContext);

    const openModalForCreatingNewMatches = () => {
        if (!numberOfTablesUserPosseses) {
            alert(t("weAreSorryButYouhave0TablesToSpendText"));
            return;
        }
        
        setMatchPopUpType({"modalType":"createMatch"});
        setToggleModalValue(true);
    }

    const renderMatchesList = () => {
        return (
            <div className="matches">
                {preActiveMatches.map( (preActiveMatch: any, index: number) => {
                    return(
                        <div key={index} className="matches_one_match">
                            <MatchItem preActiveMatch={preActiveMatch} testGameUrls={preActiveMatch.matchLinks} isActiveMatch={true} />
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderLoader = () => {
        return (
            <div style={{margin: "50px 5px"}}> 
                You have not created a single table at the moment. To create one please do press on yellow plus or <b>"Create New Match"</b> to create new matches.
            </div>
        )
    }

    return (
        <div className="matches_wrapper" style={{width: "100%"}}>
           
            <div className="matches_create_new_match_button_wrapper full_center" > 
                <img className="cursor_pointer create_new_match_plus_image" src={yellowPlus} alt="plus" onClick={() => openModalForCreatingNewMatches() } />
                <div className="underline cursor_pointer" onClick={() => openModalForCreatingNewMatches() }> {t("createNewMatchText")} </div> 
            </div>

            {preActiveMatches?.length ? renderMatchesList() : renderLoader()}
            
        </div>
    )
}

export default Matches;