import { FC, useContext } from "react";
import  {useTranslation } from "react-i18next";
import { ComponentsContext, StripeContext } from "../../../contexts";
import { ButtonAnimationUp } from "../../Atoms";

interface IPurchaseConfirmationModal {
    confirmPurchase(): void;
}

const PurchaseConfirmationModal: FC<IPurchaseConfirmationModal> = ({confirmPurchase}) => {
    const { setToggleModalValue } = useContext(ComponentsContext);
    const { selectedNumberOfGames } = useContext(StripeContext);
    const { t} = useTranslation();
    const informationTitle = t("youAreAboutToPurchaseText") + " " + selectedNumberOfGames + t("tablesSmallText") + ". " + t("pleaseConfirmText") + ".";

    return (
        <div className="create_new_match_modal padding_10">

            <div className="create_new_match_modal_title text_align_center"> {informationTitle} </div>

            <div className="create_new_match_button_holder">
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("confirmText")} functionality={() => confirmPurchase()} />
                </div>
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={ t("cancelText")} functionality={() => setToggleModalValue(false)}  />
                </div>
            </div>

        </div>
    )
}

export default PurchaseConfirmationModal;