import React, { FC, createContext, useState, useContext } from "react";
import axios from 'axios';
import { AuthDataContainerContext, UserDataContext } from ".";
import { ProductType } from "../enums";

interface IReceivedData {
    id: string;
    price: number;
    regularDiscount: number;
    friendDiscount: number;
}
interface IStripeContext {
    isStripeCreditCardModalOpen: boolean; 
    isStripeMatchUrlModalOpen: boolean;
    selectedNumberOfGames: number, 
    purchaseToken: any, 
    numberOfTablesUserPosseses: Number
    tablePurchaseIsLoading: boolean;
    enteredCouponName: string, 
    setIsStripeCreditCardModalOpen(isStripeCreditCardModalOpen: boolean): void;
    setisStripeMatchUrlModalOpen(isStripeMatchUrlModalOpen: boolean): void;
    setSelectedNumberOfGames(selectedNumberOfGames: number): void;
    setPurchaseToken: (purchaseToken: any) => void;
    setTablePurchaseIsLoading(tablePurchaseIsLoading: boolean): void;
    setEnteredCouponName(enteredCouponName: string): void;
    receivedData: IReceivedData;
    
    addNewCard: (cardId: string) => Promise<void>;
    adminPurchaseTablesEndPoint(userEmail : string, count : number): void;
    purchaseTablesEndPoint(userEmail : string, count : number): void;
    getProductsEndPoint(): void;
};

const StripeContext = createContext({} as IStripeContext);
const deployUrl = 'https://1eocnxysy2.execute-api.us-west-2.amazonaws.com/api';

interface StripeContextProps {
    children: React.ReactNode;
};

const StripeContextProvider: FC<StripeContextProps> = (props) => {
    const { creditCardToken, token } = useContext(AuthDataContainerContext);
    const { userData } = useContext(UserDataContext);
    const [isStripeCreditCardModalOpen, setIsStripeCreditCardModalOpen] = useState<boolean>(false);
    const [isStripeMatchUrlModalOpen, setisStripeMatchUrlModalOpen] = useState<boolean>(false);
    const [selectedNumberOfGames, setSelectedNumberOfGames] = useState<number>(0);
    const [purchaseToken, setPurchaseToken] = useState<any>();
    const [numberOfTablesUserPosseses, setNumberOfTablesUserPosseses] = useState<number>(0);
    const [tablePurchaseIsLoading, setTablePurchaseIsLoading] = useState<boolean>(false);
    const [enteredCouponName, setEnteredCouponName] = useState<string>("");
    const [receivedData, setReceivedData] = useState<IReceivedData>({
        id: "0",
        price: 150,
        regularDiscount: 0,
        friendDiscount: 0
    });
    const userSessionToken = token;
    const currentUserEmail: string = userData?.email || "";

    const axiosInstance = axios.create({
        baseURL: deployUrl,
        headers: {
          Authorization: 'Bearer '.concat(token),
          'Content-Type': 'application/json',
        },
        validateStatus: () => true,
    });

    const addNewCard = async (card: string) => {
      return axiosInstance
        .post('/customer/addCard', { card })
        .then((response: any) => console.log('The new card:', response.data))
        .catch((error: any) =>
          console.error('ERROR in adding the new card:', error)
        );
    };

    const adminPurchaseTablesEndPoint = (userEmail : string, count : number) => {
        axios({
            method: "post",
            url: process.env.REACT_APP_BACKEND_URL +"tables/admin-purchase-tables",
            headers: {
                Authorization: userSessionToken
            },
            data: {userEmail, count},
        })
        .then((res) => {
            console.log("adminPurchaseTablesEndPoint: ", res.data);
        })
        .catch((err) => console.error("adminPurchaseTablesEndPoint: ", err));
    }

    const purchaseTablesEndPoint = (userEmail : string, count : number) => {
            axios({
                method: "post",
                url: process.env.REACT_APP_BACKEND_URL +"tables/purchase-tables",
                headers: {
                    Authorization: userSessionToken
                },
                data: {userEmail, count, creditCardToken},
			})
			.then((res) => {
                console.log("purchaseTablesEndPoint: ", res.data);
			})
			.catch((err) => console.error("purchaseTablesEndPoint: ", err));
    }

    const getProductsEndPoint = () => {
        axios({
            method: "get",
            url: process.env.REACT_APP_BACKEND_URL + "products/" + ProductType.GAME,
            headers: { 
                Authorization: token
            }
        })
        .then((res) => {
            setReceivedData({
                id: res.data._id,
                price: res.data.price,
                regularDiscount: res.data.regularDiscount || 0,
                friendDiscount: res.data.friendDiscount || 0
            });
        })
        .catch((err) => console.error(err));
    }

    
    const providerValue = {
        isStripeCreditCardModalOpen, 
        isStripeMatchUrlModalOpen, 
        selectedNumberOfGames, 
        purchaseToken, 
        numberOfTablesUserPosseses,
        tablePurchaseIsLoading, 
        enteredCouponName, 
        setIsStripeCreditCardModalOpen,
        setisStripeMatchUrlModalOpen,
        setSelectedNumberOfGames,
        setPurchaseToken,
        setTablePurchaseIsLoading,
        setEnteredCouponName,
        receivedData,
        
        addNewCard,
        adminPurchaseTablesEndPoint,
        purchaseTablesEndPoint,
        getProductsEndPoint
    };

    return (
        <StripeContext.Provider value={providerValue}>
            {props.children}
        </StripeContext.Provider>
    );
};

export { StripeContext, StripeContextProvider };