import { FC } from "react";

interface IButtonAnimationUp {
    text: string;
    functionality(): void; 
}

const ButtonAnimationUp: FC<IButtonAnimationUp> = ({text, functionality}) => {


    return (
        <div className="button_animation_up_wrapper full_center" onClick={() => functionality()} >
            <div className="button_animation_up_background"></div>
            <div className="button_animation_up_text"> {text} </div>
        </div>
    )
}

export default ButtonAnimationUp;