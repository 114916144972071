import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { ComponentsContext } from "../../../contexts";
import { ButtonAnimationUp } from "../../Atoms";

interface ICreateMatchWarning {
    warningText: string;
}

const CreateMatchWarning: FC<ICreateMatchWarning> = ({warningText}) => {
    const { setToggleModalValue } = useContext(ComponentsContext);
    const { t } = useTranslation();
    
    return (
        <div className="create_match_warning">
            <div className="create_match_warning_body">
                {warningText}
            </div>
            <div className="create_new_match_button_wrapper">
                <ButtonAnimationUp text={t("okText")} functionality={() => setToggleModalValue(false)}  />
            </div>
        </div>      
    )
}

export default CreateMatchWarning;