import { FC } from "react";
import { useTranslation } from "react-i18next";
import MatchItem from "../Match/Matches/MatchItem";

interface IHistory {
	finishedMatches: any
}

const History: FC<IHistory> = ({finishedMatches}) => {
    const { t } = useTranslation();
    const urlDemo = t("seeTheStatisticsText");
	const historyMatchesGameUrls = [urlDemo, urlDemo, urlDemo, urlDemo, urlDemo, urlDemo];
    
    return (
        <div className="history_wrapper">

			<div className="history_matches">
                {finishedMatches.map( (historyMatches: any, index: number) => {
                    return(
                        <div key={index} className="matches_one_match">
                            <MatchItem preActiveMatch={historyMatches} testGameUrls={historyMatchesGameUrls} isActiveMatch={false} />
                        </div>
                    )
                })}
            </div>
            {/* <FinishedGames index={1} value={1} /> */}
        </div>
    )
}

export default History;