import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { CouponContext, StripeContext } from "../../../contexts";
import { InputText, RegularInput } from "../../Atoms";
import matchesIcon from "../../../assets/icons/matchesIcon.svg";
import editIcon from "../../../assets/icons/editIcon.svg";
import { InputFieldsType } from "../../../util/enums/InputFieldsType";

const PurchaseTypeBox = () => {
    const { t } = useTranslation();
    const { setSelectedNumberOfGames, receivedData, setEnteredCouponName } = useContext(StripeContext);
    const { coupons, getAllCoupons } = useContext(CouponContext); ///
    const [numberOfTables, setNumberOfTables] = useState<number>(0);
    const gamePrice = receivedData.price;
    const numberOfTablesRef = useRef<string>();
    const maxNumberOfGames = 50;
    let percentage = useRef<number>(1);

    useEffect(() => {
        getAllCoupons();
    }, [])
    
    const getTotalPrice = (): number => {
        return Math.round(numberOfTables*gamePrice*percentage.current);
    }

    const updateEnteredValue = (enteredValue: any) => {
        if (enteredValue > maxNumberOfGames) {
            updateLocalAndGlobalNumberOfTablesState(maxNumberOfGames);
            return;
        }

        updateLocalAndGlobalNumberOfTablesState(enteredValue);
    }
    
    const updateLocalAndGlobalNumberOfTablesState = (numberOfTables: number) => {
        setNumberOfTables(numberOfTables)
        setSelectedNumberOfGames(numberOfTables)
    }

    const setEnteredCouponValue = (couponCode: string) => {
        setEnteredCouponName(couponCode);

        if (!couponCode) {
            percentage.current = 1;
            return;
        }

        const isCouponExistingInDataBase = coupons?.find( (coupon: any) => coupon.code === couponCode);;
        if (!isCouponExistingInDataBase) {
            percentage.current = 1;  
            return;
        }

        const discountValue = isCouponExistingInDataBase?.discount;
        const calculatedDiscount = 1 - discountValue;
        const roundedCalculatedcDiscount = calculatedDiscount.toFixed(2);
        const calculatedDiscountNumberType = Number(roundedCalculatedcDiscount);
       
        percentage.current = calculatedDiscountNumberType;
    }


    return (
        <div className="purchase_type_box_wrapper">
            
            <div className="purchase_input_row_wrapper">
                <div className="input_field_holder">
                    <RegularInput 
                        icon={matchesIcon} 
                        placeholder={t("numberOfTables")} 
                        inputType="number" 
                        takeInputValue={(event: any) => updateEnteredValue(event?.target?.value || 0)} 
                        refForCounter={numberOfTablesRef} 
                        disabled={true}
                        counter={true} 
                        upDownArrows={true}
                        incrementType="number"
                        initialValue={1}
                        setCurrentlyInsertedNumber={updateEnteredValue}
                    />
                </div>
                <div className="purchase_grey_text"> ${gamePrice} /{t("perTableText")} </div>
            </div>

            <div className="purchase_input_row_wrapper">
                <div className="input_fiels_purchase_coupon_wrapper">
                    <InputText icon={editIcon} placeholder={t("enterCouponText")} setCouponName={setEnteredCouponValue} type={InputFieldsType.CREATECOUPON} />
                </div>
                <div>
                    <div className="purchase_grey_text"> {t("totalText")}:  </div>    
                    <div className="purchase_price_style"> ${getTotalPrice()} </div>    
                </div> 
            </div>

            <div className="purchase_line"></div>
            
        </div>
    );
};

export default PurchaseTypeBox;
