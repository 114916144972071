import { FC } from "react";
import ListAllCardsByTypeHeader from "./ListAllCardsByTypeHeader";
import CardsPanel from "../CardsPanel/CardsPanel";

const ListAllCardByType: FC<{}> = () => {
  return (
    <div className="list_all_cards_by_type_wrapper">
      <ListAllCardsByTypeHeader />
      <CardsPanel />
    </div>
  );
};

export default ListAllCardByType;

