import { FC,  useState } from "react";
import tablesIcon from "../../../assets/icons/tablesIcon.svg";
import toggleDown from "../../../assets/icons/toggleDown.svg";
import toggleUp from "../../../assets/icons/toggleUp.svg";

interface IDropDownInput {
    id: number,
    label: string
}

interface IDropDown {
    placeHolderText: string,
    dropDownItems: IDropDownInput[],
    selectedItem: any;
    setSelectedItem(selectedItem: any): void;
}

const DropDown: FC<IDropDown> = ({ placeHolderText, dropDownItems, selectedItem, setSelectedItem}) => {
    const [isOpen, setIsOpen] = useState(false);
    
    
    const toggleDropdown = () => setIsOpen(!isOpen);
    
    const handleItemClick = (item: any) => {
       setSelectedItem(item);
       toggleDropdown();
    }

    return (
        <div className="drop_down_input_wrapper">
            <div className="icon_wrapper"> 
                <img src={tablesIcon} alt="tablesIcon" />
            </div>
            <div className='dropdown'>
                <div className='dropdown_header' onClick={toggleDropdown}>
                    {selectedItem ? selectedItem?.label : placeHolderText}
                    {isOpen ?  <img src={toggleUp} alt="Up" /> : <img src={toggleDown} alt="Down" /> }
                </div>
                <div className={`dropdown_body ${isOpen && 'open'}`}>
                    {dropDownItems.map(item => (
                    <div className="dropdown-item" onClick={ () => handleItemClick(item)} id={item.id.toString()}>
                        <span className={`dropdown-item-dot ${item.id === selectedItem.id && 'selected'}`}> • </span>
                        <span className="padding_left"> {item.label} </span>
                    </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default DropDown;