import { FC, useContext, useState } from "react";
import { CouponContext } from "../../contexts";
import EditCouponDropDown from "./EditCouponDropDown";

interface IRenderAllCoupons {
    allCoupons: any[];
}

const RenderAllCoupons: FC<IRenderAllCoupons> = ({allCoupons}) => {
    const {setSelectedCoupon} = useContext(CouponContext);
    const [selectedItemId, setSelectedItemId] = useState<number>(0);
    
    
    const showPopUp = (index: number) => {
        setSelectedItemId(index)
        // setToggleModalValue(true);
    }

    const getZentIndex = (index: number) => {
        if (index === selectedItemId) return 5;
        return 1;
    }

    const getDiscountPercentage = (decimal: number) => {
        if (decimal) return decimal*100;
            return 0;
    }

    return(
        <div className="render_all_coupons_wrapper">
            <div className="render_coupons_header" >
                <div className="couopn_item_column" > <b> Coupon name </b> </div>
                <div className="couopn_item_column" > <b> Discount </b> </div>
                <div className="couopn_item_column" > <b> Code </b> </div>
                <div className="couopn_item_column" > <b> Users </b> </div>
                <div className="couopn_item_column" > <b> Status </b> </div>
                <div className="coupon_edit_dots" > </div>
            </div>
            {allCoupons.map( (coupon: any, index: number) => {
                return (
                    <div className="render_coupons_body" onClick={() => setSelectedCoupon(coupon) } key={index}>
                        <div className="couopn_item_column" >{coupon?.code} </div>
                        <div className="couopn_item_column" >{getDiscountPercentage(coupon?.discount)}% </div>
                        <div className="couopn_item_column" >{coupon?.code} </div>
                        <div className="couopn_item_column" >{coupon?.timesUsed || 0} </div>
                        <div className="couopn_item_column" >{coupon?.isActive ? <div> ACTIVE </div> : <div> INACTIVE </div>} </div>
                        <div className="coupon_edit_dots" style={{zIndex: getZentIndex(index) }} onClick={() => showPopUp(index)}> 
                            <EditCouponDropDown selectedItem={coupon}/>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default RenderAllCoupons;