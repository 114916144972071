import { FC, useContext, useState } from "react"
import { CouponContext } from "../../../contexts";
import { InputFieldsType } from "../../../util/enums/InputFieldsType";

interface IInputText {
    icon: any,
    placeholder: any,
    setCouponName(couponName: any): any,
    type: InputFieldsType,
}


const InputText: FC<IInputText> = (props) => {
    const { selectedCoupon } = useContext(CouponContext);
    const { icon, placeholder, setCouponName, type } = props; 
    const editCouponCode: string = selectedCoupon?.code || "";

    const initialValueDic = {
        "EDITCOUPON": editCouponCode,
        "CREATECOUPON": ""
    }

    const maxNumberOfCharactersDic = {
        "EDITCOUPON": 7,
        "CREATECOUPON": 7
    }

    const [value, setValue] = useState<any>({val: initialValueDic[type], focused: false});

    const updateValue = (e: any) => {
        const enteredValue = e.target.value;
        setValue({
            val: enteredValue,
            focused: value.focused
        });
    }

    const onBlurSetValues = (e: any) => {
        setValue({ val: e.target.value, focused: false});
        setCouponName(value.val)
    }

    return (
        <div className="input_text_wrapper">
            {icon && <div className="full_center position_icon"> <img src={icon} alt="home" /> </div>}
            <input
                type="text"
                defaultValue={initialValueDic[type]}
                placeholder={placeholder}

                onChange={(e: any) => updateValue(e)}
                onFocus={(e: any) => setValue({ val: e.target.value, focused: true })}
                onBlur={(e: any) => onBlurSetValues(e)}

                maxLength={maxNumberOfCharactersDic[type]}

            />
        </div>
    )
}

export default InputText
