import { FC, useContext, useState } from "react";
import { ComponentsContext, UserDataContext } from "../../../contexts";
import { IUser } from "../../../interfaces/index";
import SocialIcon from "../../../assets/icons/social.svg";
import RemoveIcon from "../../../assets/icons/DeleteGrey.svg";
import Flags from "../../MyProfile/Helpers/Flags";
import ChangePasswordForm from "../ChangePasswordForm/ChangePasswordForm";

interface IRenderOneUser {
  user: IUser | undefined;
  setOpenUpdateFacilitator: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDeleteFacilitator: React.Dispatch<React.SetStateAction<boolean>>;
  makeDarkBackground: () => void;
}
const RenderOneUser: FC<IRenderOneUser> = ({
  user,
  setOpenUpdateFacilitator,
  setOpenDeleteFacilitator,
  makeDarkBackground,
}) => {
  const { userData, selectedUser, setSelectedUser } =
    useContext(UserDataContext);
  const [toggleModalValue, setToggleModalValue] = useState(false);
  let linkedIn = undefined;
  if (user?.linkedin) {
    linkedIn = `https://${user?.linkedin}`;
  }

  if (!user) return <div></div>;

  const makeDarkBackgroundFromInviteFacilitator = () => {
    const darkBackground: any = document.getElementById("dark_background");
    darkBackground.style.visibility = "visible";

    setToggleModalValue(!toggleModalValue);
  };

  const printTheSelectedUser = (user: IUser) => {
    setSelectedUser(user);
  };

  const openDeleteModal = () => {
    setOpenDeleteFacilitator(true);
    makeDarkBackground();
  };

  return (
    <div
      style={{ display: "flex" }}
      onClick={() => printTheSelectedUser(user)}
      onDoubleClick={makeDarkBackgroundFromInviteFacilitator}
      className={
        user?._id === selectedUser?._id
          ? "item_container selected_user"
          : "item_container"
      }
    >
      {toggleModalValue && userData?.isAdmin && (
        <ChangePasswordForm
          setOpenChangePassword={setToggleModalValue}
          usersEmail={user.email}
        />
      )}

      <div
        className={
          userData?.isAdmin ? "item_type_one" : "item_type_one_facilitator_view"
        }
      >
        {" "}
        {user?.firstName} {user?.lastName}{" "}
      </div>
      <div
        className={
          userData?.isAdmin ? "item_type_one" : "item_type_one_facilitator_view"
        }
      >
        {" "}
        {user?.email}{" "}
      </div>
      {userData?.isAdmin && (
        <div className={"item_type_three"}> {user?.purchasedTables} </div>
      )}
      {userData?.isAdmin && (
        <div className={"item_type_three"}> {user?.usedTables} </div>
      )}
      <div className="item_type_two">
        <a
          target="_blank"
          href={
            user?.linkedin?.includes("www") ||
            user?.linkedin?.includes("https://")
              ? user?.linkedin
              : linkedIn
          }
        >
          <img
            style={{ cursor: "pointer" }}
            src={SocialIcon}
            alt="social_icon"
          />
        </a>
      </div>

      <div className="item_type_two">
        {Flags.Countries.map(
          (country, index: number) =>
            country.title === user.country && (
              <div key={index}>
                <img className="icon-img" src={country.icon} alt="" />
                {country.title.slice(0, 3)}
              </div>
            )
        )}
      </div>

      {userData?.isAdmin && (
        <div className="item_type_two">
          <button
            style={{ border: "none", background: "transparent" }}
            onClick={openDeleteModal}
          >
            <img style={{ cursor: "pointer" }} src={RemoveIcon} alt="delete" />
          </button>
        </div>
      )}
    </div>
  );
};

export default RenderOneUser;
