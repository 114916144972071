import React, { FC } from "react";
import LogIn from "../../components/LogIn/LogIn";

const LogInPage: FC<{}> = () => {
  return (
    <React.Fragment>
      <LogIn />
    </React.Fragment>
  );
};

export default LogInPage;
