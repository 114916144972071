import React, { FC, createContext, useContext, useState } from "react";
import axios from "axios";

import { AuthDataContainerContext } from "./AuthDataContainer";
import { AxiosContext } from "./AxiosContextProvider";

interface IAuthContext {
	logIn: (email: string, password: string) => void;
	changePassword: (
		email: string,
		oldPassword: string,
		newPassword: string
	) => void;
	logInError: any;
	password: string;
	sendPasswordToEmail(email: string): void;
	}

const AuthContext = createContext({} as IAuthContext);

interface AuthContextProps {
children: React.ReactNode;
}

const AuthContextProvider: FC<AuthContextProps> = (props) => {
	const { setToken, setEmail } = useContext(AuthDataContainerContext);
	const [ password, setPassword ] = useState<string>("");
	const [ logInError, setLogInError ] = useState<any>();

	const { adminBackend } = useContext(AxiosContext);

	const logIn = (email: string, password: string) => {
		setPassword(password);

		axios({
			method: "post",
			url: process.env.REACT_APP_BACKEND_URL + "auth/login", 
			data: {
				email,
				password,
			}
		})
		.then((res) => {
			if (res.data) {
				localStorage.setItem("token", res.data.token);
				localStorage.setItem("email", res.data.email);
				
				fetchUserData(res.data.token);

				setEmail(res.data.email);
				setToken(res.data.token);
			}
		})
		.catch((err: any) => {
			setLogInError(err)
		});
	};

	const fetchUserData = (token: any) => {
		if (token)
			axios({
				method: "get",
				url: process.env.REACT_APP_BACKEND_URL + "users/current",
				headers: { 
					Authorization: token
				}
			})
			.then((res: any) => {
				console.log("ausers/current ", res.data);
			})
			.catch((err: any) => console.error(err));
	}

	const sendPasswordToEmail = (email: string) => {
			axios({
				method: "post",
				url: process.env.REACT_APP_BACKEND_URL + "auth/reset-password",
				data: {email}
			})
			.then((res: any) => {
				console.log("reset-password ", res);
			})
			.catch((err: any) => console.error(err));
	}

	const changePassword = (
		email: string,
		oldPassword: string,
		newPassword: string
	) => {
		adminBackend.post("auth/change-password", {
		email,
		oldPassword,
		newPassword,
		});
	};

	const providerValue = {
		logIn,
		changePassword,
		logInError,
		password,
		sendPasswordToEmail
	};

	return (
		<AuthContext.Provider value={providerValue}>
		{props.children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthContextProvider };
