import argentina from "../../../assets/icons/flags/argentina.svg";
import australia from "../../../assets/icons/flags/australia.svg";
import austria from "../../../assets/icons/flags/austria.svg";
import belgium from "../../../assets/icons/flags/belgium.svg";
import brazil from "../../../assets/icons/flags/brazil.svg";
import canada from "../../../assets/icons/flags/canada.svg";
import chile from "../../../assets/icons/flags/chile.svg";
import china from "../../../assets/icons/flags/china.svg";
import colombia from "../../../assets/icons/flags/colombia.svg";
import costaRica from "../../../assets/icons/flags/costaRica.svg";
import cyprus from "../../../assets/icons/flags/cyprus.svg";
import denmark from "../../../assets/icons/flags/denmark.svg";
import egypt from "../../../assets/icons/flags/egypt.svg";
import france from "../../../assets/icons/flags/france.svg";
import germany from "../../../assets/icons/flags/germany.svg";
import iceland from "../../../assets/icons/flags/iceland.svg";
import india from "../../../assets/icons/flags/india.svg";
import israel from "../../../assets/icons/flags/israel.svg";
import italy from "../../../assets/icons/flags/italy.svg";
import japan from "../../../assets/icons/flags/japan.svg";
import lebanon from "../../../assets/icons/flags/lebanon.svg";
import malaysia from "../../../assets/icons/flags/malaysia.svg";
import mexico from "../../../assets/icons/flags/mexico.svg";
import netherlands from "../../../assets/icons/flags/netherlands.svg";
import newZeland from "../../../assets/icons/flags/newZeland.svg";
import other from "../../../assets/icons/flags/other.svg";
import panama from "../../../assets/icons/flags/panama.svg";
import poland from "../../../assets/icons/flags/poland.svg";
import portugal from "../../../assets/icons/flags/portugal.svg";
import romania from "../../../assets/icons/flags/romania.svg";
import russia from "../../../assets/icons/flags/russia.svg";
import serbia from "../../../assets/icons/flags/serbia.svg";
import singapore from "../../../assets/icons/flags/singapore.svg";
import slovenia from "../../../assets/icons/flags/slovenia.svg";
import southAfrica from "../../../assets/icons/flags/southAfrica.svg";
import spain from "../../../assets/icons/flags/spain.svg";
import switzerland from "../../../assets/icons/flags/switzerland.svg";
import thailand from "../../../assets/icons/flags/thailand.svg";
import uk from "../../../assets/icons/flags/uk.svg";
import ukraine from "../../../assets/icons/flags/ukraine.svg";
import usa from "../../../assets/icons/flags/usa.svg";
import greece from "../../../assets/icons/flags/greece.svg";


const Flags =  {
    Countries: [
        {
            key: 0,
            title: "Argentina",
            icon: argentina
        },
        {
            key: 1,
            title: "Australia",
            icon: australia
        },
        {
            key: 2,
            title: "Austria",
            icon: austria
        },
        {
            key: 3,
            title: "Belgium",
            icon: belgium
        },
        {
            key: 4,
            title: "Brazil",
            icon: brazil
        },
        {
            key: 5,
            title: "Canada",
            icon: canada
        },
        {
            key: 6,
            title: "Chile",
            icon: chile
        },
        {
            key: 7,
            title: "China",
            icon: china
        },
        {
            key: 8,
            title: "Colombia",
            icon: colombia
        },
        {
            key: 9,
            title: "Costa",
            icon: costaRica
        },
        {
            key: 10,
            title: "Cyprus",
            icon: cyprus
        },
        {
            key: 11,
            title: "Denmark",
            icon: denmark
        },
        {
            key: 12,
            title: "Egypt",
            icon: egypt
        },
        {
            key: 13,
            title: "France",
            icon: france
        },
        {
            key: 14,
            title: "Germany",
            icon: germany
        },
        {
            key: 15,
            title: "Iceland",
            icon: iceland
        },
        {
            key: 16,
            title: "India",
            icon: india
        },
        {
            key: 17,
            title: "Israel",
            icon: israel
        },
        {
            key: 18,
            title: "Italy",
            icon: italy
        },
        {
            key: 19,
            title: "Japan",
            icon: japan
        },
        {
            key: 20,
            title: "Lebanon",
            icon: lebanon
        },
        {
            key: 21,
            title: "Malaysia",
            icon: malaysia
        },
        {
            key: 22,
            title: "Mexico",
            icon: mexico
        },
        {
            key: 23,
            title: "TheNetherlands",
            icon: netherlands
        },
        {
            key: 24,
            title: "NewZeland",
            icon: newZeland
        },
        {
            key: 25,
            title: "Other",
            icon: other
        },
        {
            key: 26,
            title: "Panama",
            icon: panama
        },
        {
            key: 27,
            title: "Poland",
            icon: poland
        },
        {
            key: 28,
            title: "Portugal",
            icon: portugal
        },
        {
            key: 29,
            title: "Romania",
            icon: romania
        },
        {
            key: 30,
            title: "Russia",
            icon: russia
        },
        {
            key: 31,
            title: "Serbia",
            icon: serbia
        },
        {
            key: 32,
            title: "Singapore",
            icon: singapore
        },
        {
            key: 33,
            title: "Slovenia",
            icon: slovenia
        },
        {
            key: 34,
            title: "SouthAfrica",
            icon: southAfrica
        },
        {
            key: 35,
            title: "Spain",
            icon: spain
        },
        {
            key: 36,
            title: "Switzerland",
            icon: switzerland
        },
        {
            key: 37,
            title: "Thailand",
            icon: thailand
        },
        {
            key: 38,
            title: "UK",
            icon: uk
        },
        {
            key: 39,
            title: "Ukraine",
            icon: ukraine
        },
        {
            key: 40,
            title: "USA",
            icon: usa
        },
        {
            key: 41,
            title: "Greece",
            icon: greece
        } 
    ]
}

export default Flags;