import { FC, useContext, useState } from "react";
import "./SpectatorButton.scss";
import { ComponentsContext } from "../../../contexts";
import editIcon from "../../../assets/icons/editIcon.svg";
import { useTranslation } from "react-i18next";
import { IMatchesObject } from "../../../interfaces";

interface IEditMatchButton {
  preActiveMatch: IMatchesObject;
  urlLink: string
}

const EditMatchButton: FC<IEditMatchButton> = ({ preActiveMatch, urlLink }) => {
  const { t } = useTranslation();
  const { setMatchPopUpType, setToggleModalValue, setSelectedGameUrlToken } = useContext(ComponentsContext);
  const [isEditHovered, setIsEditHovered] = useState(false);

  const urlPathChunks = urlLink.split("/");
	const urlTokenExtracted = urlPathChunks[urlPathChunks.length-1];

  const openModalForMatchEditing = () => {
    setMatchPopUpType({"modalType": "updateMatch", "matchData": preActiveMatch});
    setToggleModalValue(true);
    setSelectedGameUrlToken(urlTokenExtracted);
  };

  return (
    <div
      className="update_match_tooltip"
      onMouseEnter={() => setIsEditHovered(true)}
      onMouseLeave={() => setIsEditHovered(false)}
      onClick={() => openModalForMatchEditing()}
    >
      <img src={editIcon} alt="time" />
      <div className={isEditHovered ? "update_match_tooltip_hover" : "display_none"}> {t("editMatchText")} </div>
    </div>
  );
};

export default EditMatchButton;
