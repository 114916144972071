import { useContext, useRef, FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CardContext, CardContextNew, ComponentsContext, MatchContext, UserDataContext } from "../../../contexts";
import { ButtonAnimationUp, RegularInput } from "../../Atoms";
import { IMatchesObject } from "../../../interfaces";
import home from "../../../assets/icons/home.svg";
import radioIcon from "../../../assets/icons/radioIcon.svg";
import clockIcon from "../../../assets/icons/clockIcon.svg";
import tablesIcon from "../../../assets/icons/tablesIcon.svg";
import { IEnteredMatchData } from "../../../interfaces";
import { routeNamesDic } from "../../../interfaces/Dictionaries";

interface IMatchModal {
    preActiveMatch?: IMatchesObject,
}

const MatchModal: FC<IMatchModal> = ({preActiveMatch}) => {
    const { setToggleModalValue, selectedGameUrlToken } = useContext(ComponentsContext);
    const { userData } = useContext(UserDataContext);
    const { setQuestionCustomCardsNew, mapReceivedQuestionCards, setActionCustomCardsNew, mapReceivedActionCards } = useContext(CardContextNew);
    const { setEnteredMatchData, numberOfTablesUserPosseses } = useContext(MatchContext);
    const { getActionCards, getQuestionCards } = useContext(CardContext);
    const matchName = useRef<any>();
    const initialDuration = !preActiveMatch?.matchDuration ? 600000 : preActiveMatch?.matchDuration;
    const companyName = useRef<any>();
    const durationTime = useRef<any>();
    const numberOfTables = useRef<any>({value: 1});
    const { t } = useTranslation();
    let history = useHistory();
    

    useEffect(() => {
        if (preActiveMatch) {
            matchName.current.value = preActiveMatch.matchName;
            companyName.current.value = preActiveMatch.companyName;
            numberOfTables.current.value = preActiveMatch?.matchLinks.length;
        }
    }, [])

    const sidebarMenuItemNamesDic : any= {
        0: "Matches",
        1: "History",
        2: "Learning",
        3: "Facilitators",
        4: "Notifications",
        5: "Purchase Tables",
        6: "My Profile",
        7: "Matches/Card Deck"
    }

    const checkInsertedMatchData = () => {
        if ( matchName.current.value.length < 1) { 
            alert(t("excuseMeButTheFieldMatchNameIsEmptyText"));
            return; 
        }
        if ( companyName.current.value.length < 1) { 
            alert(t("excuseMeButTheFieldCompanyNameIsEmptyText")); 
            return; 
        }

        let matchNameFiltered = matchName.current.value.replace(/[^a-zA-Z0-9 _]/g, '');
        let companyNameFiltered = companyName.current.value.replace(/[^a-zA-Z0-9 _]/g, '');

        const enteredMatchDataLocal: IEnteredMatchData = {
            idForUpdate: preActiveMatch? selectedGameUrlToken : "",
            companyEmail: userData?.email || "",
            matchName: matchNameFiltered,
            companyName: companyNameFiltered,
            gameCount: parseInt(numberOfTables?.current?.value),
            matchDuration: durationTime.current.value || 1000
        };

        setEnteredMatchData(enteredMatchDataLocal);
        getActionCards();
		getQuestionCards();
        preActiveMatch && setQuestionCustomCardsNew(mapReceivedQuestionCards(preActiveMatch?.customQuestionCards));
        preActiveMatch && setActionCustomCardsNew(mapReceivedActionCards(preActiveMatch?.customActionCards));
        setToggleModalValue(false);

        const selectedRouteName =  routeNamesDic[sidebarMenuItemNamesDic[7]];
        if (history)
            history.push(selectedRouteName);
    }
    
    return (
        <div className="create_new_match_modal">
            {preActiveMatch ? 
                <div className="create_new_match_modal_title"> {t("updateMatchText")} </div>
                :
                <div className="create_new_match_modal_title"> {t("createNewMatchText")} </div>
            }
            <div className="input_field_holder">
                <RegularInput 
                    icon={radioIcon} 
                    placeholder={t("matchNameText")} 
                    inputType="text" 
                    takeInputValue={(data: any) => console.log(data)} 
                    refInput={matchName} />
            </div>
            <div className="input_field_holder">
                <RegularInput 
                    icon={home} 
                    placeholder={t("companyText")} 
                    inputType="text" 
                    takeInputValue={(data: any) => console.log(data)} 
                    refInput={companyName} />
            </div>
            <div className="input_field_holder">
                    <RegularInput 
                        icon={clockIcon} 
                        placeholder={t("durationText")} 
                        inputType="number" 
                        takeInputValue={(data: any) => console.log(data)} 
                        refInput={durationTime}
                        disabled={true}
                        clock={true} 
                        upDownArrows={true}
                        incrementType="time"
                        initialValue={initialDuration}
                    />
            </div>
            {/* Temp solution and removing ability to update number of tables */}
            {!preActiveMatch &&
            <div className="input_field_holder">
                <RegularInput 
                    icon={tablesIcon} 
                    placeholder={t("numberOfTablesText")}
                    inputType="number" 
                    takeInputValue={(data: any) => console.log(data)} 
                    refForCounter={numberOfTables} 
                    disabled={true}
                    counter={true} 
                    upDownArrows={true}
                    incrementType="number"
                    initialValue={1}
                    upperLimitValue={numberOfTablesUserPosseses}
                />
            </div>

            }
            <div className="input_filed_warning"> {t("youCurrentlyHaveText")} {numberOfTablesUserPosseses || 0} {t("tablesAvailableText")} </div>

            <div className="create_new_match_button_holder">
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("completeText")} functionality={() => checkInsertedMatchData()} />
                </div>
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("cancelText")} functionality={() => setToggleModalValue(false)}  />
                </div>
            </div>
        </div>
    )
}

export default MatchModal;