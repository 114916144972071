import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ComponentsContext, CouponContext } from "../../contexts";
import { ButtonAnimationUp, InputNumber, InputText } from "../Atoms";
import editIcon from "../../assets/icons/editIcon.svg";
import { CouponType } from "../../enums";
import { InputFieldsType } from "../../util/enums/InputFieldsType";


const CreateCoupnModal = () => {
    const { setToggleModalValue } = useContext(ComponentsContext);
    const { 
        setCoupon,
        postCreateDiscountCoupon,
        postCreateCompanyCoupon,
        postCreateReferalCoupon
    } = useContext(CouponContext);
    const { t } = useTranslation();
    let history = useHistory();

    const [couponName, setCouponName] = useState<string>("");
    const [couponDiscount, setCouponDiscount] = useState<number>(0);
    const [couponActivity, setCouponActivity] = useState<boolean>(false);
    const [couponType, setCouponType] = useState<CouponType>(CouponType.DISCOUNT);
    const discount: number = couponDiscount ? couponDiscount/100 : 0;

    const updateType = () => {
        
        const updatedCoupon = {
            code: couponName,
            discount: discount,
            isActive: couponActivity
        }

        if (couponType === CouponType.DISCOUNT) postCreateDiscountCoupon(updatedCoupon);
        if (couponType === CouponType.COMPANY) postCreateCompanyCoupon(updatedCoupon);
        if (couponType === CouponType.REFERAL) postCreateReferalCoupon(updatedCoupon);
    }
    
    const updateTimeOfTheCurrentMatch = () => {
        const updatedCoupon = {
            code: couponName,
            discount: discount,
            isActive: couponActivity
        }

        setCoupon(updatedCoupon);
        updateType();
        
        setToggleModalValue(false);

        if (history)
            history.push("/purchasetables/coupons");
    }

    const checkInput = (value: number) => {
        if (value > 100) {
            setCouponDiscount(100);
            return;
        }
        
        if (value < 0.5) {
            setCouponDiscount(0.5);
            return;
        }

        setCouponDiscount(value);
    }

    const referalButtonStyle = (type: string) => {
        if (type === couponType) return "coupon_referal_button background_0D062D";
        return "coupon_referal_button background_f5f5f5";
    }

    const activityButtonStyle = () => {
        if (couponActivity) return "coupon_referal_button background_0D062D";
        return "coupon_referal_button background_FF3434";
    }

    return (
        <div className="">

            <div className="create_new_match_modal_title extra_padding_10 text_align_center"> {t("createANewCouponText")} </div>

            <div className="order_in_line referal_type_button_margin"> 
                <button className={referalButtonStyle("DISCOUNT")} onClick={() => setCouponType(CouponType.DISCOUNT)}> {t("discountCouponText")} </button>
                <button className={referalButtonStyle("COMPANY")} onClick={() => setCouponType(CouponType.COMPANY)}> {t("companyText")} </button>
                <button className={referalButtonStyle("REFERAL")} onClick={() => setCouponType(CouponType.REFERAL)}> {t("referalCouponText")} </button>
            </div>

            <InputText icon={editIcon} placeholder={t("enterCouponText")} setCouponName={setCouponName} type={InputFieldsType.CREATECOUPON} />

            <InputNumber icon={editIcon} placeholder={t("enterCouponDiscount")} setCouponDiscount={checkInput} type={InputFieldsType.CREATECOUPON} />

            <div className="order_in_line referal_type_activity_button_margin"> 
                <button className={activityButtonStyle()} onClick={() => setCouponActivity(!couponActivity)}>  
                    {couponActivity ? t("activatedText") :t("deactivatedText")} 
                </button>
            </div>

            <div className="create_new_match_button_holder">
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("completeText")} functionality={() => updateTimeOfTheCurrentMatch()} />
                </div>
                <div className="create_new_match_button_wrapper">
                    <ButtonAnimationUp text={t("cancelText")} functionality={() => setToggleModalValue(false)}  />
                </div>
            </div>

        </div>
    )
}

export default CreateCoupnModal;