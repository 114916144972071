import {useTranslation} from "react-i18next";
import { SidebarMenuItem } from "..";
import matchesIcon from "../../assets/icons/matchesIcon.svg";
import matchesIconSelected from "../../assets/icons/matchesIconSelected.svg";
import calendarIcon from "../../assets/icons/calendarIcon.svg";
import calendarIconSelected from "../../assets/icons/calendarIconSelected.svg";
import starIcon from "../../assets/icons/starIcon.svg";
import starIconSelected from "../../assets/icons/starIconSelected.svg";
import facilitatorsIcon from "../../assets/icons/facilitatorsIcon.svg";
import facilitatorsIconSelected from "../../assets/icons/facilitatorsIconSelected.svg";
import notificationsIcon from "../../assets/icons/notificationsIcon.svg";
import notificationsIconSelected from "../../assets/icons/notificationsIconSelected.svg";
import walletIcon from "../../assets/icons/walletIcon.svg";
import walletIconSelected from "../../assets/icons/walletIconSelected.svg";
import myProfileIcon from "../../assets/icons/myProfileIcon.svg";
import myProfileIconSelected from "../../assets/icons/myProfileIconSelected.svg";

const SidebarMenu = () => {
    const {t} = useTranslation();
    const sidebarMenuItems: string[] = [ 
        t('matchesText'),
        t('historyText'),
        t('learningText'),
        t('FacilitatorText'),
        t('notificationText'),
        t('purchaseTablesText'),
        t('myProfileText')
    ];

    const iconDic: any = {
        0: matchesIcon,
        1: calendarIcon,
        2: starIcon,
        3: facilitatorsIcon,
        4: notificationsIcon,
        5: walletIcon,
        6: myProfileIcon
    }

    const iconSelectedDic: any = {
        0: matchesIconSelected,
        1: calendarIconSelected,
        2: starIconSelected,
        3: facilitatorsIconSelected,
        4: notificationsIconSelected,
        5: walletIconSelected,
        6: myProfileIconSelected
    }

    const isLeftIconShown: any = {
        0: true,
        1: false,
        2: true,
        3: true,
        4: false,
        5: false,
        6: false
    }

    const sidebarMenuItemNamesDic : any= {
        0: t("matchesText"),
        1: t("historyText"),
        2: t("learningText"),
        3: t("FacilitatorText"),
        4: t("notificationText"),
        5: t("purchaseTablesText"),
        6: t("myProfileText"),
        7: t("matchesCardDeckText")
    }

    return (
        <div className="sidebar_menu_wrapper"> 
            {sidebarMenuItems.map( (itemName: string, index: number) => {
                return  (
                    <div key={index}>
                        <SidebarMenuItem icon={iconDic[index]} iconSelected={iconSelectedDic[index]} index={index} itemName={itemName} isLeftIconShown={isLeftIconShown[index]} sidebarMenuItemName={sidebarMenuItemNamesDic[index]} />
                    </div>
            )})}
        </div>
    )
}

export default SidebarMenu;