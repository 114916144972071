import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import messageIcon from "../../../assets/icons/messageIcon.svg";
import { CardContextNew, ComponentsContext } from "../../../contexts";
import { RegularInput } from "../../Atoms";
import tablesIcon from "../../../assets/icons/tablesIcon.svg";

interface IUpdateCustomQuestionCard {
    updatedQuestionValue: any;
    questionCardsUpperLimit: number;
    updateQuestionCardOrderNumber: any;
}

const UpdateCustomQuestionCard: FC<IUpdateCustomQuestionCard> = ({updatedQuestionValue, updateQuestionCardOrderNumber, questionCardsUpperLimit}) => {
    const { selectedQuestionCardForEdit } = useContext(CardContextNew);
    const { popUpBoxType } = useContext(ComponentsContext);
    const { t } = useTranslation();

    const upperLimitReachedText = t("youCannotAddACardWithGreaterOrderText") + `${questionCardsUpperLimit}`

    const [value, setValue] = useState<string>(selectedQuestionCardForEdit?.description || "");
    const currentOrder: number = selectedQuestionCardForEdit?.order || 1;

    const onChange = (e: any) => {
        setValue(e.target.value);
    }
 
    if ((popUpBoxType === "editQuestion" || popUpBoxType === "editSelectedMatchQuestion") && selectedQuestionCardForEdit)
        return (
            <div>
                <div className="textarea_field_holder">
                    <div className="textarea_field_wrapper">
                        <div className="textarea_field_icon"> <img src={messageIcon} alt="home" /></div>
                        <div className="textarea_field_text left_center"> 
                            <textarea
                                value={value}
                                onChange={(event) => onChange(event)}
                                placeholder={t("addDescriptionText")}
                                cols={30}
                                rows={1} 
                                onBlur={() => updatedQuestionValue.current = value } 
                                maxLength={140} 
                            />
                        </div>
                    </div>
                </div>
                <div className="input_field_holder">
                    <RegularInput 
                        icon={tablesIcon} 
                        placeholder={t("newCardOrderNumberText")}
                        inputType="number" 
                        takeInputValue={(data) => console.log(data)} 
                        refForCounter={updateQuestionCardOrderNumber} 
                        disabled={true}
                        counter={true} 
                        upDownArrows={true}
                        incrementType="number"
                        initialValue={currentOrder}
                        upperLimitValue={questionCardsUpperLimit}
                        upperLimitCustomAlertText={upperLimitReachedText}
                    />
                </div>
            </div>
        )

    return <div></div>
}

export default UpdateCustomQuestionCard;