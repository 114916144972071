import { FC, useContext, useEffect, useState } from "react";
import toggleUp from "../../assets/icons/toggleUp.svg";
import toggleDown from "../../assets/icons/toggleDown.svg";
import { ComponentsContext } from "../../contexts";
import LogOutDropDownBox from "./LogOutDropDownBox";
import LanguageDropDownBox from "./LanguageDropDownBox";

type dropDownType = "languages" | "logOut";

interface IHeaderMenuDropDownBox {
    typeOfDropDown: dropDownType
}

const HeaderMenuDropDownBox : FC<IHeaderMenuDropDownBox> = ({typeOfDropDown}) => {
    const { isAnyDropDownBoxOpen, setIsAnyDropDownBoxOpen } = useContext(ComponentsContext);
    const [currentArrowImage, setCurrentArrowImage] = useState<any>(toggleDown);
    
    useEffect(() => {
        const toggleIconDic = {
            "up": toggleUp,
            "down": toggleDown
        }

        if(isAnyDropDownBoxOpen[typeOfDropDown])
            setCurrentArrowImage(toggleIconDic["up"]);

         if(!isAnyDropDownBoxOpen[typeOfDropDown])
            setCurrentArrowImage(toggleIconDic["down"]);  
    }, [isAnyDropDownBoxOpen, typeOfDropDown]);

    const toggleTheArrow = () => {
        if (typeOfDropDown === "languages" && isAnyDropDownBoxOpen["languages"])
            setIsAnyDropDownBoxOpen({"logOut": false, "languages": false})
        if (typeOfDropDown === "languages" && !isAnyDropDownBoxOpen["languages"])
            setIsAnyDropDownBoxOpen({"logOut": false, "languages": true})
        if (typeOfDropDown === "logOut" && isAnyDropDownBoxOpen["logOut"])
            setIsAnyDropDownBoxOpen({"logOut": false, "languages": false})
        if (typeOfDropDown === "logOut" && !isAnyDropDownBoxOpen["logOut"])
            setIsAnyDropDownBoxOpen({"logOut": true, "languages": false})
    }
    
    const renderDropDown = () => {
        if (isAnyDropDownBoxOpen[typeOfDropDown])
            return dropDownTypeDic[typeOfDropDown];
        return <div></div>
    }

    const dropDownTypeDic = {
        "languages": <LanguageDropDownBox />,
        "logOut": <LogOutDropDownBox />
    }

    return (
        <div className="header_menu_drop_down_box_wrapper" style={{cursor: "pointer"}}>
            <img src={currentArrowImage} alt="up" onClick={() => toggleTheArrow()} />
            {renderDropDown()}
        </div>      
    )
}

export default HeaderMenuDropDownBox;