import React, { FC, createContext, useState } from "react";
import {useTranslation} from "react-i18next";

interface IIsTheDropDownBoxOpen {
    "languages": boolean,
    "logOut": boolean,
}

interface IModalData {
	"modalType": "createMatch" | "updateTime" | "updateMatch";
	"matchData"?: any
}

type TMatchPopUpType = "createMatch" | "updateTime" | "updateMatch";
type TPaymentPopUpType = "createCoupon" | "editCoupon";
type TPopUpBox = "maxNumWarning" | "minNumWarning" | "createNewQuestionCard" | "createNewActionCard" | "editQuestion" | "editAction" | "editSelectedMatchQuestion" | "editSelectedMatchAction";

interface IComponentsContext {
    isAnyDropDownBoxOpen: IIsTheDropDownBoxOpen;
	selectedLanguage: string;
	currentlySelectedScreen: string;
	toggleModalValue: boolean;
	popUpBoxType: TPopUpBox; 
	notificationText: string;
	isNotificationActivated: boolean;
	matchPopUpType: IModalData;
	selectedGameUrlToken: string, 
	paymentPopUpType: TPaymentPopUpType,
    setIsAnyDropDownBoxOpen(isAnyDropDownBoxOpen: IIsTheDropDownBoxOpen): void;
	setSelectedLanguage(selectedLanguage: string): void;
	setCurrentlySelectedScreen(currentlySelectedScreen: string): void;
	setToggleModalValue(toggleModalValue: boolean): void;
	setPopUpBoxType(popUpBoxType: TPopUpBox): void;
	setNotificationText(notificationText: string): void;
	setIsNotificationActivated(isNotificationActivated: boolean): void;
	setMatchPopUpType(matchPopUpType: IModalData): void;
	setSelectedGameUrlToken(setSelectedGameUrlToken: string): void;
	setPaymentPopUpType(paymentPopUpType: TPaymentPopUpType): void;
}

const ComponentsContext = createContext({} as IComponentsContext);

interface AuthContextProps {
	children: React.ReactNode;
}

const ComponentsContextProvider: FC<AuthContextProps> = (props) => {
	const {t} = useTranslation();
    const [isAnyDropDownBoxOpen, setIsAnyDropDownBoxOpen] = useState<IIsTheDropDownBoxOpen>({
        "languages": false,
        "logOut": false
    });
	const [selectedLanguage, setSelectedLanguage] = useState<string>("English");
	const [currentlySelectedScreen, setCurrentlySelectedScreen] = useState<string>(t('matchesText'));
	const [toggleModalValue, setToggleModalValue] = useState<boolean>(false);
	const [popUpBoxType, setPopUpBoxType] = useState<TPopUpBox>("createNewQuestionCard");
	const [notificationText, setNotificationText] = useState<string>("");
	const [isNotificationActivated, setIsNotificationActivated] = useState<boolean>(false);
	const [matchPopUpType, setMatchPopUpType] = useState<IModalData>({
		"modalType":"createMatch",
		"matchData":[]
	});
	const [paymentPopUpType, setPaymentPopUpType] = useState<TPaymentPopUpType>("createCoupon");
	const [selectedGameUrlToken, setSelectedGameUrlToken] = useState<string>("");

	const providerValue = {
        isAnyDropDownBoxOpen, 
		selectedLanguage, 
		currentlySelectedScreen, 
		toggleModalValue,
		popUpBoxType, 
		notificationText,
		isNotificationActivated,
		matchPopUpType, 
		selectedGameUrlToken, 
		paymentPopUpType, 
        setIsAnyDropDownBoxOpen,
		setSelectedLanguage,
		setCurrentlySelectedScreen,
		setToggleModalValue,
		setPopUpBoxType,
		setNotificationText,
		setIsNotificationActivated,
		setMatchPopUpType,
		setSelectedGameUrlToken,
		setPaymentPopUpType
	};

	return (
		<ComponentsContext.Provider value={providerValue}>
			{props.children}
		</ComponentsContext.Provider>
	);
};

export { ComponentsContext, ComponentsContextProvider };
