import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ButtonAnimationUp } from "../../components/Atoms";
import { CouponContext } from "../../contexts";
import editIcon from "../../assets/icons/editIcon.svg";

const TableManagement = () => {
    const { addTablesToUser } = useContext(CouponContext); 

    const [userEmail, setUserEmail] = useState('');
    const [numberOfTables, setNumberOfTables] = useState(0);

    let history = useHistory();
    const { t } = useTranslation();

    const goBack = () => {
        if (history)
            history.push("/purchasetables");
    }

    const handleSubmit = () => {
        if (numberOfTables <= 0) {
          alert('Please enter a positive number of tables.');
          return;
        }

        addTablesToUser(userEmail, numberOfTables);
    };

    return (
        <div className="coupons_page_wrapper custom_scrollbar custom_made_scrollbar">

            <div className="">
                <div className="coupon_header">
                    <div className="go_back_button_wrapper">    
                        <ButtonAnimationUp text={t("goBackText")} functionality={goBack} />
                    </div>
                </div>
                
                <div className="margin_top_50">
                    <h1>Add Tables to User</h1>
                

                    <div className="couopn_item_column"> <b> User Email: </b> </div>
                    <div className="tablePurchase">
                        <div className="input_field_holder">
                            <div className="input_field_wrapper">
                                <div className="input_field_icon full_center">
                                    <img src={editIcon} alt="home" />
                                </div>
                                <div className="input_field_text left_center delete_default_input_style">
                                    <input
                                        type="email"
                                        id="userEmail"
                                        name="userEmail"
                                        value={userEmail}
                                        onChange={(e) => setUserEmail(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="couopn_item_column"> <b> Number of tables: </b> </div>
                    <div className="input_field_holder">
                        <div className="input_field_wrapper">
                            <div className="input_field_icon full_center">
                                <img src={editIcon} alt="home" /> 
                            </div>
                            <div className="input_field_text left_center delete_default_input_style">
                                <input
                                    type="number"
                                    id="numberOfTables"
                                    name="numberOfTables"
                                    value={numberOfTables}
                                    onChange={(e) => setNumberOfTables(parseInt(e.target.value))}
                                    min="1"
                                    required
                                />
                            </div>
                        </div>
                    </div>

                    <div className="add_button_width">
                        <ButtonAnimationUp text={t("addKey")} functionality={handleSubmit} />
                    </div>
                </div>

            </div>

        </div>
    )
}

export default TableManagement;