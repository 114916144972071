import { FC, useContext, useEffect, useState } from "react";
import questionCardImage from "../../../assets/images/questionCard.svg";
import checkStroke from "../../../assets/icons/checkStroke.svg";
import editIcon from "../../../assets/icons/editIcon.svg";
import deleteGrey from "../../../assets/icons/DeleteGrey.svg";
import editBoxIcon from "../../../assets/icons/editBoxIcon.svg";
import { CardContextNew, ComponentsContext, MatchContext } from "../../../contexts";

interface IQuestionCard {
    questionCard: any;
    index: number;
    isCustom?: boolean;
}

const QuestionCard: FC<IQuestionCard> = ({questionCard, index, isCustom}) => {
    const {
        customQuestionCardIds,
        setCustomQuestionCardIds,
        defaultQuestionCardIds,
        setDefaultQuestionCardIds,
        setSelectedQuestionCardForEdit,
        deleteCustomQuestionCardHTTP
    } = useContext(CardContextNew);
    const { selectedLanguage, setToggleModalValue, setPopUpBoxType } = useContext(ComponentsContext);
    const { enteredMatchData } = useContext(MatchContext);

    const orderNumber = isCustom ? index : index + 1;
    
    useEffect( () => {
        setIsCardChecked(initialIsChecked());
    }, [customQuestionCardIds])

    useEffect( () => {
        setIsCardChecked(initialIsChecked());
    }, [defaultQuestionCardIds])

    useEffect( () => {
    }, [customQuestionCardIds]);

    const initialIsChecked = () => {
        if (isCustom)
            if (customQuestionCardIds.includes(questionCard.id)) return true;
        
        if (!isCustom)
            if (defaultQuestionCardIds.includes(questionCard.id)) return true;

        return false;        
    } 

    const [isCardChecked, setIsCardChecked] = useState<boolean>(initialIsChecked());

    const addIdToArray = () => {
        if (isCustom) {
            setCustomQuestionCardIds([...customQuestionCardIds, questionCard.id]);
            return;
        }

        setDefaultQuestionCardIds([...defaultQuestionCardIds, questionCard.id ])
    }
    
    const removeIdFromArray = () => {
        if (isCustom) {
            setCustomQuestionCardIds(customQuestionCardIds.filter( (itemId: any) => { return itemId !== questionCard.id}));
            return;
        }

        setDefaultQuestionCardIds(defaultQuestionCardIds.filter( (itemId: any) => { return itemId !== questionCard.id}));
    }

    const selectCard = () => {
        if (!isCardChecked) addIdToArray();
        if (isCardChecked) removeIdFromArray();

        setIsCardChecked(!isCardChecked);
    }

    const openPopUpBoxForEditing = () => {
        setSelectedQuestionCardForEdit(questionCard);
        if (enteredMatchData?.idForUpdate) {
            setPopUpBoxType("editSelectedMatchQuestion");
        } else {
            setPopUpBoxType("editQuestion");
        }
        setToggleModalValue(true);
    }

    return (
        <div key={index} className="dilemma_card_wrapper" >
            <img className="dilemma_card_image" src={questionCardImage} alt="question" />
            <div className="question_card_body">
                <div className="question_card_description"> {questionCard.description} </div>
            </div>
            {isCustom && <img className="card_edit_box" onClick={() => openPopUpBoxForEditing()} src={editBoxIcon} alt="check" />}
            <div className="card_check_box" onClick={() => selectCard()} > 
                {isCardChecked && <img src={checkStroke} alt="check" />}
            </div>
            <div className="card_order_number">
                {orderNumber}
            </div>
            {isCustom && !enteredMatchData?.idForUpdate && <div className="card_delete" onClick={() => deleteCustomQuestionCardHTTP(questionCard.id, selectedLanguage)}>
                <img className="color_filter_to_white" src={deleteGrey} alt="delete" />
            </div>}
        </div>
    )
}

export default QuestionCard;